import React, { Component } from "react";
import axios from "axios";
import config from "../../../config";

import { toast } from "react-toastify";

class FileUpload extends Component {
	constructor(props) {
		super(props);
		this.state = {
			spinner: false,
			image_file: null,
			image_preview: "",
			image_datauri: "",
		};
		this.handleSubmitFile = this.handleSubmitFile.bind(this);
	} 

	returnDataURI = (val) => { 
		var reader = new FileReader();
		reader.readAsDataURL(val);
		reader.onload = function () {
			return reader.result;
		};
		reader.onerror = function (error) {
			console.log("Error: ", error);
		};
	}
	 
	// Image Preview Handler
	handleImagePreview = (e) => {
		let image_as_base64 = URL.createObjectURL(e.target.files[0]);
		let image_as_files = e.target.files[0];	
		
		
		let image_dataURI = this.returnDataURI(e.target.files[0]);
		console.log("image_dataURI: ", image_dataURI);
		this.setState({ 
			image_datauri:image_dataURI,
			image_preview: image_as_base64,
			image_file: image_as_files,
		});
	};

	// Image/File Submit Handler
	handleSubmitFile = () => {
		let datecreated = new Date().toISOString().slice(0, 11).replace("T", " ");
		this.setState((prevState) => ({
			spinner: !prevState.spinner,
		}));
		if (this.state.image_file !== null) {
			let formData = new FormData();
			formData.append("file", this.state.image_file);
			formData.append("owner", this.props.owner);
			formData.append("customer", this.props.customer);
			formData.append("projectid", this.props.projectid);
			formData.append("action", "insert");
			formData.append("data", this.state.image_datauri);
			formData.append("created", datecreated);
			formData.append("name", this.props.name);
			formData.append("table", "photos");
			// the image field name should be similar to your api endpoint field name
			// in my case here the field name is customFile
			if (this.props.projectid && this.props.owner) {
				axios({
					method: "post",
					url: config.saveAPI,
					data: formData,
					file: this.state.image_file,
					config: { headers: { "Content-Type": "multipart/form-data" } },
				})
					.then(function (response) {
						toast.success("Photo has been submitted " + response.data, {
							position: toast.POSITION.BOTTOM_RIGHT,
							autoClose: 5500,
						});
						//document.getElementById("close").click();
						console.log("submitted form  ", response.data);
						console.log(response);
					})
					.catch(function (response) {
						//handle error
						console.log("ERROR FORM SERVER", response);
						toast.warn(" Photo Submission failed.", {
							position: toast.POSITION.BOTTOM_RIGHT,
							autoClose: 1500,
						});
					});
			} else {
				toast.error("Select CUSTOMER and PROJECT FIRST!.", {
					position: toast.POSITION.BOTTOM_RIGHT,
					autoClose: 5500,
				});
			}
			this.setState((prevState) => ({
				spinner: !prevState.spinner,
			}));
		}
	};

	// render from here
	render() {
		var classShowSpinner = this.state.spinner ? "show" : "hide";
		return (
			<div className="row gx-9 h-100">
				<div className="col-sm-6 mb-10 mb-sm-0">
					<div className="card card-custom card-stretch shadow mb-5">
						<div className="card-header">
							<h3 className="card-title">Upload Image</h3>
							<div className="card-toolbar">
								{/* image input field */}
								<div className="input-group mb-5">
									<input
										type="file"
										className="form-control"
										placeholder="select image for upload"
										aria-label="image"
										aria-describedby="basic-image"
										onChange={this.handleImagePreview}
									/>
								</div>
								<div className="input-group mb-5">
									<button
										className="btn btn-primary my-2 me-5"
										type="submit"
										onClick={this.handleSubmitFile}
										value="Submit"
									>
										Save in DB
									</button>
								</div>
							</div>
						</div>
						<div className="card-body"></div>
					</div>
				</div>
				<div className="col-sm-6 mb-10 mb-sm-0">
					<div
						id="loader"
						className={`spinner-wrapper hide ${classShowSpinner}`}
					>
						<div className="city">
							<span className="plane"></span>
							<i>...Uploading and preparing for new Photo take ...</i>
							<span id="loading"></span>
						</div>
					</div>
					{this.state.image_preview ? (
						<img
							src={this.state.image_preview}
							className="mw-100"
							alt="image preview"
						/>
					) : null}
				</div>
			</div>
		);
	}
}

export default FileUpload;
