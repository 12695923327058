import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";   
//import SaveInvoice from "../services/invoices/save.invoice";
import { updateAdutStore, unloadedAdutStore } from "../../../features/adut";
import { toast } from "react-toastify";
import Config from "../../../config";
//import DataTable from "./data.table";
//import SelectedProjectview from "./selectedProject.table";
import ProjectsTable from "../TableSearchView/TableSearchView";
import axios from "axios"; 
import Modal from "react-modal";
import IvoiceView from "../View/invoice";
const ref = React.createRef();
//***************************** */
// TBD:: if we pass to this component selected project ID in props 
// by triggering SelectProject(id) we will reinitiate component
// and display invoice, otherwise will display table for user to select project 
// and do again the same :display invoice
// this should be added to TableProjectView to allow user to see invoice when looking into project table 
//***************************** */

const Invoice = (props) => {

  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.value);
  const adut = useSelector((state) => state.adut.value);
  const terms = useSelector((state) => state.terms.value);
  const customers = useSelector((state) => state.customers.value);
  const [selected, setSelProjectId] = useState([]);
  const [labor, setLabor] = useState([]);
  const [bom, setBOM] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState([]);
  const [selectedInvoice, setSelectedInvoice] = useState([]);
  const [selectedTerm, setSelectedTerms] = useState([]);
  const projects = useSelector((state) => state.projects.value);
  const [activeModal, setActiveModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [photoGalery, setPhotosGalery] = useState([]);
  const [loginError, setLoginError] = useState("");
  const [loading, setLoading] = useState(false);
  const [totalPrice, setTotalPrice] = useState([]);
  const [projectCost, setprojectCost] = useState([]);
  const [projectCostWithTax, setprojectCostWithTax] = useState([]);
  const [projectTax, setprojectTax] = useState([]);
  const [allTotalCost, setAllTotalCost] = useState([]);
  let today = new Date().toISOString().slice(0, 11).replace("T", " ");
  // console.log("SELECTED CUSTOMER", selectedCustomer);

	useEffect(() => {
		if ((bom && labor && adut  )) {
			PrepareSelectedInvoice();
    }
    
    console.log("SELECTED TERM IN INVOICE ", selectedTerm);
    console.log("SELECTED PROJECT IN INVOICE ", selected);
    console.log("TOTAL COST IN INVOICE ", totalPrice);
    console.log("PREPARED FOR INVOICE ", selectedInvoice);
  }, [bom, labor, adut]);

	 const settings = {
     fields: [
       { name: "id", headerName: "ID", type: "link" },
       {
         name: "projectname",
         headerName: "Project Name",
         type: "title",
         width: "min-w-250px",
       },
       { name: "status", headerName: "Status", type: "string" },
       { name: "paymentterms", headerName: "Payment Terms", type: "string" },
       { name: "customername", headerName: "Customer", type: "string" },
       { name: "tax", headerName: "Tax", type: "persentage" },
       { name: "type", headerName: "Type", type: "string" },
       { name: "datecreated", headerName: "Date Created", type: "date" },
       { name: "startingdate", headerName: "Date Starting", type: "date" },
     ],
     filter: {
       datePickerField: "startDate",
       dropdownField: "status",
       dropdownValues: [
         { name: "All", value: "" },
         { name: "Estimate", value: "estimate" },
         { name: "Proposal", value: "proposal" },
         { name: "Invalid", value: "invalid" },
         { name: "Closed", value: "closed" },
         { name: "Re-work", value: "rework" },
         { name: "Invoice", value: "invoice" },
       ],
     },
	 };
	
  const handleOpenModal = (val) => {
    setActiveModal(val.split(" ") ? val.split(" ").join("") : val);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setActiveModal("");
    unloadedAdutStore();
  };
	
  useEffect(() => {
    //setTotalPrice([]);
    setBOM([]);
    setLabor([]);
    setPhotosGalery([]);
    setTotalPrice([]);
    setprojectCost([]);
    setprojectCostWithTax([]);
    setprojectTax([]);
    setSelectedInvoice([]);

    getAllPhotos(selected.id);
    getLabor(selected.id);
    getBOM(selected.id);
    //array === undefined || array.length == 0
  }, [selected]);

  useEffect(() => {
    dispatch(
      updateAdutStore({
        project: selected,
        terms: selectedTerm,
        bom: bom,
        customer: selectedCustomer,
        labor: labor,
        projectCost: projectCost,
        projectCostWithTax: projectCostWithTax,
        projectTax: projectTax,
      })
    );
    calculateAllCosts();
  }, [totalPrice]);

 

  const totalCost = (total) => {
    setTotalPrice((totalPrice) => [...totalPrice, total.toFixed(2)]);
  };

  const printElement = (e) => {
    e = document.getElementById("invoice");
    let cloned = e.cloneNode(true);
    document.body.appendChild(cloned);
    cloned.classList.add("printable");
    window.print();
    document.body.removeChild(cloned);
    //const myTimeout = setTimeout(document.body.removeChild(cloned), 1000);
  };

//   const costCalculator = (name) => {
//     if (setTotalPrice.length > 0) {
//       const AllTotal = totalPrice.reduce((acc, val) => {
//         return acc + Number(val);
//       }, 0);
//       let percent = AllTotal / 100;
//       let tax = percent * Number(selected.tax);
//       setprojectTax(tax);
//       switch (name) {
//         case "withoutTax":
//           return AllTotal.toFixed(2);
//           break;

//         case "tax":
//           return tax;
//           break;

//         case "withTax":
//           return tax + AllTotal;
//           break;

//         default:
//           return "No DATA  in calculation";
//       }
//     } else {
//       return "error in calculation";
//     }
//   };

  const calculateAllCosts = () => {
    if (totalPrice) {
      const AllTotal = totalPrice.reduce((acc, val) => {
        return acc + Number(val);
      }, 0);
      console.log("AllTotal", AllTotal);
      setprojectCost(AllTotal.toFixed(2));
      let percent = AllTotal / 100;
      console.log("percent", percent);
      let tax = percent * Number(selected.tax);
      setprojectTax(tax);
      let costWithTax = tax + AllTotal;
      console.log("costWithTax", costWithTax);
      setprojectCostWithTax(costWithTax);
    }
  };

  const isJSON = (str) => {
    try {
      return JSON.parse(str) && !!str;
    } catch (e) {
      return false;
    }
  };

  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",

    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  }); // {formatter.format(projectCost)}

  const getLabor = (id) => {
    //reset data
    //load Labor
    if (id) {
      console.log("GET LABOR DATA ", id);
      var bodyFormDataProjects = new FormData();
      bodyFormDataProjects.append("action", "select");
      bodyFormDataProjects.append("owner", Number(selected.owner));
      bodyFormDataProjects.append("token", user.token);
      bodyFormDataProjects.append("data", Number(id));
      bodyFormDataProjects.append("table", "labor");
      axios({
        method: "post",
        url: Config.saveAPI,
        data: bodyFormDataProjects,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then((res) => {
          let validated = res.data ? true : false;
          if (validated) {
            //set data
            //setLabor([]);
            setLabor(res.data);
            toast.success("Retreiving Labor data complete  ", {
              position: toast.POSITION.BOTTOM_RIGHT,
              autoClose: 1500,
            });
            //set total cost
            totalCost(
              res.data.reduce((acc, val) => {
                return acc + Number(val.price);
              }, 0)
            );
          }
          setLoginError(
            validated
              ? null
              : "Server message: Something went wrong with loading PROJECTS  Data"
          );
          //remove spinner
          setLoading(false);
          console.log("RECIEVED LABOR DATA ", res.data);
        })
        .catch((err) => {
          toast.warn(
            "Hmmm, geting labor for this project is having troubles:  " + err,
            {
              position: toast.POSITION.BOTTOM_RIGHT,
              autoClose: 4500,
            }
          );
          console.log(err);
        });
    }
  };
  const getBOM = (id) => {
    //load BOM
    if (id) {
      console.log("GET LABOR DATA BOM", id);
      var bodyFormDataProjects = new FormData();
      bodyFormDataProjects.append("action", "select");
      bodyFormDataProjects.append("owner", Number(selected.owner));
      bodyFormDataProjects.append("token", user.token);
      bodyFormDataProjects.append("data", Number(id));
      bodyFormDataProjects.append("table", "bom");
      axios({
        method: "post",
        url: Config.saveAPI,
        data: bodyFormDataProjects,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then((res) => {
          let apiResponse = res;
          let validated = res.data ? true : false;
          if (validated) {
            setBOM(res.data);
            toast.success("Retreiving BOM data complete  ", {
              position: toast.POSITION.BOTTOM_RIGHT,
              autoClose: 1500,
            });
            //calculate cost of items
            totalCost(
              res.data.reduce((acc, val) => {
                if (val.quantity) {
                  return acc + Number(val.price) * Number(val.quantity);
                } else return acc + Number(val.price);
              }, 0)
            );
          }
          setLoginError(
            validated
              ? null
              : "Server message: Something went wrong with loading BOM  Data"
          );
          //remove spinner
          setLoading(false);
          console.log("RECIEVED BOM DATA ", res.data);
        })
        .catch((err) => {
          toast.warn(
            "Hmmm, geting labor for this project is having troubles:  " + err,
            {
              position: toast.POSITION.BOTTOM_RIGHT,
              autoClose: 4500,
            }
          );
          console.log(err);
        });
    }
  };

  const getAllPhotos = (id) => {
    //load All

    if (id) {
      var bodyFormDataProjects = new FormData();
      bodyFormDataProjects.append("action", "select");
      bodyFormDataProjects.append("owner", user.id);
      bodyFormDataProjects.append("token", user.token);
      bodyFormDataProjects.append("projectid", id);
      bodyFormDataProjects.append("table", "photos");
      axios({
        method: "post",
        url: Config.saveAPI,
        data: bodyFormDataProjects,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then((res) => {
          let apiResponse = res;
          let validated = res.data ? true : false;
          if (validated) {
            //set data
            setPhotosGalery([]);
            setPhotosGalery(res.data);
            //console.log("GALLERY", res.data);
            toast.success("Retreiving All photos fro this project complete  ", {
              position: toast.POSITION.BOTTOM_RIGHT,
              autoClose: 1500,
            });
          }
          setLoginError(
            validated
              ? null
              : "Server message: Something went wrong with loading ALL PHOTOS for this project ID"
          );
          console.log("RECIEVED ALL PHOTOS DATA ", res.data);
        })
        .catch((err) => {
          toast.warn(
            "Hmmm, geting labor for this project is having troubles:  " + err,
            {
              position: toast.POSITION.BOTTOM_RIGHT,
              autoClose: 4500,
            }
          );
          console.log(err);
        });
    }
  };

  const SelectProject = (id) => {
    setSelProjectId(
      projects.component.find((item) => {
        return item.id === id;
      })
    );
    ///select customer from selected project
    setSelectedCustomer(
      customers.component.find((item) => {
        return (
          item.id ===
          projects.component.find((item) => {
            return item.id === id;
          }).customer
        );
      })
    );
    setSelectedTerms(
      terms.component.find((term) => {
        return (
          term.name ===
          projects.component.find((item) => {
            return item.id === id;
          }).paymentterms
        );
      })
    );
    //selectionUpdated run new data

  };
  const SaveInvoice = () => {
    //
    setLoading(true);
    let datecreated = new Date().toISOString().slice(0, 11).replace("T", " ");
    let name =
      adut.customer.lastname +
      "-" +
      adut.customer.firstname +
      "-" +
      adut.project.id +
      "-" +
      new Date().toLocaleString("en-US", {
        month: "short",
        year: "numeric",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
      });

    const data = {
      name: name,
      datecreated: datecreated,
      owner: Number(user.id),
      customer: Number(adut.customer.id),
      json: JSON.stringify(adut),
      projectid: Number(adut.project.id),
    };

    var bodyFormDataProjects = new FormData();
    bodyFormDataProjects.append("action", "insert");
    bodyFormDataProjects.append("owner", Number(user.id));
    bodyFormDataProjects.append("token", user.token);
    bodyFormDataProjects.append("table", "invoices");
    bodyFormDataProjects.append("data", JSON.stringify(data));
    //bodyFormDataProjects.append("json", JSON.stringify(adut));
    //bodyFormDataProjects.append("projectid", Number(adut.project.id));
    //bodyFormDataProjects.append("customer", Number(adut.customer.id));
    //bodyFormDataProjects.append("datecreated", datecreated);
    
    axios({
      method: "post",
      url: Config.saveAPI,
      data: bodyFormDataProjects,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((res) => {
        let validated = res.data ? true : false;
        setLoginError(
          validated
            ? null
            : "Server message: Something went wrong with Saving INVOICE "
        );
        toast.success("INVOICE  has been saved " + res.data, {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 1500,
        });
        //remove spinner
        setLoading(false);
      })
      .catch((err) => {
        toast.warn("Hmmm, SAVE INVOICE is having troubles:  " + err, {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 4500,
        });
        console.log(err);
      });
  };

  const PrepareSelectedInvoice = () => {
    //
    setLoading(true);
    let datecreated = new Date().toISOString().slice(0, 11).replace("T", " ");
    let name =
      adut.customer.lastname +
      "-" +
      adut.customer.firstname +
      "-" +
      adut.project.id +
      "-" +
      new Date().toLocaleString("en-US", {
        month: "short",
        year: "numeric",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
      });

    const data = {
      name: name,
      datecreated: datecreated,
      owner: Number(user.id),
      customer: Number(adut.customer.id),
      json: JSON.stringify(adut),
      projectid: Number(adut.project.id),
    };
    setSelectedInvoice(data);
  };

	Modal.setAppElement("#app");
	
  return (
    <>
   

      <ProjectsTable
        data={projects.component}
        settings={settings}
        selectProject={SelectProject}
        handleOpenModal={handleOpenModal}
      />

      <div className="selected-project-container">
        <Modal
          isOpen={
            showModal &&
            activeModal == selected?.id.toString().split(" ").join("")
          }
          contentLabel="Invoice Modal"
          shouldCloseOnOverlayClick={true}
          appElement={document.getElementById("app")}
        >
          <div className="content d-flex flex-column   rounded">
            <header className=" bg-light w-100 p-2  ">
              <h4 className="w-100">
                <button
                  className="close btn btn-primary mr-5"
                  id="close"
                  onClick={handleCloseModal}
                >
                  CLOSE
                </button>{" "}
                <button
                  className="close btn btn-primary mr-5"
                  id="close"
                  onClick={handleCloseModal}
                >
                  SEND AGAIN - missing fn(x)
                </button>{" "}
                <button
                  className="close btn btn-secondary mr-5"
                  id="close"
                  onClick={() => {
                    printElement();
                  }}
                >
                  PRINT
                </button>{" "}
                <button
                  className="close btn btn-secondary mr-5"
                  id="close"
                  onClick={handleCloseModal}
                >
                  SEND EMAIL
                </button>{" "}
                <button
                  className="close btn btn-secondary mr-5"
                  id="close"
                  onClick={() => {
                    SaveInvoice();
                  }}
                >
                  SAVE INVOICE in DB
                </button>
              </h4>
            </header>
            <main id="invoice">
              <h1>{selectedInvoice?.length}</h1>

              {selectedInvoice?.json && bom && labor && adut ? (
                <IvoiceView
                  selected={selectedInvoice}
                  handleCloseModal={handleCloseModal}
                  user={user}
                />
              ) : null}
            </main>
          </div>
        </Modal>

        
      </div>
    </>
  );
}
 export default Invoice;