import React, { Component } from 'react'
import ImportCustomer from "../Common/services/import/importCustomers/import.customers";
export class ToolsLayouts extends Component {
  render() {
    return (
			<div>
				<ImportCustomer />
			</div>
		);
  }
}

export default ToolsLayouts;
