import React, { useState,useEffect } from "react";
import { Alert, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
 
import {
	updatedTermsStore,
	unloadedTermsStore,
} from "../../features/terms";
import { toast } from "react-toastify";

import Config from "../../config";
import axios from "axios"; 

function StoreTerms() {
	const dispatch = useDispatch();
    const user = useSelector((state) => state.user.value);
	const terms = useSelector((state) => state.customers.value); 
 
	const [loginError, setLoginError] = useState("");
	const [loading, setLoading] = useState(false); 
    
    const reset = () => {
		unloadedTermsStore(); 
	};
 
    useEffect(() => {
			setLoading(true);
			unloadedTermsStore();
			var bodyFormDataProjects = new FormData();
			bodyFormDataProjects.append("action", "select");
			bodyFormDataProjects.append("token", user.token);
			bodyFormDataProjects.append("owner", Number(user.id));
			bodyFormDataProjects.append("table", "terms");
			axios({
				method: "post",
				url: Config.saveAPI,
				data: bodyFormDataProjects,
				headers: { "Content-Type": "multipart/form-data" },
			})
				.then((res) => {
					let apiResponse = res;
					let validated = res.data ? true : false;
					setLoginError(
						validated
							? null
							: "Server message: Something went wrong with loading PROJECTS  Data"
					);

					if (validated) {
						toast.success("TERMS's Store has been updated  ", {
							position: toast.POSITION.BOTTOM_RIGHT,
							autoClose: 1500,
						});

						//console.log("VALIDATED TERMS STORE ", res.data);
						//remove spinner
						setLoading(false);
						//set store
						dispatch(
							updatedTermsStore({
								component: res.data,
							})
						);
					}
				})
				.catch((err) => {
					toast.warn("Hmmm, TERMS store is having troubles:  " + err, {
						position: toast.POSITION.BOTTOM_RIGHT,
						autoClose: 4500,
					});
					console.log(err);
				});
		}, [updatedTermsStore]);
    return (
      <>
         
        {user.loginStatus ? (
          <>
            {loading ? (
              <div className="flex mt-5">
                <div className="ms-4 me-4">
                  <Spinner animation="border" variant="primary" /> Store is
                  loading data ...
                </div>
              </div>
            ) : null}
            {loginError ? (
              <div className="flex mt-5">
                <Alert variant="danger" className="mt-3">
                  {" "}
                  {loginError}
                </Alert>
              </div>
            ) : null}
          </>
        ) : null}
      </>
    );
}

export default StoreTerms;
