import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
//import ItemProject from "./items.project";

const CurrentMontView = (props) => {
  const month = props;
  const date = new Date(month.year + "-" + month.month + "-01");
  const textDate = date.toLocaleString("en-US", { month: "short" }); // {month:'long'}
  const projects = useSelector((state) => state.projects.value);
  const [projectList, setProjectList] = useState(false);
  const datestarted = props.current;
  const currentDate = props.month.year + "-" + props.month.month;

  useEffect(() => {
    ThisMonth();
    //  setProjectList(ThisMonth());
    console.log("CurrentMontView projectList:", projectList);
    //   console.log("CurrentMontView month",month);
    //   console.log("CurrentMontView props :",props);
  }, [projects]);

  const ThisMonth = () => {
    let items = [];

    projects.component.forEach((a) => {
      //console.log("a.startingdate="+a.startingdate.substring(0, 7)+" currentDate="+ currentDate)
      if (a.startingdate.substring(0, 7) == currentDate) items.push(a);
    });
    if (items != undefined || items.length != 0) {
      setProjectList(items);
    }
  };

  const checktDate = (date) => {
    const t = new Date(date);
    const y = t.getFullYear();
    const m = ("0" + (t.getMonth() + 1)).slice(-2);
    const d = ("0" + t.getDate()).slice(-2);
    return `${y}-${m}`;
  };

  const isThisMOnth = () => {
    const date = new Date(props.month.year + "-" + props.month.month + "-01");

    const setDate = date.toLocaleString("en-US", {
      year: "numeric",
      month: "2-digit",
    });

    // console.log(
    //     "PROPS YEAR + MONTH REAL DATE =" +setDate.replace("/", "-").substring(0, 7)
    // );
    // console.log(
    //     "PROPS YEAR + MONTH  =" +props.month.year + "-" + props.month.month
    // );
    //anyString.substring(0, 6)
    var currentDate = new Date();

    if (date.getMonth() == currentDate.getMonth()) {
      // console.log(
      // 	"MATCH IN MOTNHS " + date.getMonth(),
      // 	currentDate.getMonth()
      // );
      return true;
    } else {
      console.log(
        "NO MATCH IN MOTNHS " + date.getMonth(),
        currentDate.getMonth()
      );
    }
  };
  // return (
  //     projectList? (
  //         <div className="d-flex flex-stack  align-left position-relative mt-8">
  //             <div className="position-absolute h-100 w-4px bg-secondary rounded top-0 start-0"></div>
  //             <div className="fw-semibold ms-5 text-gray-600">
  //                 <div className="fs-7">
  //                     <span className="fs-1">
  //                         {new Date(projects.startingdate).toLocaleString("en-US", {
  //                             day: "numeric",
  //                         })}
  //                     </span>
  //                 </div>

  //                 <div className="fs-7">
  //                     <span className="fs-7 text-gray-400 text-uppercase ">
  //                         {new Date(projects.startingdate).toLocaleString("en-US", {
  //                             month: "short",
  //                             year: "numeric",
  //                         })}
  //                     </span>
  //                 </div>

  //                 <a
  //                     href="#"
  //                     className="fs-5 fw-bold text-gray-800 text-hover-primary mb-2"
  //                 >
  //                     {projects.projectname}{" "}
  //                     <span className="fs-7 text-gray-400  ms-2">
  //                         {projects.additionalinfo}
  //                     </span>
  //                 </a>

  //                 <div className="text-gray-400">
  //                     Customer <strong>{projects.customername}</strong>
  //                 </div>
  //                 <div className="text-gray-400">
  //                     Type <strong>{projects.type}</strong> Status{" "}
  //                     <strong>{projects.status}</strong>
  //                 </div>
  //             </div>
  //         </div>
  //     ):null
  // )

  return (
    <div className="w-75 d-flex flex-column align-items-center justify-content-center mb-5 ">
      {projectList ? (
        projectList?.map((item, i) => {
          return (
            <div
              key={i}
              className="card align-left bg-primary rounded position-relative mt-8 w-50"
            >
              <div className="position-absolute h-100 w-4px bg-light rounded top-0 start-0"></div>
              <div className="fw-semibold ms-3 text-gray-400 ">
                <div className="d-flex wrapper align-items-center ">
                  <div className="fs-1 me-2 ">
                    {new Date(item.startingdate).toLocaleString("en-US", {
                      day: "numeric",
                    })}
                  </div>

                  <h6 className=" text-light  text-uppercase text-gray-400 ">
                    {new Date(item.startingdate).toLocaleString("en-US", {
                      month: "short",
                      year: "numeric",
                    })}
                  </h6>
                </div>

                <div className="d-flex wrapper align-items-start">
                  <div className="fs-8  text-gray-400 text-light me-4">
                    {item.customername}
                  </div>
                  <div className="fs-8 text-white mb-2 d-flex flex-column px-2">
                    <span>{item.address} </span>

                    <span className="fs-8 text-light">
                      {item.additionalinfo}
                    </span>
                  </div>

                  <div className="fs-8  text-gray-400 text-light d-flex flex-column px-2">
                    {/* <span>Type <i>{item.type}</i> </span> */}
                    <span>
                      <strong>{item.status}</strong>
                    </span>
                  </div>
                </div>
              </div>
              {/* <a
                            href="#"
                            className="btn btn-bg-light btn-active-color-primary btn-sm"
                            onClick={() => {
                                handleOpenModal();
                                //showProjectDetails(item.id);
                            }}
                        >
                            View
                        </a> */}
            </div>
          );
        })
      ) : (
        <p>
          Hmmmm... bad month? Nothing in list for {props.month.month} of{" "}
          {props.month.year}
        </p>
      )}
    </div>
  );

  // return (
  //     {projectSelected.item.map(
  //         (singleItem, i) => {
  //             return (
  //                 <ItemProject
  //                     month={calDate}
  //                     item={singleItem}
  //                     key={i}
  //                     index={i}
  //                 />
  //             );
  //         }
  //     )}

  // )
};
export default CurrentMontView;
