import React, { useRef } from "react";

import axios from "axios";
import config from "../../../config";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { Button, Form } from "react-bootstrap";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

import TypeAheadCustomers from "./find.customer.typeahead";
//https://www.npmjs.com/package/react-sliding-pane#example
import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";
//import ProjectsTable from "../TableSearchView/TableSearchView";

const ProjectCreator = ({ props, options }) => {
  const user = useSelector((state) => state.user.value);
  const projects = useSelector((state) => state.projects.value);
  const [componentList, setComponentList] = useState([]);
  const [selected, setSelCustomer] = useState([]);
  const [message, setMessage] = useState("");
  const [isPaneOpen, setisPaneOpen] = useState("");
  const [isPaneOpenLeft, setisPaneOpenLeft] = useState("");
  const [selectedAsset, setSelectedAsset] = useState("");
  const [requiredPass, setRequiredPass] = useState(false);
  const [componentName, setComponentName] = useState("");
  const [componentJSONFormat, setComponentJSONFormat] = useState({});
  const [formFieldsBasedOnJSON, setFormFieldBasedOnJSON] = useState(<></>);
  const [stateForForm, setStateForForm] = useState({});
  const [showButton, setShowButton] = useState(false);
  const navigate = useNavigate();
  const selectFormRef = useRef();
  const gotoURL = (url) => {
    navigate(url);
  };

  //remove BOM and LAbor from list
  const removeItem = (item) => {
    setComponentList((prevState) =>
      prevState.filter((prevItem) => prevItem !== item)
    );
  };

  useEffect(() => {
    //Gets all json files from contracts/components to display in ComponentList
    axios
      .get(config.preparedcontracts)
      .then((res) => {
        setComponentList(res.data.map((elem) => elem.split(".")[0]));
      })
      .then((res) => {
        removeItem("BOM"); //here will not be supported
        removeItem("Labor"); //here will be removed not supported
      })
      .catch((e) => {});
  }, []);

  useEffect(() => {
    //console.log("selectedAsset",selectedAsset);
    if (selected?.id) {
      setShowButton(true);
      //restart();
    }
  }, [selected?.id]);

  useEffect(() => {
    if (!user.loginStatus) {
      gotoURL("/login");
    } else {
      setMessage("");
      renderForm();
    }
  }, [stateForForm]);

  const createCompleteObjectState = (value) => {
    const state = {};
    Object.entries(value).forEach(([key, val]) => {
      if (val.type == "array") {
        state[key] = val.childObject
          ? [{ ...createCompleteObjectState(val.childObject) }]
          : [];
      } else if (val.type == "object") {
        state[key] = { ...createCompleteObjectState(val.childObject) };
      } else {
        state[key] = "";
      }
    });
    return state;
  };
  const createObjectStateForForm = (value) => {
    const state = {};
    Object.entries(value).forEach(([key, val]) => {
      if (val.type == "array") {
        if (val.required == "true")
          state[key] = val.childObject
            ? [{ ...createObjectStateForForm(val.childObject) }]
            : [];
        else state[key] = [];
      } else if (val.type == "object") {
        state[key] = { ...createObjectStateForForm(val.childObject) };
      } else {
        state[key] = "";
      }
    });
    return state;
  };

  const createStateforForm = (res) => {
    const state = { ...createObjectStateForForm(res) };
    setStateForForm(state);
  };

  //recursive function that returns final state after pushing new object at a location
  const pushObjInState = (keys, state, obj) => {
    const key = keys.shift();
    if (keys.length == 0) {
      state[key].push(obj);
    } else state[key] = pushObjInState(keys, state[key], obj);
    return state;
  };

  //This function is called on + button click
  const addItemToArray = (e, keyHierarchy) => {
    const obj = JSON.parse(e.currentTarget.dataset.object);
    setStateForForm((state) => {
      return { ...pushObjInState(keyHierarchy.split(","), state, obj) };
    });
  };

  const removeObjFromState = (keys, state, idx) => {
    const key = keys.shift();
    if (keys.length == 0 && state[key].length > idx && idx > -1) {
      state[key].splice(idx, 1);
    } else state[key] = removeObjFromState(keys, state[key], idx);
    return state;
  };
  const removeItemFromArray = (e, keyHierarchy, idx) => {
    setStateForForm((state) => {
      return { ...removeObjFromState(keyHierarchy.split(","), state, idx) };
    });
  };

  //finds the object from json provides using the keyHierarchy
  const findObjectFromJSON = (keyHierarchy, json) => {
    var data = { ...json };
    keyHierarchy.split(",").forEach((key) => {
      data = data[key];
    });
    return data[0];
  };

  //Finds the object to be pushed by creating an object from componentJSON
  const findObject = (keyHierarchy) => {
    var data = { ...componentJSONFormat };
    const key = keyHierarchy.split(",")[0];
    const json = createCompleteObjectState(data[key]?.childObject);
    var newHierarchy = keyHierarchy.substring(
      keyHierarchy.indexOf(",") + 1
        ? keyHierarchy.indexOf(",") + 1
        : keyHierarchy.length
    );
    if (newHierarchy.startsWith("0,")) newHierarchy = newHierarchy.substring(2);
    if (newHierarchy == "") return json;
    return findObjectFromJSON(newHierarchy, json);
  };
  const onEditorValueChange = (event, editor, keyHierarchy) => {
    const data = editor.getData();
    setStateForForm((state) => {
      return { ...updateState(keyHierarchy.split(","), state, data) };
    });
  };
  //renders an HTML Form Field
  const renderField = (key, state, keyHierarchy, componentObject) => {
    if (componentObject?.type === "select")
      return (
        <Form.Group key={keyHierarchy} className="my-2 col-md-4 col-sm-12">
          <Form.Label>{componentObject?.description}</Form.Label>
          <Form.Select
            name={key}
            defaultValue={state}
            required={componentObject?.required == "true"}
            onChange={(e) => onChangeHandler(e, keyHierarchy)}
          >
            {componentObject?.options.map((opt, i) => (
              <option key={i} value={opt.value}>
                {opt.name}
              </option>
            ))}
          </Form.Select>
        </Form.Group>
      );
    else if (componentObject?.type === "textarea") {
      return (
        <Form.Group key={keyHierarchy} className="my-2 col-md-12 col-sm-12">
          <Form.Label>{componentObject?.description}</Form.Label>
          <CKEditor
            editor={ClassicEditor}
            data={state}
            required={componentObject?.required == "true"}
            onChange={(event, editor) =>
              onEditorValueChange(event, editor, keyHierarchy)
            }
          />
        </Form.Group>
      );
    } else if (componentObject?.type === "number") {
      return (
        <Form.Group key={keyHierarchy} className="my-2 col-md-12 col-sm-12">
          <Form.Label>{componentObject?.description}</Form.Label>
          <Form.Control
            type={componentObject?.type}
            defaultValue={state}
            name={key}
            step="any"
            required={componentObject?.required == "true"}
            onChange={(e) => onChangeHandler(e, keyHierarchy)}
          />
        </Form.Group>
      );
    }

    return (
      <Form.Group key={keyHierarchy} className="my-2 col-md-4 col-sm-12">
        <Form.Label>{componentObject?.description}</Form.Label>
        <Form.Control
          type={componentObject?.type}
          defaultValue={state}
          name={key}
          required={componentObject?.required == "true"}
          onChange={(e) => onChangeHandler(e, keyHierarchy)}
        />
      </Form.Group>
    );
  };
  const renderObject = (state, keyHierarchy, componentObject) => {
    var form = [];
    // if (componentObject?.description) {
    //   form.push(<div>{componentObject?.description}</div>);
    // }
    Object.entries(state).forEach(([key, value]) => {
      if (typeof value == "string") {
        form.push(
          renderField(
            key,
            state[key],
            keyHierarchy + "," + key,
            componentObject?.childObject[key]
          )
        );
      } else if (Array.isArray(value)) {
        form.push(
          renderArray(
            key,
            state[key],
            keyHierarchy + "," + key,
            componentObject?.childObject[key]
          )
        );
      } else if (typeof value == "object") {
        form = [
          ...form,
          ...renderObject(
            state[key],
            keyHierarchy + "," + key,
            componentObject?.childObject[key]
          ),
        ];
      }
    });
    return form;
  };

  const renderArray = (key, state, keyHierarchy, componentObject) => {
    var form = [];
    const obj = findObject(keyHierarchy.replace(/,[0-9]*,/g, ",0,"));
    if (componentObject?.childObject)
      return (
        <div key={keyHierarchy} className="">
          <div className="title">
            <label>{componentObject?.description} (Array)</label>
            <a
              className="button"
              title="Add Array Item"
              data-object={JSON.stringify(obj)}
              onClick={(e) => addItemToArray(e, keyHierarchy)}
            >
              <i className="fa-solid fa-square-plus"></i>
            </a>
          </div>
          {state.length > 0 &&
            state.map((st, idx) => {
              return (
                <div key={idx} className="array-wrapper container">
                  <div className="title">
                    {componentObject?.description} #{idx + 1}
                    <a
                      className="button"
                      title="Remove Array Item"
                      onClick={(e) => removeItemFromArray(e, keyHierarchy, idx)}
                    >
                      <i className="fa-solid fa-square-minus"></i>
                    </a>
                  </div>
                  <div className="row">
                    {renderObject(
                      st,
                      keyHierarchy + "," + idx,
                      componentObject
                    )}
                  </div>
                </div>
              );
            })}
        </div>
      );
  };

  const renderForm = () => {
    var form = [];
    Object.entries(stateForForm).forEach(([key, value]) => {
      if (typeof value == "string") {
        form.push({ ...renderField(key, stateForForm[key], key, "text") });
      } else if (typeof value == "number") {
        form.push({ ...renderField(key, stateForForm[key], key, "number") });
      } else if (Array.isArray(value)) {
        form.push(
          renderArray(key, stateForForm[key], key, componentJSONFormat[key])
        );
      } else if (typeof value == "object") {
        form = [
          ...form,
          ...renderObject(stateForForm[key], key, componentJSONFormat[key]),
        ];
      }
    });
    setFormFieldBasedOnJSON(form);
  };
  const required = (assetName) => {
    //checking if  dependent selection is done
    if (assetName === "Terms" || assetName === "Customers") {
      setRequiredPass(true);
      setComponentName(assetName);
      setShowButton(true);

      return true;
    } else {
      setRequiredPass(false);
      setComponentName(assetName);
      setShowButton(false);
      setMessage(
        '<p className="border-1 border-dashed card-rounded p-5 p-lg-10 mb-1 "> <span> To create new project you will need to select Customer. If there is no customers you will need to add one. Once customer is entered you can proceed to  create new project by selecting customer form dropdown menu.</span></p>'
      );
      //setFormFieldBasedOnJSON(<></>);
      return true;
    }
  };
  //OnChange handler function retreives contract for the component selected
  const componentSelected = (e) => {
    //console.log("COMPONENT NAME:", e.target.value);
    if (e.target.value == "") {
      setMessage;
      setFormFieldBasedOnJSON(<></>);
      return;
    }
    setSelectedAsset(e.target.value);
    //if required conditional met load otherwise update message
    required(e.target.value) && setRequiredPass
      ? axios
          .get(config.preparedcontracts + e.target.value + ".component.json")
          .then((res) => {
            setComponentJSONFormat({ ...res.data });
            createStateforForm(res.data);
            setMessage("");
          })
          .catch((e) => {})
      : e.target.value === "Projects"
      ? setMessage(
          "To add new <strong>" +
            e.target.value +
            "</strong>, you will have to select CUSTOMER to continue. "
        )
      : setMessage(
          "To add new <strong>" +
            e.target.value +
            "</strong>, you will have to select CUSTOMER and PROJECT in order to continue. "
        );
  };
  const onChangeHandler = (event, keyHierarchy) => {
    const name = event.target.name;
    const value = event.target.value;
    const keys = keyHierarchy.split(",");
    setStateForForm((state) => {
      return { ...updateState(keys, state, value) };
    });
  };

  const updateState = (keys, state, value) => {
    const key = keys.shift();
    if (!key) {
      return value;
    }
    state[key] = updateState(keys, state[key], value);
    return state;
  };

  const flatten = (data) => {
    var result = {};
    function recurse(cur, prop) {
      if (Object(cur) !== cur) {
        result[prop] = cur;
      } else if (Array.isArray(cur)) {
        for (var i = 0, l = cur.length; i < l; i++)
          recurse(cur[i], prop + "[" + i + "]");
        if (l == 0) result[prop] = [];
      } else {
        var isEmpty = true;
        for (var p in cur) {
          isEmpty = false;
          recurse(cur[p], prop ? prop + "." + p : p);
        }
        if (isEmpty && prop) result[prop] = {};
      }
    }
    recurse(data, "");
    return result;
  };
  const onFormSubmit = (e) => {
    e.preventDefault();
    console.log("COMPONENTLIST", componentList);
    const formdata = Object.fromEntries(new FormData(e.target)?.entries());
    const componentProps = { ...stateForForm };
    const self = this;
    let owner = Number(user.id);
    let customer = selected.id ? Number(selected.id) : 0;

    componentProps[Object.keys(componentProps)[0]].owner = owner;
    if (componentName.toLowerCase() != "customers") {
      componentProps[Object.keys(componentProps)[0]].customer = customer;
    }
    if (componentName.toLowerCase() === "projects") {
      componentProps[Object.keys(componentProps)[0]].customername =
        selected.firstname + " " + selected.lastname;
    } else {
      if (componentName.toLowerCase() === "terms") {
        componentProps[Object.keys(componentProps)[0]].datecreated = new Date()
          .toISOString()
          .slice(0, 19)
          .replace("T", " ");
      }
      //   else {
      //     //if is not project or terms or customers table add projectid
      //     if (componentName.toLowerCase() != "customers") {
      //       componentProps[Object.keys(componentProps)[0]].projectid = projectid;
      //     }
      //   }
    }

    const finalObj = {
      data: componentProps[Object.keys(componentProps)[0]],
    };

    console.log("Project Creator saving data:", finalObj);
    const postData = new FormData();
    for (let key in finalObj) {
      postData.append(key, JSON.stringify(finalObj[key]));
    }
    postData.append("owner", owner);
    postData.append("action", "insert");
    postData.append("table", componentName.toLowerCase());

    axios({
      method: "post",
      url: config.saveAPI,
      data: postData,
      config: { headers: { "Content-Type": "multipart/form-data" } },
    })
      .then(function (response) {
        toast.success("Form has been submitted " + response.data, {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 5500,
        });
        //document.getElementById("close").click();
        console.log("submitted form  ", response.data);
        console.log(response);
        restart();
        //gotoURL("/creator");
      })
      .catch(function (response) {
        //handle error
        console.log("ERROR Project Creator saving data:", response);
        toast.warn(" Submission failed.", {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 1500,
        });
      });
  };

  const restart = () => {
    setMessage("");
    setSelectedAsset("");
    setComponentName("");
    setComponentJSONFormat({});
    createStateforForm({});
    setFormFieldBasedOnJSON(<p>Select Component from menu above...</p>);
    setShowButton(false);
    return;
  };

  return (
    <div className="row">
      {selected?.email || selected?.phone ? (
        <SlidingPane
          className="some-custom-class"
          overlayClassName="some-custom-overlay-class"
          isOpen={isPaneOpen}
          width="520px"
          title="Upon selection selected customer and project will appear in here"
          subtitle="If you dont see anything it means you did not select anything.At any point of selection you can verify selected customer and projects."
          onRequestClose={() => {
            // triggered on "<" on left top click or on outside click
            setisPaneOpen(false);
          }}
        >
          {/* Display project and selected customer  */}
          {selected ? (
            <main>
              <div className="container">
                {selected ? (
                  <div className="row g-6 g-xl-9">
                    <div className="col-lg-12">
                      <h3>
                        {selected.firstname} {selected.lastname}
                      </h3>
                      {Object.entries(selected).map(function ([key, val]) {
                        if (key != "id" && key != "owner") {
                          if (val != "") {
                            return (
                              <p className="block" key={key}>
                                <span>{key} </span> <em> {val} </em>
                              </p>
                            );
                          } else {
                            return;
                          }
                        }
                      })}
                    </div>
                  </div>
                ) : (
                  <p>Nothing selected </p>
                )}
              </div>
            </main>
          ) : null}
        </SlidingPane>
      ) : null}

      <div className="row">
        <div className="  col-sm-12 position-relative">
          {/* <div className="d-flex align-items-center">
							
							<h5 className="mb-1">
								{selected?.firstname? <span> Create new project for customer {selected.firstname} {selected.lastname}</span>:<span>Create new Terms or add new Customer</span>}
							</h5>		
						</div> */}

          <Form className="row" onSubmit={onFormSubmit} onReset={restart}>
            {message ? (
              <div
                className="alert alert-danger border-1 border-dashed card-rounded p-5 p-lg-10 mb-1 "
                dangerouslySetInnerHTML={{
                  __html: message,
                }}
              />
            ) : null}

            <div
              className={
                user?.id && selected?.id && requiredPass
                  ? "mt-4 col-sm-12 rounded shodow bg-light border border-1"
                  : "mt-4 col-sm-12 rounded bg-light border border-1 "
              }
            >
              <h4 className="title fs-6 mt-3">Select from options below </h4>

              <Form.Group></Form.Group>
              <Form.Group className="py-2">
                {/* <Form.Label>
                    <span className="svg-icon svg-icon-2hx svg-icon-primary me-3">
                      <i className="fa-duotone fa-person-digging fs-1"></i>
                    </span>
                    Select new Project, or new Customer or new Terms
                  </Form.Label> */}
                <Form.Select
                  className="text-capitalize mb-4 "
                  ref={selectFormRef}
                  value={selectedAsset}
                  multiple={false}
                  name="componentName"
                  onChange={(e) => componentSelected(e)}
                >
                  <option value={"item"}>Select Option</option>
                  {componentList?.map((opt, i) => (
                    <option key={i}>{opt}</option>
                  ))}
                </Form.Select>
                <a
                  className="btn btn-primary my-2 me-2 mt-0"
                  onClick={() => restart()}
                >
                  Reset Selected option{" "}
                  <i className="fa-duotone fa-rotate-right fs-2 text-light"></i>
                </a>
                <div className="d-flex justify-content-center align-items-center">
                  {/* {selected?.email ? (
                      <a
                        className="btn btn-primary my-4  mt-0"
                        onClick={() => setisPaneOpen(true)}
                      >
                        <span className="me-2">
                          View customer and project info
                        </span>
                        <i className="fa-duotone fa-binoculars fs-1 text-light"></i>
                      </a>
                    ) : null} */}
                </div>

                <Form.Control
                  type="text"
                  name="customerID"
                  defaultValue={user.id ? Number(user.id) : ""}
                  placeholder="View Name"
                  className={user?.id ? "bg-light-success" : "bg-light-danger"}
                  hidden
                />

                <Form.Control
                  type="text"
                  name="customerID"
                  defaultValue={
                    selected?.id ? JSON.stringify(Number(selected.id)) : ""
                  }
                  placeholder="Select Customer first from above search field"
                  className={
                    selected?.id ? "bg-light-success" : "bg-light-danger"
                  }
                  hidden
                />
              </Form.Group>
            </div>
            {(componentName === "Projects") & !showButton ? (
              <div className="alert alert-warning  border mt-4 mb-0">
                <i className="fa-solid fa-hand-pointer fa-rotate-90 fa-sharp fs-4 text-dark me-4"></i>
                <span>
                  To create new project you will need to select Customer so we
                  know where to create project. Customer and Projects are in
                  relation.
                </span>
              </div>
            ) : null}
            {!requiredPass && componentName === "Projects" && (
              <TypeAheadCustomers
                options={options}
                selected={selected}
                setSelCustomer={setSelCustomer}
                setisPaneOpen={setisPaneOpen}
              />
            )}
            {componentName && showButton ? (
              <div className="card p-3 bg-light">
                {componentName && showButton ? (
                  <h2 className="title">{componentName}</h2>
                ) : null}
                <div className="row">
                  {componentJSONFormat?.description ? (
                    <p> {componentJSONFormat.description}</p>
                  ) : null}

                  {showButton && formFieldsBasedOnJSON}
                </div>
                {showButton ? (
                  <Button
                    variant="primary"
                    type="submit"
                    className="my-3 col-3 offset-4"
                  >
                    Submit
                  </Button>
                ) : null}
              </div>
            ) : null}
          </Form>

          <div className="row"></div>
        </div>
      </div>
    </div>
  );
};

export default ProjectCreator;
