import { Fragment } from "react";
import { Nav, Navbar, NavDropdown } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
/**
 *  @Component props.data:
 * img
 * imgClass
 * title
 * content
 * buttonText
 * buttonLink
 * border
 *
 */
const NavigationVertical = (props, closeThePanel) => {
	const location = useLocation();

	const navigate = useNavigate();
	const gotoURL = (url) => {
		navigate(url);
	};
	const getNav = (navData, idx) => {
		const nav = (
			<Nav key={idx}
				className={"navigation-header  navigation-column text-uppercase d-flex flex-column p-0 mt-12"}
				as="ul"
			>
				{navData?.items &&
					navData?.items?.map((item, index) => (
						<Fragment key={index}>
							{item.sublist && (
								<NavDropdown
									title={item?.name}
									className="my-1"
									id="basic-nav-dropdown"
									as="ul"
								>
									{item?.sublist?.map((listitem, idx) => (
										<NavDropdown.Item
											key={idx}
											as="li"
											className={`${
												location?.pathname?.includes(listitem?.url + "/") ||
												location?.pathname === listitem?.url
													? "active"
													: ""
											}`}
											// onClick={() => {
											// 	setisPaneOpenLeft(false); gotoURL(listitem?.url);
											// }}
										>
											{listitem?.name && (
												<span
													className={`${
														location?.pathname?.includes(listitem?.url + "/") ||
														location?.pathname === listitem?.url
															? "active"
															: ""
													}`}
												>
													{listitem.name}
												</span>
											)}
										</NavDropdown.Item>
									))}
								</NavDropdown>
							)}
							{!item.sublist && (
								item.url?
								<Nav.Item className="my-1" as="li" 
							
								>
									<Link to={item?.url ? item.url : "/"} className="px-2 py-0 fs-8 fw-bolder "
										onClick={() => {
										props.closeThePanel(); 
									}}
									>
										{item?.name && (
											<span
												className={`${
													location?.pathname?.includes(item?.url + "/") ||
													location?.pathname === item?.url
														? "active"
														: ""
												}`}
											>
												{item.icon ? (<i className={`me-2 ${item.icon}`} ></i> ):null} {item.name} 
											</span>
										)}
									</Link>
								</Nav.Item>:<a  className=" p-2 seprator fs-9 italic bg-light  border-bottom text-dark uppercased">
										{item?.name && (
											<span
												className={`${
													location?.pathname?.includes(item?.url + "/") ||
													location?.pathname === item?.url
														? "active"
														: ""
												}`}
											>
												{item.icon ? (<i className={`me-2 text-dark ${item.icon}`} ></i> ):null} {item.name} 
											</span>
										)}
									</a>
							)}
						</Fragment>
					))}
			</Nav>
		);
		if (navData?.type && navData?.type === "collapse")
			return (
				<>
				 
						{nav}
				 
				</>
			);
		// else if (navData.type && navData.type === "offcanvas")
		//   return (
		//     <Navbar expand={false} className="mb-3">
		//       <Navbar.Toggle aria-controls="offcanvasNavbar-expand-false" />
		//       <Navbar.Offcanvas
		//         id="offcanvasNavbar-expand-false"
		//         aria-labelledby={`offcanvasNavbarLabel-expand-false`}
		//         placement="end"
		//       >
		//         <Offcanvas.Header closeButton>Nav</Offcanvas.Header>
		//         <Offcanvas.Body>{nav}</Offcanvas.Body>
		//       </Navbar.Offcanvas>
		//     </Navbar>
		//   );
		else return nav;
	};
	return (
		<>
			{props?.data &&
				props?.data?.map((navData, idx) => {
					return <Fragment key={idx}> {getNav(navData, idx)}</Fragment>;
				})}
		</>
	);
};

export default NavigationVertical;
