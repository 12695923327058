import { createSlice } from "@reduxjs/toolkit";

const initialStateValue = {
	component: [],
};
//creating terms
export const termsSlice = createSlice({
	name: "terms",
	initialState: { value: initialStateValue },
	reducers: {
		updatedTermsStore: (state, action) => {
			state.value = action.payload;
		},
		unloadedTermsStore: (state) => {
			state.value = initialStateValue;
		},
	},
});

export const { updatedTermsStore, unloadedTermsStore } = termsSlice.actions;

export default termsSlice.reducer;
