import { createContext, useState } from "react";
import config from "../../../../config";
import axios from "axios";

const StoreContext = createContext();

function ContextProvider({ children }) {
  const [projects, setProjects] = useState([]);

  const fetchProjects = async () => {
    //const response = await axios.get(config.this.saveAPI);
    //setProjects(response.data);
    //projects update
    var bodyFormDataProjects = new FormData();
    bodyFormDataProjects.append("action", "select");
    bodyFormDataProjects.append("token", user.token);
    bodyFormDataProjects.append("owner", Number(user.id));
    bodyFormDataProjects.append("table", "projects");

    const response = await axios({
      method: "post",
      url: config.saveAPI,
      data: bodyFormDataProjects,
      headers: { "Content-Type": "multipart/form-data" },
    });
    setProjects(response.data);
    //set store
    dispatch(
      updateProjectStore({
        component: response.data,
      })
    );
  };

  //   const editProjectById = async (id, newTitle) => {
  //     const response = await axios.put(`http://localhost:3001/projects/${id}`, {
  //       title: newTitle,
  //     });

  //     const updatedProjects = projects.map((book) => {
  //       if (book.id === id) {
  //         return { ...book, ...response.data };
  //       }

  //       return book;
  //     });

  //     setProjects(updatedProjects);
  //   };

  //   const deleteProjectById = async (id) => {
  //     await axios.delete(`http://localhost:3001/projects/${id}`);

  //     const updatedProjects = projects.filter((book) => {
  //       return book.id !== id;
  //     });

  //     setProjects(updatedProjects);
  //   };

  //   const createProject = async (title) => {
  //     const response = await axios.post("http://localhost:3001/projects", {
  //       title,
  //     });

  //     const updatedProjects = [...projects, response.data];
  //     setProjects(updatedProjects);
  //   };

  //   const shared = {
  //     projects,
  //     deleteProjectById,
  //     editProjectById,
  //     createProject,
  //     fetchProjects,
  //   };
  const shared = {
    projects,
  };

  return (
    <StoreContext.Provider value={shared}>{children}</StoreContext.Provider>
  );
}

export { ContextProvider };
export default StoreContext;
