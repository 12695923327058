import React, { useState } from "react";
import { useSelector } from "react-redux"; 
import CustomerStore from "../components/Store/store.customers.projects"; 
import TermsStore from "../components/Store/store.terms";
import InvoiceCreator from "../components/Common/CreateInvoice/invoice.creator";
import Error from "./error";

const Create = (props) => {
    //const customers = useSelector((state) => state.customers.value);
	const [selected, setSelCustomer] = useState([]); 
	const user = useSelector((state) => state.user.value);
	const terms = useSelector((state) => state.terms.value);
	const projects = useSelector((state) => state.projects.value);
   // console.log("SELECTED FROM PARENT COMPONENT:", selected);
 
    return user.loginStatus ? (
			<>
				 
				<CustomerStore />
				<TermsStore />
				<InvoiceCreator projects={projects} user={user} terms={terms} />
			</>
		) : (
			<Error error="User has been logged out please login to have access" />
		);
  
}

export default Create;
