import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

function BackgroundSettings(props) {
  const user = useSelector((state) => state.user.value);
  const settings = useSelector((state) => state.settings.value);
  const SaveSettings = props.SaveSettings;
  const [dropdownValue, setDropdownValue] = useState("");

  const [save, setSave] = useState("disabled");
  const [message, setMessage] = useState("");

  // const saveHomePageSettings = () => {
  //   alert(
  //     "save saveHomePageSettings: " + dropdownValue + " is to be done in DB"
  //   );
  // };

  useEffect(() => {
    // Set up
    if (settings?.component?.lenght > 0) {
      setDropdownValue(
        settings?.component?.length > 0
          ? settings.component[0].invoicetemplate
          : ""
      );
      //console.log("SETINGS background:", dropdownValue);
    }
  }, [settings]);

  return user.loginStatus ? (
    <div className="col-md-4 col-sm-12 ">
      <div className="card card-custom card-stretch shadow   p-4">
        <h4 className="align-left m-2 mb-4">Home Settings</h4>

        <select
          className="form-select  h-40px  "
          data-control="select2"
          data-hide-search="true"
          data-placeholder="Status"
          data-kt-ecommerce-order-filter="status"
          value={dropdownValue}
          onChange={(e) => {
            setDropdownValue(e.target.value);
            setSave("");
          }}
        >
          <option value="">Select Home Page Background Image</option>
          <option value="/background/bg1.jpeg">Island cost</option>
          <option value="/background/bg2.jpeg">Town on See cost</option>
          <option value="/background/bg3.jpeg">River</option>
          <option value="/background/bg4.jpeg">Concrete</option>
          <option value="/background/bg5.jpeg">Music Studio</option>
          <option value="/background/bg6.jpeg">Construction</option>
          <option value="/background/bg7.jpeg">Tiles Kitchen</option>
        </select>
        <div className="m-4 d-flex flex-column">
          {dropdownValue ? (
            <p>
              Currently Sellected{" "}
              <img
                alt={dropdownValue}
                className="w-100 max-w-100 my-2"
                src={`/images/${dropdownValue}`}
              />
              <span className="text-success">{dropdownValue} </span>
            </p>
          ) : null}

          <button
            className="btn btn-primary my-4 mx-2 mt-0"
            disabled={save}
            onClick={(e) => {
              e.preventDefault();
              setMessage(SaveSettings("homebackground", dropdownValue));
            }}
          >
            {save ? save : "Update"}
          </button>
          {message && (
            <div
              dangerouslySetInnerHTML={{
                __html: message ? message : "",
              }}
            />
          )}
        </div>
      </div>
    </div>
  ) : null;
}

export default BackgroundSettings;
