import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

function InvoiceTemplateSettings(props) {
  const user = useSelector((state) => state.user.value);
  const settings = useSelector((state) => state.settings.value);

  const [dropdownValue, setDropdownValue] = useState("");

  const [save, setSave] = useState("disabled");
  const [message, setMessage] = useState("");
  const SaveSettings = props.SaveSettings;

  useEffect(() => {
    // Set up
    if (settings?.component?.lenght > 0) {
      setDropdownValue(
        settings?.component?.length > 0
          ? settings.component[0].invoicetemplate
          : ""
      );
      console.log("SETINGS invoicetemplate:", dropdownValue);
    }
  }, [settings]);

  return user.loginStatus ? (
    <div className="col-md-4 col-sm-12 ">
      <div className="card card-custom card-stretch shadow   p-4">
        <h4 className="align-left  m-2 mb-4">Invoice Template Settings</h4>

        <select
          className="form-select  h-40px  mx-2"
          data-control="select2"
          data-hide-search="true"
          data-placeholder="Status"
          data-kt-ecommerce-order-filter="status"
          value={dropdownValue}
          onChange={(e) => {
            setDropdownValue(e.target.value);
            setSave("");
          }}
        >
          <option value="0">0</option>
          <option value="1">1</option>
          <option value="2">2</option>
        </select>
        <div className="m-4 d-flex flex-column">
          <p>
            Currently Sellected{" "}
            <span className="text-success">{dropdownValue} </span>
          </p>

          <button
            className="btn btn-primary my-4 mx-2 mt-0"
            disabled={save}
            onClick={(e) => {
              e.preventDefault();
              setMessage(SaveSettings("invoicetemplate", dropdownValue));
            }}
          >
            {save ? save : "Update"}
          </button>
          {message && (
            <div
              dangerouslySetInnerHTML={{
                __html: message ? message : "",
              }}
            />
          )}
        </div>
      </div>
    </div>
  ) : null;
}

export default InvoiceTemplateSettings;
