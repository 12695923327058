import { Fragment } from "react";
import { Nav, Navbar, NavDropdown } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
/**
 *  @Component props.data:
 * img
 * imgClass
 * title
 * content
 * buttonText
 * buttonLink
 * border
 *
 */
const Navigation = (props) => {
	const location = useLocation();
	const getClassName = (pos) => {
		if (pos === "right" || pos === "left") return "navigation-col";
		else if (pos === "top" || pos === "bottom") return "navigation-row";
		else return "";
	};
	const navigate = useNavigate();
	const gotoURL = (url) => {
		navigate(url);
	};
	 
	
	const getNav = (navData, idx) => {
		const nav = (
			<Nav
				className={`${
					navData?.className ? navData.className : ""
				} ${getClassName(navData?.position)}`}
				as="ul"
			>
				{navData?.items &&
					navData?.items?.map((item, index) => (
						<Fragment key={index}>
							{item.sublist && (
								<NavDropdown
									title={item?.name}
									className="my-1"
									id="basic-nav-dropdown"
									as="ul"
								>
									{item?.sublist?.map((listitem, idx) => (
										<NavDropdown.Item
											key={idx}
											as="li"
											onClick={() => gotoURL(listitem?.url)}
										>
											{listitem?.name && (
												<span
													className={`${
														location?.pathname?.includes(listitem?.url + "/") ||
														location?.pathname === listitem?.url
															? "active"
															: ""
													}`}
												>
													{listitem.name}
												</span>
											)}
										</NavDropdown.Item>
									))}
								</NavDropdown>
							)}
							{!item.sublist && (
								item.url?
								<Nav.Item className="my-1 w-25 p-1 " as="li">
									 
									<Link to={item?.url ? item.url : "/"} className=" btn btn-primary-red  fs-8 fw-bolder bg-hover-light align-left p-2  text-hover-dark w-100 h-100 border border-dark ">
										{item?.name && (
											<>
											<span>
												{item.icon ? (<i className={` fs-1 ${item.icon}`} ></i> ):null} 
												{/*   */}
											</span>
											<span className={` `} >
												{item.name}
											</span>
											</>
										)}
									</Link>
								 
								</Nav.Item>:null
							)}
						</Fragment>
					))}
			</Nav>
		);
		// if (navData?.type && navData?.type === "collapse")
		// 	return (
		// 		<>
		// 			<Navbar.Toggle
		// 				className="col-md-1 offset-md-5 col-2 offset-4"
		// 				aria-controls={`basic-navbar-nav-${idx}`}
		// 			/>
		// 			<Navbar.Collapse
		// 				className="col-lg-6 col-md-5"
		// 				id={`basic-navbar-nav-${idx}`}
		// 			>
		// 				{nav}
		// 			</Navbar.Collapse>
		// 		</>
		// 	);
	 
		// else return nav;
		return nav;
	};
	const data= props;
	console.log("HORIZONTAL NAV props.data:",props);
	return (
		<div className="p-3 mb-8">
			{data?(
				data?.data?.map((navData, idx) => {
					//console.log("navData in navigation ", navData);
					//return getNav(navData, idx);
					return <Fragment key={idx}> {getNav(navData, idx)}</Fragment>;
				})
			):<p>No data in navvigation</p>
				}
		</div>
	);
};

export default Navigation;
