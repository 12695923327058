import React, { useState, useEffect } from "react";
import DataTable from "./TableProjectView";
import { useSelector } from "react-redux";
import Error from "../../../templates/error";

const ProjectsLookup = (props) => {
  const projects = useSelector((state) => state.projects.value);
  const user = useSelector((state) => state.user.value);
  const adut = useSelector((state) => state.adut.value);
  // const headerConfig = [
  // 	"firstname",
  // 	"lastname",
  // 	"email",
  // 	"phone",
  // 	"phone2",
  // 	"address1",
  // 	"city1",
  // 	"state1",
  // 	"zip1",
  // 	"company",
  // 	"address2",
  // 	"city2",
  // 	"state2",
  // 	"zip2",
  // 	"phone3",
  // ];

  useEffect(() => {
    // setHeader(headerConfig);
    console.log("PROJECT LOOKUP ADUT:", adut);
  }, []);
  const settings = {
    fields: [
      { name: "id", headerName: "Action", type: "link" },
      {
        name: "id",
        headerName: "ID",
        type: "number",
        selector: "id",
        headerStyle: (selector, id) => {
          return { width: "80px", textAlign: "center" };
        },
      },

      {
        name: "projectname",
        headerName: "Name",
        type: "title",
        width: "w-350px",
      },
      { name: "customername", headerName: "Customer", type: "string" },
      { name: "status", headerName: "Status", type: "status" },
      { name: "type", headerName: "Type", type: "string" },
      { name: "datecreated", headerName: "Created", type: "string" },
    ],
    filter: {
      datePickerField: "datestarted",
      dropdownField: "status",
      dropdownValues: [
        { name: "All", value: "" },
        { name: "Active", value: "active" },
        { name: "Complete", value: "complete" },
        { name: "Canceled", value: "canceled" },
        { name: "Re-work", value: "rework" },
        { name: "Invoice", value: "invoice" },
      ],
    },
  };

  return user.loginStatus ? (
    <DataTable data={projects.component} settings={settings} user={user} />
  ) : (
    <Error error="User has been logged out please login to have access" />
  );
};
export default ProjectsLookup;
