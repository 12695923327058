import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import ItemProject from "./items.project";

const ItemProjects = (props, data) => {
  const months = props.months;
  const storeProjects = useSelector((state) => state.projects.value);
  const projects = storeProjects.component;
  //TBD: get projects from props to avoid empty load at startup of component CALENDAR
  //const project = props.projects;
  const [scheduledProjects, setScheduledProjects] = useState([]);

  const selected = (calDate) => {
    let items = [];
    projects.forEach((a) => {
      if (a.startingdate === calDate + a.startingdate.slice(-3)) items.push(a);
    });
    if (items != undefined || items.length != 0) {
      return items;
    }
  };

  const isThisMOnth = (calDate) => {
    const date = new Date(calDate + "-01");
    const setDate = date.toLocaleString("en-US", {
      year: "numeric",
      month: "2-digit",
    });
    var currentDate = new Date();

    if (date.getMonth() == currentDate.getMonth()) {
      //console.log("MATCH IN MOTNHS ");
      return true;
    } else {
      console.log(
        //	"NO MATCH IN MOTNHS " + date.getMonth(),
        currentDate.getMonth()
      );
    }
  };

  useEffect(() => {
    const sp = [];
    months.map((month, i) => {
      let items = [];
      const date = new Date(month.year + "-" + month.month + "-10");
      const calDate = month.year + "-" + month.month;
      items = selected(month.year + "-" + month.month);
      if (items && items.length) {
        sp.push({
          calendar: calDate,
          item: items,
        });
      }
    });
    //console.log("SP:", sp);
    if (sp) setScheduledProjects(sp);
  }, [projects]);

  //const scheduled = scheduledProjects;
  // console.log("scheduledProjects", scheduledProjects);

  return (
    <>
      {months.map((calDate, i) => {
        return scheduledProjects ? (
          scheduledProjects.map((projectSelected, i) => {
            return projectSelected.calendar ===
              `${calDate.year}-${calDate.month}` ? (
              <div
                id={`item${calDate.year}-${calDate.month}`}
                className={
                  isThisMOnth(calDate.year + "-" + calDate.month)
                    ? "tab-pane fade show active"
                    : "tab-pane fade"
                }
                role="tabpanel"
                key={i}
              >
                {projectSelected.item.map((singleItem, i) => {
                  return (
                    <ItemProject
                      month={calDate}
                      scheduled={singleItem}
                      key={i}
                      index={i}
                    />
                  );
                })}
              </div>
            ) : null;
          })
        ) : (
          <p>Hmmm... looks like nothing is going on this month</p>
        );
      })}
    </>
  );
};
export default ItemProjects;
