import React, { useState, useEffect } from "react";
import "flatpickr/dist/themes/material_blue.css";
import DataTable from "react-data-table-component";
import Modal from "react-modal";
import axios from "axios";
import config from "../../../config";
import EditCustomer from "../Edit/edit.customer";
import Gallery from "./ProjectGallery";

import { toast } from "react-toastify";
const ref = React.createRef();

/**
 * react-data-table-component package to display data in table format with paginationa
 * flatpickr and react-flatpickr for date picker
 */

const TableSearchView = (props, selectProject) => {
  const [filteredData, setFilteredData] = useState([]);
  const [selectedC, setSelectedC] = useState([]);
  const [selectedProjects, setSelectedProjects] = useState([]);
  const [projectPhotos, setSelectedProjectPhotos] = useState([]);

  const [spinner, setSpinner] = useState(false);
  const [photoGalery, setPhotosGalery] = useState([]);
  const [galleries, setGalleries] = useState([]);
  const [loginError, setLoginError] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [startdate, setStartDate] = useState(new Date(0));
  const [enddate, setEndDate] = useState(new Date("04/20/2030"));
  const [dropdownValue, setDropdownValue] = useState("");
  const [activeModal, setActiveModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [edit, setEdit] = useState(false);
  const thismonth = new Date().toLocaleString("en-US", {
    month: "short",
    year: "numeric",
  });

  useEffect(() => {
    setFilteredData(props?.data ? [...props?.data] : []);
  }, [props?.data]);
  //let author = user?.find((item) => item.userName === name);

  useEffect(() => {
    setSelectedProjects(
      props.projects.component?.filter((item) => item.customer === selectedC.id)
    );
    setPhotosGalery([]);
    // console.log(
    //   "selectedProjects in table view for customer",
    //   selectedProjects
    // );
  }, [selectedC]);

  useEffect(() => {
    if (selectedProjects.length > 0) {
      selectedProjects.map((item, index) => {
        console.log("selectedProjects ID:", item);
        //getAllPhotos(item.owner, item.id, item.projectname);
      });
    }
  }, [selectedProjects]);

  // useEffect(() => {
  //     //  setSelectedProjectPhotos(
  //     //    photoGalery?.filter((item) => item.project === selectedC.id)
  //     //  );
  //     console.log("projectPhotos:", projectPhotos);
  //   }, [photoGalery]);

  useEffect(() => {
    setFilteredData(props?.data ? [...props?.data] : []);
    if (searchTerm !== "") {
      setFilteredData([]);
      props?.settings?.fields?.forEach((e) => {
        switch (e.type) {
          case "string":
            setFilteredData((f) => [
              ...f,
              ...props?.data?.filter((d) =>
                d[e.name]?.toLowerCase().includes(searchTerm.toLowerCase())
              ),
            ]);
            break;
          case "title":
            setFilteredData((f) => [
              ...f,
              ...props?.data?.filter((d) =>
                d[e.name].toLowerCase().includes(searchTerm.toLowerCase())
              ),
            ]);
            break;
          case "number":
            setFilteredData((f) => [
              ...f,
              ...props?.data?.filter((d) =>
                d[e.name].toString().includes(searchTerm)
              ),
            ]);
            break;
          case "link":
            break;
          default:
            setFilteredData((f) => [
              ...f,
              ...props?.data?.filter((d) =>
                d[e.name].toLowerCase().includes(searchTerm.toLowerCase())
              ),
            ]);
        }
      });
    }

    setFilteredData((f) =>
      f?.filter((value, index) => {
        const _value = JSON.stringify(value);
        return (
          index ===
          f.findIndex((obj) => {
            return JSON.stringify(obj) === _value;
          })
        );
      })
    );
  }, [searchTerm, dropdownValue, startdate?.getTime(), enddate?.getTime()]);

  const customStyles = {
    rows: {
      style: {
        minHeight: "72px", // override the row height
      },
    },
    headCells: {
      style: {
        paddingLeft: "8px", // override the cell padding for head cells
        paddingRight: "8px",
      },
    },
    cells: {
      style: {
        paddingLeft: "8px", // override the cell padding for props?.data cells
        paddingRight: "8px",
      },
    },
  };

  const col = props?.settings?.fields?.map((f) => {
    let sortable = true;
    switch (f.type) {
      case "array":
        sortable = false;
        break;
      default:
        sortable = true;
    }
    return {
      name: <div className="text-gray-800 fw-bold ">{f.headerName}</div>,
      selector: (row) => row[f.name],
      cell: (row) => {
        switch (f.type) {
          case "html":
            return (
              <div
                className="text-gray-600 fw-bold"
                dangerouslySetInnerHTML={{
                  __html: row[f.name] ? row[f.name] : "",
                }}
              ></div>
            );
          case "price":
            return <div className="text-gray-600 fw-bold ">${row[f.name]}</div>;
          case "title":
            return (
              <div className={`"text-gray-600  ${row[f.width]}"`}>
                {row[f.name]}
              </div>
            );
          case "link":
            return (
              <>
                <a
                  className="text-blue-600 fw-bold link d-flex flex-column w-50 align-items-center"
                  onClick={(e) => {
                    handleOpenModal(row[f.name], row);
                  }}
                >
                  <i className="fa-duotone fa-print-magnifying-glass fs-5 text-danger"></i>{" "}
                  view
                </a>
                <a
                  className="text-blue-600 fw-bold link d-flex flex-column w-50 align-items-center"
                  onClick={(e) => {
                    setEdit(true);
                    handleOpenModal(row[f.name], row);
                  }}
                >
                  <i className="fa-duotone fa-file-pen fs-5  text-danger"></i>{" "}
                  edit
                </a>
              </>
            );
          case "date":
            return <div className="text-gray-600  ">{row[f.name]}</div>;
          case "persentage":
            return <div className="text-gray-600  "> {row[f.name]} %</div>;
          default:
            return <div className="text-gray-600  ">{row[f.name]}</div>;
        }
      },
      sortable,
      sortFunction: (a, b) => {
        switch (f.type) {
          case "number":
            return a[f.name] - b[f.name];
          case "date":
            return new Date(a[f.name]) - new Date(b[f.name]);
          case "string":
            return a[f.name].localeCompare(b[f.name]);
          case "title":
            return a[f.name].localeCompare(b[f.name]);
        }
      },
    };
  });
  const handleOpenModal = (val, item) => {
    console.log("CUSTOMER TABLE VIEW LOOKUP val:", val);
    console.log("CUSTOMER TABLE VIEW LOOKUP item:", item);
    setSelectedC(item);
    //console.log("ITEM IN VEW:",item);
    //setActiveModal(val.split(" ").join(""));
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setEdit(false);
    setActiveModal("");
  };
  const projectList = props.projects;
  //console.log("projectListprojectList", projectList.component);

  return (
    <div className="card card-flush  p-0">
      <div className="card-header mt-6">
        <div className="card-title flex-column">
          <h3 className="title p-0 mb-1">{props?.title}</h3>
          <div className="fs-6 text-gray-400">{props?.subtitle} </div>
        </div>
      </div>

      <div className="card-header">
        <div className="d-flex align-items-center position-relative my-1">
          <span className="svg-icon svg-icon-1 position-absolute ms-4">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                opacity="0.5"
                x="17.0365"
                y="15.1223"
                width="8.15546"
                height="2"
                rx="1"
                transform="rotate(45 17.0365 15.1223)"
                fill="currentColor"
              />
              <path
                d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z"
                fill="currentColor"
              />
            </svg>
          </span>

          <input
            type="text"
            data-kt-ecommerce-order-filter="search"
            className="form-control form-control-solid w-250px h-40px ps-14"
            placeholder="Search Order"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
      </div>

      <div className="card-body pt-0">
        <DataTable
          columns={col}
          data={filteredData}
          customStyles={customStyles}
          pagination
          filterable={true}
        />
      </div>

      <Modal
        isOpen={showModal && !edit}
        contentLabel="View Modal"
        shouldCloseOnOverlayClick={true}
        appElement={document.getElementById("app")}
        onRequestClose={handleCloseModal}
      >
        <div className="content  d-flex flex-column rounded">
          <header className="bg-light p-2 ">
            <h4 className="w-100">
              <button
                className="close btn btn-primary mr-5"
                id="close"
                onClick={handleCloseModal}
              >
                CLOSE
              </button>{" "}
            </h4>
          </header>
          <main>
            <div className="container">
              {selectedC ? (
                <div className="row g-6 g-xl-12">
                  <div className="col-lg-3">
                    <h3>
                      {selectedC.firstname} {selectedC.lastname}
                    </h3>
                    {Object.entries(selectedC).map(function ([key, val]) {
                      if (key != "id" && key != "owner") {
                        if (val != "") {
                          return (
                            <p className="block" key={key}>
                              <span>{key} </span> <em> {val} </em>
                            </p>
                          );
                        } else {
                          return;
                        }
                      }
                    })}
                  </div>

                  <div className="col-lg-9">
                    {selectedProjects.length > 0 ? (
                      <div className="alert alert-warning d-flex align-items-center p-5 mt-5">
                        <span className="svg-icon svg-icon-2hx svg-icon-primary me-3">
                          <i className="fa-duotone fa-calendar fs-1"></i>
                        </span>

                        <div className="d-flex flex-column">
                          <h5 className="mb-1 ">
                            {selectedProjects.length} {" projects found"}
                          </h5>
                          <span>
                            <strong>
                              {selectedC.firstname} {selectedC.lastname}{" "}
                            </strong>
                            {" has "} {selectedProjects.length}{" "}
                            {"available projects"}. Click on name of the project
                            to see more details. Any project located in this
                            month will have red badge as mark of urgency and
                            attention.
                          </span>
                        </div>
                      </div>
                    ) : null}
                    <h3 className="title mb-1  "> List of projects </h3>
                    <div className="accordion accordion-icon-toggle " id="acc">
                      {selectedProjects.length > 0 ? (
                        selectedProjects.map((item, index) => {
                          return (
                            <div key={index} className="projects">
                              <div
                                className="accordion-header p-1 d-flex collapsed"
                                data-bs-toggle="collapse"
                                data-bs-target={`#acc_item_${index}`}
                                aria-expanded="false"
                              >
                                <span className="accordion-icon">
                                  <i className="fa-duotone fa-arrow-right"></i>
                                </span>
                                <div className="fs-7 text-gray-800 fw-bold mb-0 ms-4 p-1 ps-2">
                                  {thismonth ===
                                  new Date(item.startingdate).toLocaleString(
                                    "en-US",
                                    {
                                      month: "short",
                                      year: "numeric",
                                    }
                                  ) ? (
                                    <span className="ms-1 badge badge-danger">
                                      {" "}
                                      {new Date(
                                        item.startingdate
                                      ).toLocaleString("en-US", {
                                        month: "short",
                                        year: "numeric",
                                      })}
                                    </span>
                                  ) : (
                                    <span className="ms-1 badge badge-primary ">
                                      {new Date(
                                        item.startingdate
                                      ).toLocaleString("en-US", {
                                        month: "short",
                                        year: "numeric",
                                      })}
                                    </span>
                                  )}
                                  {"  "}
                                  {item.projectname}
                                </div>
                              </div>
                              <div
                                id={`acc_item_${index}`}
                                className="fs-6 collapse  p-4 block"
                                data-bs-parent="#acc"
                              >
                                <div className="container">
                                  <div className="row">
                                    {Object.entries(item).map(function ([
                                      key,
                                      val,
                                    ]) {
                                      if (
                                        key != "owner" &&
                                        key != "customer" &&
                                        key != "id" &&
                                        key != "laborID" &&
                                        key != "bomID" &&
                                        key != "photoID"
                                      ) {
                                        if (
                                          key == "notes" ||
                                          key == "customername" ||
                                          key == "projectname"
                                        ) {
                                          return (
                                            <div
                                              className="p-3 card col-sm-12 col-md-12 col-lg-12"
                                              key={key}
                                            >
                                              <div className="text-danger fw-bold text-uppercase me-4">
                                                {key}{" "}
                                              </div>
                                              <div
                                                className="text-dark fw-semibold "
                                                dangerouslySetInnerHTML={{
                                                  __html: val,
                                                }}
                                              />
                                            </div>
                                          );
                                        } else if (val != "") {
                                          return (
                                            <div
                                              className="p-3  col-sm-12 col-md-6 col-lg-6"
                                              key={key}
                                            >
                                              <div className="text-muted">
                                                {key}{" "}
                                              </div>
                                              <div
                                                className="text-dark fw-semibold "
                                                dangerouslySetInnerHTML={{
                                                  __html: val,
                                                }}
                                              />
                                            </div>
                                          );
                                        } else {
                                          return;
                                        }
                                      }
                                    })}
                                    <Gallery
                                      owner={item.owner}
                                      projectId={item.id}
                                      projectname={item.projectname}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })
                      ) : (
                        <div className="alert alert-warning d-flex align-items-center p-5 mb-10">
                          <span className="svg-icon svg-icon-2hx svg-icon-primary me-3">
                            <i className="fa-duotone fa-face-holding-back-tears fs-1"></i>
                          </span>

                          <div className="d-flex flex-column">
                            <h5 className="mb-1">Not an issue but no data</h5>
                            <span>
                              It looks like{" "}
                              <strong>
                                {selectedC.firstname} {selectedC.lastname}{" "}
                              </strong>{" "}
                              does not have any project created yet. To create a
                              new project go to Project Creator from main manu
                              and create new project.
                            </span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
          </main>
        </div>
      </Modal>

      <Modal
        isOpen={showModal && edit}
        contentLabel="Edit Modal"
        shouldCloseOnOverlayClick={true}
        appElement={document.getElementById("app")}
        onRequestClose={handleCloseModal}
      >
        <div className="content  d-flex flex-column rounded">
          <header className="bg-light w-100 p-2 ">
            <h4 className="w-100">
              <button
                className="close btn btn-primary mr-5"
                id="close"
                onClick={handleCloseModal}
              >
                CLOSE
              </button>{" "}
            </h4>
          </header>
          <main>
            <EditCustomer
              selected={selectedC}
              handleCloseModal={handleCloseModal}
            />
          </main>
        </div>
      </Modal>
    </div>
  );
};
export default TableSearchView;
