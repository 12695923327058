import React, { useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import CalendarSettings from "./calendar.settings";
import ThemeSettings from "./theme.settings";
import InvoiceTemplateSettings from "./invoicetemplate.settings";
import BackgroundSettings from "./background.settings";
import InvoiceSettings from "./invoice.settings";
import Login from "../../login/login";
import Config from "../../config";

function Profile() {
  const user = useSelector((state) => state.user.value);
  const [status, setStatus] = useState([]);

  const SaveSettings = (cellName, cellVal) => {
    var cell = {};
    cell[cellName] = cellVal;
    var message = {};
    message =
      "<b>" + cellName + "</b> has been updated to <b>" + cellVal + "</b>";
    var bodyFormData = new FormData();
    bodyFormData.append("action", "update");
    bodyFormData.append("token", user.token);
    bodyFormData.append("owner", user?.id);
    bodyFormData.append("table", "settings");
    bodyFormData.append("data", JSON.stringify(cell));
    axios({
      method: "post",
      url: Config.saveAPI,
      data: bodyFormData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((res) => {
        if (res) {
          setStatus(...status, [JSON.stringify(message)]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
    console.log("STATUS", status);
    return message;
  };

  return user.loginStatus ? (
    <>
      <div className="m-3 ">
        <div className="row g-2  mb-4">
          <CalendarSettings SaveSettings={SaveSettings} status={status} />
          <ThemeSettings SaveSettings={SaveSettings} status={status} />
          <InvoiceTemplateSettings
            SaveSettings={SaveSettings}
            status={status}
          />
          <InvoiceSettings SaveSettings={SaveSettings} status={status} />
          <BackgroundSettings SaveSettings={SaveSettings} status={status} />
        </div>
      </div>
      <div className=" mb-4 p-4 ">
        <h4 className="align-left fw-bolder m-2 mb-4 title">
          Personal Information
        </h4>
        <div className="row">
          {Object.entries(user).map(function ([key, val]) {
            return (
              <div key={key} className={`col-xs-12 col-md-4 col-lg-3 mb-2 `}>
                <div
                  className={`card p-2  min-h-80px ${
                    !val ? " bg-light-secondary" : ""
                  }`}
                >
                  <p className="mb-1 text-danger fw-bold text-uppercase me-4">
                    {key}{" "}
                  </p>
                  {val ? (
                    key === "companylogo" ? (
                      <p>
                        <img src={val} alt={key} width="100px" />
                      </p>
                    ) : key === "token" ? (
                      <input placeholder={val} value={val} disabled />
                    ) : (
                      <div
                        className="text-dark fw-semibold user mb-3"
                        dangerouslySetInnerHTML={{
                          __html: val,
                        }}
                      />
                    )
                  ) : (
                    <div className="text-secondary fw-semibold user mb-3">
                      not provided
                    </div>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  ) : (
    <Login />
  );
}

export default Profile;
