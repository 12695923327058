import React, { useState,useEffect } from "react";
import { Alert, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
	updateCustomerStore,
	unloadedCustomerStore,
} from "../../features/customers";
import { updateProjectStore, unloadedProjectStore } from "../../features/projects";
import {
	updateInvoiceStore,
	unloadedInvoiceStore,
} from "../../features/invoices";
import { toast } from "react-toastify";

import Config from "../../config";
import axios from "axios"; 
 
function Store() {
	const dispatch = useDispatch();
    const user = useSelector((state) => state.user.value);
	const customers = useSelector((state) => state.customers.value);
	const projects = useSelector((state) => state.projects.value);
 
	const [loginError, setLoginError] = useState("");
	const [loading, setLoading] = useState(false); 
    
    const reset = () => {
		unloadedCustomerStore();
		unloadedProjectStore ();
	};
 
    useEffect(() => {
		setLoading(true);
		unloadedCustomerStore(customers);
		//cusotmers update 
			var bodyFormData = new FormData();
			bodyFormData.append("action", "select");
			bodyFormData.append("token", user.token);
			bodyFormData.append("owner", Number(user.id));
			bodyFormData.append("table", "customers");
			
		axios({
				method: "post",
				url: Config.saveAPI,
				data: bodyFormData,
				headers: { "Content-Type": "multipart/form-data" },
			})
				.then((res) => {
					let apiResponse = res;
					let validated = res.data ? true : false;
					setLoginError(
						validated
							? null
							: "Server message: Something went wrong with loading Customers Data"
					);

					if (validated) {
						toast.success("Customer's Store has been updated  ", {
							position: toast.POSITION.BOTTOM_RIGHT,
							autoClose: 1500,
						});

						//console.log("VALIDATED CUSTOMERS STORE ", res.data);
						//remove spinner
						setLoading(false);
						//set store
						dispatch(
							updateCustomerStore({
								component: res.data,
								menu: customers.menu,
							})
						);
					}
				})
				.catch((err) => {
					toast.warn("Hmmm, cusomer store is having troubles:  " + err, {
						position: toast.POSITION.BOTTOM_RIGHT,
						autoClose: 4500,
					});
					console.log(err);
				});
		   //invoices update
			var bodyFormDataInvoice = new FormData();
			bodyFormDataInvoice.append("action", "select");
			bodyFormDataInvoice.append("token", user.token);
			bodyFormDataInvoice.append("owner", Number(user.id));
			bodyFormDataInvoice.append("table", "invoices");

			axios({
				method: "post",
				url: Config.saveAPI,
				data: bodyFormDataInvoice,
				headers: { "Content-Type": "multipart/form-data" },
			})
				.then((res) => {
					let apiResponse = res;
					let validated = res.data ? true : false;
					setLoginError(
						validated
							? null
							: "Server message: Something went wrong with loading INVOICE  Data"
					);

					if (validated) {
						toast.success("IVNOICE's have been updated  ", {
							position: toast.POSITION.BOTTOM_RIGHT,
							autoClose: 1500,
						});

						//console.log("VALIDATED INVOCICES in STORE ", res.data);
						//remove spinner
						setLoading(false);
						//set store
						dispatch(
							updateInvoiceStore({
								component: res.data,
							})
						);
					}
				})
				.catch((err) => {
					toast.warn("Hmmm, projects store is having troubles:  " + err, {
						position: toast.POSITION.BOTTOM_RIGHT,
						autoClose: 4500,
					});
					console.log(err);
				});
		//projects update 
		var bodyFormDataProjects = new FormData();
		bodyFormDataProjects.append("action", "select");
		bodyFormDataProjects.append("token", user.token);
		bodyFormDataProjects.append("owner", Number(user.id));
		bodyFormDataProjects.append("table", "projects");
		
		axios({
			method: "post",
			url: Config.saveAPI,
			data: bodyFormDataProjects,
			headers: { "Content-Type": "multipart/form-data" },
		})
			.then((res) => {
				let apiResponse = res;
				let validated = res.data ? true : false;
				setLoginError(
					validated
						? null
						: "Server message: Something went wrong with loading PROJECTS  Data"
				);

				if (validated) {
					toast.success("PROJECTS's Store has been updated  ", {
						position: toast.POSITION.BOTTOM_RIGHT,
						autoClose: 1500,
					});

					//console.log("VALIDATED PROJECTS STORE ", res.data);
					//remove spinner
					setLoading(false);
					//set store
					dispatch(
						updateProjectStore({
							component: res.data
						})
					);
				}
			})
			.catch((err) => {
				toast.warn("Hmmm, projects store is having troubles:  " + err, {
					position: toast.POSITION.BOTTOM_RIGHT,
					autoClose: 4500,
				});
				console.log(err);
			});
		}, []);
    return (
			<>
				{user.loginStatus ? (
				 <>
                    {loading ?
						<div className="flex mt-5"><div className="ms-4 me-4">
							<Spinner animation="border" variant="primary" /> Store is loading  data ...
						</div></div>: null}
					{loginError ? (<div className="flex mt-5"><Alert variant="danger" className="mt-3"> {loginError}</Alert></div>) : null}
				</> 
				) : null}
					
			</>
		);
}

export default Store;
