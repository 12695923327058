import Login from "../login/login";
const Error = (props) => {
	return (
		<div className="d-flex flex-column flex-root">
			<div className="d-flex flex-column flex-center flex-column-fluid">
				<div className="d-flex flex-column flex-center text-center p-10">
					<div className="card card-flush py-5">
						<div className="card-body py-15 py-lg-20">
							<h1 className="fw-bolder fs-2hx text-gray-900 mb-4">Oops!</h1>
							<div className="fw-semibold fs-6 text-gray-500 mb-7">
								{props?.error}
							</div>
							<div className="mb-3">
							 <Login/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
export default Error;
