import React, { useState, useEffect } from "react";
import DataTable from "./TableCustomerView";
import { useSelector } from "react-redux";
import Error from "../../../templates/error";

const CustomersLookup = (props) => {
  const customers = useSelector((state) => state.customers.value);
  const projects = useSelector((state) => state.projects.value);
  const user = useSelector((state) => state.user.value);

  const settings = {
    fields: [
      { name: "id", headerName: "Details", type: "link" },
      {
        name: "firstname",
        headerName: "First Name",
        type: "title",
        width: "min-w-150px",
      },
      { name: "lastname", headerName: "Last Name", type: "string" },
      { name: "email", headerName: "E-mail", type: "string" },
      { name: "phone", headerName: "Mobile", type: "string" },
      { name: "phone2", headerName: "Phone", type: "string" },
      { name: "address1", headerName: "Address1", type: "string" },
      { name: "city1", headerName: "City1", type: "string" },
      { name: "state1", headerName: "State1", type: "string" },
      { name: "zip1", headerName: "Zip1", type: "string" },
      { name: "company", headerName: "Company", type: "string" },
      { name: "address2", headerName: "Address2", type: "string" },
      { name: "city2", headerName: "City2", type: "string" },
      { name: "state2", headerName: "State2", type: "string" },
      { name: "zip2", headerName: "Zip2", type: "string" },
      { name: "phone3", headerName: "Phone3", type: "string" },
      { name: "registredDate", headerName: "Registred", type: "date" },
    ],
    filter: {
      datePickerField: "registredDate",
      dropdownField: "status",
      dropdownValues: [
        { name: "All", value: "" },
        { name: "Estimate", value: "estimate" },
        { name: "Proposal", value: "proposal" },
        { name: "Invalid", value: "invalid" },
        { name: "Closed", value: "closed" },
        { name: "Re-work", value: "rework" },
        { name: "Invoice", value: "invoice" },
      ],
    },
  };
  return user.loginStatus ? (
    <DataTable
      data={customers.component}
      settings={settings}
      projects={projects}
      title="Customer Look Up"
      subtitle="Search for your customers, see related projects and projects details."
    />
  ) : (
    <Error error="User has been logged out please login to have access" />
  );
};
export default CustomersLookup;
