import React, { useState } from "react";  
import Config from "../../../config";
import axios from "axios";  

const GetBOMdata = (id, owner) => {
    const [bom, setBOM] = useState([]);
    if (id) {
      var bodyFormDataProjects = new FormData();
      bodyFormDataProjects.append("action", "select");
      bodyFormDataProjects.append("owner", Number(owner));
      bodyFormDataProjects.append("data", Number(id));
      bodyFormDataProjects.append("table", "bom");
      axios({
        method: "post",
        url: Config.saveAPI,
        data: bodyFormDataProjects,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then((res) => { 
          let validated = res.data ? true : false;
          if (validated) {
            setBOM(res.data);
            console.log("GetBOM data function returned", res.data);
          }
          return bom;
        })
        .catch((err) => {
          console.log(err);
           return bom;
        });
    }
  };
  export  {GetBOMdata};

 