import { createSlice } from "@reduxjs/toolkit";

const initialStateValue = {
  project: [],
  customer: [],
  term: [],
  bom: [],
  labor: [],
  gallery: [],
  projectCost: 0,
  projectCostWithTax: 0,
  projectTax: 0,
};
//creating customers
export const adutSlice = createSlice({
  name: "adut",
  initialState: { value: initialStateValue },
  reducers: {
    updateAdutCustomer: (state, action) => {
      var updatedItems = [];
      var item = { ...action.payload };
      const updated = {
        ...state.customer,
        item,
      };

      state.value.customer = updated;
    },
    updateAdutStore: (state, action) => {
      state.value = action.payload;
    },
    unloadedAdutStore: (state) => {
      state.value = initialStateValue;
    },
  },
});

export const adutActions = adutSlice.actions;
export const { updateAdutStore, unloadedAdutStore, updateAdutCustomer } =
  adutSlice.actions;

export default adutSlice.reducer;
