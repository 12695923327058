import { Route, Routes } from "react-router-dom"; 
import CreateInvoice from "./templates/creator.invoice.template";
import CreateProject from "./components/Common/ProjectCreator/project.creator";
import Creator from "./templates/creator.template"; 
import CreatePhotos from "./templates/creator.photos"; 
import ImportCustomers from "./templates/creator.importCustomers";
import CustomerLookup from "./components/Common/Lookup/customer.lookup";
import InvoiceLookup from "./components/Common/Lookup/invoice.lookup";
import ProjectLookup from "./components/Common/Lookup/projects.lookup";

import Home from "./templates/home.template";
import Login from "./login/login";
import Profile from "./components/profile/profile";
import CalendarView from "./components/Common/CalendarView/calendarView";

const Routers = (props) => {
  return (
    <main>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route exact path="/" element={<Login />} />
        <Route path="/creator" element={<Creator />} />
        <Route path="/project-creator" element={<CreateProject />} />
        <Route path="/photos" element={<CreatePhotos />} />
        <Route path="/invoice" element={<CreateInvoice />} />
        <Route path="/calendar" element={<CalendarView />} />
        <Route path="/customer-lookup" element={<CustomerLookup />} />
        <Route path="/invoice-lookup" element={<InvoiceLookup />} />
        <Route path="/project-lookup" element={<ProjectLookup />} />
        <Route path="/profile" element={<Login />} />
		    <Route path="/import" element={<ImportCustomers />} />
        <Route path="/home" element={<Home />} />
      </Routes>
    </main>
  );
};
export default Routers;
