import { useState, useEffect, useRef } from "react";
import GetProject from "../Get/get.project.data";
import { useSelector } from "react-redux";
//import ProjectTable from "../View/project.table";
import ProjectTable from "../Edit/edit.project";
import Modal from "react-modal";
const ItemProject = (props) => {
  const month = props.month;
  const scheduled = props.scheduled;
  const calendarDate = month.year + "-" + month.month;
  const user = useSelector((state) => state.user.value);
  const [activeModal, setActiveModal] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const showProjectDetails = (id) => {
    //  e.preventDefault();
    return console.log("getData project", GetProject(id, user.id));
  };

  const handleOpenModal = (item) => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setActiveModal("");
  };

  //console.log("CALENDAR:scheduled:", scheduled);
  return (
    <div>
      {scheduled.calendar === calendarDate ? <p>Render this item</p> : null}
      {scheduled ? (
        <div className="d-flex flex-stack  align-left position-relative mt-8 ">
          <div className="position-absolute h-100 w-4px bg-secondary rounded top-0 start-0"></div>
          <div className="fw-semibold ms-5 text-gray-600">
            <div className="fs-7">
              <span className="fs-1">
                {new Date(scheduled.startingdate).toLocaleString("en-US", {
                  day: "numeric",
                })}
              </span>
            </div>
            <div className="fs-7">
              <span className="fs-7 text-gray-400 text-uppercase ">
                {new Date(scheduled.startingdate).toLocaleString("en-US", {
                  month: "short",
                  year: "numeric",
                })}
              </span>
            </div>

            <a
              href="#"
              className="fs-5 fw-bold text-gray-800 text-hover-primary mb-2"
            >
              {scheduled.projectname}{" "}
              {/* <span className="fs-7 text-gray-400  ms-2">
                {scheduled.additionalinfo}
              </span> */}
            </a>

            <div className="text-gray-400">
              Customer <strong>{scheduled.customername}</strong>
            </div>
            <div className="text-gray-400">
              <strong>{scheduled.type}</strong>
              <div
                className={`${
                  scheduled.status === "active" ||
                  scheduled.status === "approved"
                    ? "mx-2 badge bg-red text-white text-uppercase"
                    : scheduled.status === "complete"
                    ? "mx-2 badge bg-red text-dark text-uppercase"
                    : "mx-2 text-light badge bg-dark text-uppercase"
                }`}
              >
                {scheduled.status}
              </div>
              <span
                className="mx-2 badge btn-red   text-uppercase"
                onClick={() => {
                  handleOpenModal();
                  //showProjectDetails(scheduled.id);
                }}
              >
                View
              </span>
            </div>
          </div>
          <a
            href="#"
            className="btn btn-red btn-active-color-primary btn-sm"
            onClick={() => {
              handleOpenModal();
              //showProjectDetails(scheduled.id);
            }}
          >
            View
          </a>
        </div>
      ) : (
        <p>No data for this month </p>
      )}
      <Modal
        isOpen={showModal}
        contentLabel="Editable"
        shouldCloseOnOverlayClick={true}
        appElement={document.getElementById("app")}
      >
        <div className="content d-flex flex-column rounded">
          <header className="shodow bg-light w-100 p-2   ">
            <h4 className="w-100">
              <button
                className="close btn btn-primary mr-5"
                id="close"
                onClick={handleCloseModal}
              >
                CLOSE
              </button>{" "}
            </h4>
          </header>
          <main>
            {/* <ProjectTable data={scheduled} /> */}
            <ProjectTable
              selected={scheduled}
              handleCloseModal={handleCloseModal}
            />
          </main>
        </div>
      </Modal>
    </div>
  );
};
export default ItemProject;
