import React, { useRef } from "react";

import axios from "axios";
import config from "../../../config";
import { useState } from "react";
import { useEffect } from "react";
import StoreCustomers from "../../Store/store.customers.projects";
import LoadingSpinner from "../../../utils/LoadingSpinner";
import GetBOM from "../Get/get.bom";
import { Button, Form } from "react-bootstrap";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import "react-sliding-pane/dist/react-sliding-pane.css";

const EditBom = ({ props, selected, handleCloseModal }) => {
  const user = useSelector((state) => state.user.value);
  const [message, setMessage] = useState("");
  const [componentName, setComponentName] = useState("Bom");
  const [componentJSONFormat, setComponentJSONFormat] = useState({});
  const [formFieldsBasedOnJSON, setFormFieldBasedOnJSON] = useState(<></>);
  const [stateForForm, setStateForForm] = useState({});
  const [showButton, setShowButton] = useState(false);
  const [loginError, setLoginError] = useState("");
  const [loading, setLoading] = useState(false);
  const [refreshBom, setRefreshBom] = useState(false);

  const asset = "Bom";

  useEffect(() => {
    renderForm();
    setShowButton(true);
  }, [componentJSONFormat]);

  useEffect(() => {
    componentSelected(asset);
    setComponentName(asset);
    componentSelected(asset);
  }, []);

  // useEffect(() => {
  // 	//restart();
  // }, [refreshBom]);

  const restart = () => {
    setMessage("");
    //setSelectedAsset("");
    setComponentName("");
    setComponentJSONFormat({});
    createStateforForm({});
    //	setFormFieldBasedOnJSON(<></>);
    setShowButton(false);
    setRefreshBom(false);
    return;
  };

  const createCompleteObjectState = (value) => {
    const state = {};
    Object.entries(value).forEach(([key, val]) => {
      if (val.type == "array") {
        state[key] = val.childObject
          ? [{ ...createCompleteObjectState(val.childObject) }]
          : [];
      } else if (val.type == "object") {
        state[key] = { ...createCompleteObjectState(val.childObject) };
      } else {
        state[key] = "";
      }
    });
    return state;
  };
  const createObjectStateForForm = (value) => {
    const state = {};
    Object.entries(value).forEach(([key, val]) => {
      if (val.type == "array") {
        if (val.required == "true")
          state[key] = val.childObject
            ? [{ ...createObjectStateForForm(val.childObject) }]
            : [];
        else state[key] = [];
      } else if (val.type == "object") {
        state[key] = { ...createObjectStateForForm(val.childObject) };
      } else {
        state[key] = "";
      }
    });
    return state;
  };

  const createStateforForm = (res) => {
    const state = { ...createObjectStateForForm(res) };
    setStateForForm(state);
  };

  //recursive function that returns final state after pushing new object at a location
  const pushObjInState = (keys, state, obj) => {
    const key = keys.shift();
    if (keys.length == 0) {
      state[key].push(obj);
    } else state[key] = pushObjInState(keys, state[key], obj);
    return state;
  };

  //This function is called on + button click
  const addItemToArray = (e, keyHierarchy) => {
    const obj = JSON.parse(e.currentTarget.dataset.object);
    setStateForForm((state) => {
      return { ...pushObjInState(keyHierarchy.split(","), state, obj) };
    });
  };

  const removeObjFromState = (keys, state, idx) => {
    const key = keys.shift();
    if (keys.length == 0 && state[key].length > idx && idx > -1) {
      state[key].splice(idx, 1);
    } else state[key] = removeObjFromState(keys, state[key], idx);
    return state;
  };
  const removeItemFromArray = (e, keyHierarchy, idx) => {
    setStateForForm((state) => {
      return { ...removeObjFromState(keyHierarchy.split(","), state, idx) };
    });
  };

  //finds the object from json provides using the keyHierarchy
  const findObjectFromJSON = (keyHierarchy, json) => {
    var data = { ...json };
    keyHierarchy.split(",").forEach((key) => {
      data = data[key];
    });
    return data[0];
  };

  //Finds the object to be pushed by creating an object from componentJSON
  const findObject = (keyHierarchy) => {
    var data = { ...componentJSONFormat };
    const key = keyHierarchy.split(",")[0];
    const json = createCompleteObjectState(data[key]?.childObject);
    var newHierarchy = keyHierarchy.substring(
      keyHierarchy.indexOf(",") + 1
        ? keyHierarchy.indexOf(",") + 1
        : keyHierarchy.length
    );
    if (newHierarchy.startsWith("0,")) newHierarchy = newHierarchy.substring(2);
    if (newHierarchy == "") return json;
    return findObjectFromJSON(newHierarchy, json);
  };
  const onEditorValueChange = (event, editor, keyHierarchy) => {
    const data = editor.getData();
    setStateForForm((state) => {
      return { ...updateState(keyHierarchy.split(","), state, data) };
    });
  };
  //renders an HTML Form Field
  const renderField = (key, state, keyHierarchy, componentObject) => {
    //set by default value of selected in state
    setStateForForm((state) => {
      return { ...updateState(keyHierarchy.split(","), state, selected[key]) };
    });

    if (componentObject?.type === "select")
      return (
        <Form.Group key={keyHierarchy} className="my-2 col-md-6 col-sm-12">
          <Form.Label>{componentObject?.description}</Form.Label>
          <Form.Select
            name={key}
            defaultValue={selected[key]}
            required={componentObject?.required == "true"}
            onChange={(e) => onChangeHandler(e, keyHierarchy)}
          >
            {componentObject?.options.map((opt, i) => (
              <option key={i} value={opt.value}>
                {opt.name}
              </option>
            ))}
          </Form.Select>
        </Form.Group>
      );
    else if (componentObject?.type === "textarea") {
      return (
        <Form.Group key={keyHierarchy} className="my-2 col-md-12 col-sm-12">
          <Form.Label>{componentObject?.description}</Form.Label>
          <CKEditor
            editor={ClassicEditor}
            data={selected[key]}
            required={componentObject?.required == "true"}
            onChange={(event, editor) =>
              onEditorValueChange(event, editor, keyHierarchy)
            }
          />
        </Form.Group>
      );
    } else if (componentObject?.type === "number") {
      return (
        <Form.Group key={keyHierarchy} className="my-2 col-md-12 col-sm-12">
          <Form.Label>{componentObject?.description}</Form.Label>
          <Form.Control
            type={componentObject?.type}
            defaultValue={state}
            name={key}
            step="any"
            required={componentObject?.required == "true"}
            onChange={(e) => onChangeHandler(e, keyHierarchy)}
          />
        </Form.Group>
      );
    }

    return (
      <Form.Group key={keyHierarchy} className="my-2 col">
        <Form.Label>{componentObject?.description}</Form.Label>
        <Form.Control
          type={componentObject?.type}
          defaultValue={selected[key]}
          name={key}
          required={componentObject?.required == "true"}
          onChange={(e) => onChangeHandler(e, keyHierarchy)}
        />
      </Form.Group>
    );
  };
  const renderObject = (state, keyHierarchy, componentObject) => {
    var form = [];
    // if (componentObject?.description) {
    //   form.push(<div>{componentObject?.description}</div>);
    // }
    Object.entries(state).forEach(([key, value]) => {
      if (typeof value == "string") {
        form.push(
          renderField(
            key,
            state[key],
            keyHierarchy + "," + key,
            componentObject?.childObject[key]
          )
        );
      } else if (Array.isArray(value)) {
        form.push(
          renderArray(
            key,
            state[key],
            keyHierarchy + "," + key,
            componentObject?.childObject[key]
          )
        );
      } else if (typeof value == "object") {
        form = [
          ...form,
          ...renderObject(
            state[key],
            keyHierarchy + "," + key,
            componentObject?.childObject[key]
          ),
        ];
      }
    });
    return form;
  };

  const renderArray = (key, state, keyHierarchy, componentObject) => {
    var form = [];
    const obj = findObject(keyHierarchy.replace(/,[0-9]*,/g, ",0,"));
    if (componentObject?.childObject)
      return (
        <div key={keyHierarchy} className="">
          <div className="title">
            <label>{componentObject?.description} (Array)</label>
            <a
              className="button"
              title="Add Array Item"
              data-object={JSON.stringify(obj)}
              onClick={(e) => addItemToArray(e, keyHierarchy)}
            >
              <i className="fa-solid fa-square-plus"></i>
            </a>
          </div>
          {state.length > 0 &&
            state.map((st, idx) => {
              return (
                <div key={idx} className="array-wrapper container">
                  <div className="title">
                    {componentObject?.description} #{idx + 1}
                    <a
                      className="button"
                      title="Remove Array Item"
                      onClick={(e) => removeItemFromArray(e, keyHierarchy, idx)}
                    >
                      <i className="fa-solid fa-square-minus"></i>
                    </a>
                  </div>
                  <div className="row">
                    {renderObject(
                      st,
                      keyHierarchy + "," + idx,
                      componentObject
                    )}
                  </div>
                </div>
              );
            })}
        </div>
      );
  };

  const renderForm = () => {
    var form = [];
    Object.entries(stateForForm).forEach(([key, value]) => {
      if (typeof value == "string") {
        form.push({ ...renderField(key, stateForForm[key], key, "text") });
      } else if (typeof value == "number") {
        form.push({ ...renderField(key, stateForForm[key], key, "number") });
      } else if (Array.isArray(value)) {
        form.push(
          renderArray(key, stateForForm[key], key, componentJSONFormat[key])
        );
      } else if (typeof value == "object") {
        form = [
          ...form,
          ...renderObject(stateForForm[key], key, componentJSONFormat[key]),
        ];
      }
    });
    setFormFieldBasedOnJSON(form);
  };

  const componentSelected = (e) => {
    //console.log("EDIT component name :", e);
    axios
      .get(config.preparedcontracts + e + ".component.json")
      .then((res) => {
        setComponentJSONFormat({ ...res.data });
        createStateforForm(res.data);
        // setMessage("Done loading JSON");
      })
      .catch((e) => {
        setMessage("Edit error loading JSON");
      });
  };
  const onChangeHandler = (event, keyHierarchy) => {
    const name = event.target.name;
    const value = event.target.value;
    const keys = keyHierarchy.split(",");
    setStateForForm((state) => {
      return { ...updateState(keys, state, value) };
    });
  };

  const updateState = (keys, state, value) => {
    const key = keys.shift();
    if (!key) {
      return value;
    }
    state[key] = updateState(keys, state[key], value);
    return state;
  };

  const onFormSubmit = (e) => {
    e.preventDefault();
    const formdata = Object.fromEntries(new FormData(e.target)?.entries());
    const componentProps = { ...stateForForm };
    const self = this;
    let owner = Number(user.id);
    let token = Number(user.token);
    let projectid = selected.id ? Number(selected.id) : 0;
    let customer = selected.customer ? Number(selected.customer) : 0;

    componentProps[Object.keys(componentProps)[0]].owner = owner;
    componentProps[Object.keys(componentProps)[0]].projectid = projectid;
    componentProps[Object.keys(componentProps)[0]].customer = customer;

    const finalObj = {
      data: componentProps[Object.keys(componentProps)[0]],
    };

    console.log("Edit BOM saving data:", finalObj);
    const postData = new FormData();
    for (let key in finalObj) {
      postData.append(key, JSON.stringify(finalObj[key]));
    }
    postData.append("owner", owner);
    postData.append("action", "insert");
    postData.append("table", componentName.toLowerCase());

    axios({
      method: "post",
      url: config.saveAPI,
      data: postData,
      config: { headers: { "Content-Type": "multipart/form-data" } },
    })
      .then(function (response) {
        toast.success("Confirmation: " + response.data, {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 5500,
        });
        setRefreshBom(!refreshBom);
        handleCloseModal();
        const storeRecall = () => {
          return <StoreCustomers />;
        };
        const myTimeout = setTimeout(
          console.log("EDIT customer complete"),
          5000
        );
      })
      .catch(function (response) {
        //handle error
        //console.log("ERROR FORM SERVER", response);
        toast.warn(response, {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 1500,
        });
      });
  };

  const deleteItem = (id) => {
    //action: disconnect
    // Removing($table, $owner ,$action, $id, $token)

    if (
      id &&
      window.confirm(
        "You are about to delete BOM with ID:" +
          id +
          " press OK to continue or cancel to cancel"
      )
    ) {
      //console.log("GET BOM :id", props.selected.id);
      var bodyFormDataProjects = new FormData();
      bodyFormDataProjects.append("action", "disconnect");
      bodyFormDataProjects.append("token", user.token);
      bodyFormDataProjects.append("owner", Number(user.id));
      bodyFormDataProjects.append("id", Number(id));
      bodyFormDataProjects.append("table", "bom");
      axios({
        method: "post",
        url: config.saveAPI,
        data: bodyFormDataProjects,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then((res) => {
          if (res?.data?.data === "Expired") {
            toast.warn("Error:  Please re-login your session has expired", {
              position: toast.POSITION.BOTTOM_RIGHT,
              autoClose: 3500,
            });
          } else {
            toast.success(res.data.data, {
              position: toast.POSITION.BOTTOM_RIGHT,
              autoClose: 1000,
            });
          }

          //remove spinner
          setLoading(false);
          //refresh data
          //alert("refreshBom:" + refreshBom);
          setRefreshBom(!refreshBom);
          //console.log("RECIEVED BOM DATA ", res.data);
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          }
          toast.warn(
            "Hmmm, geting labor for this project is having troubles:  " +
              error?.response?.status,
            {
              position: toast.POSITION.BOTTOM_RIGHT,
              autoClose: 4500,
            }
          );
          console.log(err);
        });
    } else {
      return;
    }
  };

  return (
    <div className="bom p-3">
      <Form onSubmit={onFormSubmit} onReset={restart}>
        <div className="row justify-content-starts">
          {message ? (
            <div
              className="alert alert-success border-1 border-dashed card-rounded p-5 p-lg-10 mb-1 "
              dangerouslySetInnerHTML={{
                __html: message,
              }}
            />
          ) : null}

          {/* { "IF ther eare BOMs already in project list them with remove option "} */}

          <h1 className=" ribbon ribbon-start ribbon-vertical">
            <span className="svg-icon svg-icon-2hx svg-icon-warning me-3">
              <div className="ribbon-label bg-blue">
                <i className="fa-duotone fa-square-plus text-light me-3 fs-3"></i>{" "}
                <span className="fs-3 me-3">Add New BOM</span>{" "}
                <strong className="fs-7">{selected.customername}</strong>
              </div>
            </span>
          </h1>

          <div className="ribbon ribbon-triangle ribbon-top-end border-light-gray">
            <span className="ribbon-label bg-blue">
              <strong className="fs-7 text-light">{selected.id}</strong>
            </span>
          </div>
          {componentJSONFormat?.description ? (
            <p> {componentJSONFormat.description}</p>
          ) : null}

          {formFieldsBasedOnJSON}

          {showButton ? (
            <div className="my-2 col-12">
              <Button variant="primary" type="submit" className="mb-4 ">
                Add New BOM
              </Button>
            </div>
          ) : null}
        </div>
        <div
          className={
            user?.id && selected?.id
              ? "  d-flex "
              : "  d-flex border-1 border-dashed card-rounded border-danger justify-content-around"
          }
        >
          <Form.Group className="my-2 col-md-2 col-sm-12">
            <Form.Control
              type="text"
              name="customerID"
              defaultValue={user.id ? Number(user.id) : ""}
              placeholder="View Name"
              className={user?.id ? "bg-light-success" : "bg-light-danger"}
              hidden
            />
          </Form.Group>

          <Form.Group className="my-2 col-md-2 col-sm-12">
            <Form.Control
              type="text"
              name="customerID"
              defaultValue={
                selected?.id ? JSON.stringify(Number(selected.id)) : ""
              }
              placeholder="Select Customer first from above search field"
              className={selected?.id ? "bg-light-success" : "bg-light-danger"}
              hidden
            />
          </Form.Group>
        </div>
      </Form>

      {loading && (
        <div class="page-loader flex-column">
          <span class="spinner-border text-primary" role="status"></span>
          <span class="text-muted fs-6 fw-semibold mt-5">Loading...</span>
        </div>
      )}
      <GetBOM
        selected={selected}
        user={user}
        projectid={selected.id}
        type={"edit"}
        deleteItem={deleteItem}
        refresh={refreshBom}
      />
    </div>
  );
};

export default EditBom;
