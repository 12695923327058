import { createSlice } from "@reduxjs/toolkit";

const initialStateValue = {
	component: [],
};
//creating customers
export const invoiceSlice = createSlice({
	name: "invoices",
	initialState: { value: initialStateValue },
	reducers: {
		updateInvoiceStore: (state, action) => {
			state.value = action.payload;
		},
		unloadedInvoiceStore: (state) => {
			state.value = initialStateValue;
		},
	},
});

export const { updateInvoiceStore, unloadedInvoiceStore } = invoiceSlice.actions;

export default invoiceSlice.reducer;
