import React, { useState, useRef } from "react";
import { Typeahead } from "react-bootstrap-typeahead";
import { Form } from "react-bootstrap";

export default function TypeAheadCustomers({
	props,
	options,
	selected,
	selectedProject,
	setSelectedProject,
}) {
	const [projectSelected, setProjectSelected] = useState([]);
	const [searchFilter, setSearchFilter] = useState("customername");
	const [project, setStateCustomer] = useState(false);
	//const options = options;
	const filtered = options.filter((project) => {
		return project.customer === selected.id;
	});
	const typeaheadProjectRef = useRef(null);

	function changeFilter(newFilter) {
		setSearchFilter(newFilter);
		setSelectedProject([]);
		document.getElementById("typeaheadProject");
		console.log("PROJECT-CUSTOMER", filtered);
		console.log("ORIGINAL", options);
	}

	function changeProject(project) {
		//console.log(" selected", selectedProject);
		setProjectSelected(project);
		setSelectedProject(...project);
		console.log("SELECTED PROJECT FROM PARENT", selectedProject);
	}

	return (
		<div className="p-4 rounded bg-light my-3">
			<Form.Group>
				<Form.Label>Start by selecting Project</Form.Label>
				<Form.Control
					className="mb-2"
					as="select"
					defaultValue={searchFilter.toString()}
					onChange={(e) => {
						changeFilter(e.target.value);
					}}
				>
					<option value="firstname">
						Change Filter (search by Customer Name is default)
					</option>
					<option value="projectname">by project name</option>
					<option value="datecreated">by date created</option>
					<option value="customername">By customer name</option>
					<option value="id">By project ID </option>
				</Form.Control>
			</Form.Group>

			<Form.Group>
				<Typeahead
					className="mb-2"
					id="typeaheadProject"
					labelKey={searchFilter}
					onChange={changeProject.bind(this)}
					minLength={2}
					options={filtered}
					ref={typeaheadProjectRef}
					placeholder="Type project info..."
					renderMenuItemChildren={(option) => (
						<div
							key={option.id}
							className="flex horizontal block-link space-around "
						>
							<span
								className={`badge  text-dark ${
									searchFilter === "id" ? "badge-success text-white" : "badge-light"
								}`}
							>
								{option.id}{" "}
							</span>
							<span
								className={`badge  text-dark ${
									searchFilter === "customername"
										? "badge-success"
										: "badge-light"
								}`}
							>
								{option.customername}{" "}
							</span>
							<span
								className={`badge  text-dark ${
									searchFilter === "datecreated"
										? "badge-success"
										: "badge-light"
								}`}
							>
								{option.datecreated}{" "}
							</span>
							<span
								className={`badge  text-dark ${
									searchFilter === "projectname"
										? "badge-success"
										: "badge-light"
								}`}
							>
								{option.projectname}{" "}
							</span>
						</div>
					)}
				/>
			</Form.Group>

			{projectSelected.length > 0 ? (
				<div className="block bg-light-warning">
					<span>Project # </span>
					<span>
						{projectSelected[0]?.id +
							", " +
							projectSelected[0]?.projectname +
							" @" +
							selected.address1}
					</span>
				</div>
			) : null}
		</div>
	);
}
