import { createSlice } from "@reduxjs/toolkit";
const initialStateValue = {
  data: [],
};
//creating USER Settings
export const settingsSlice = createSlice({
  name: "settings",
  initialState: { value: initialStateValue },
  reducers: {
    updateSettigns: (state, action) => {
      state.value = action.payload;
    },
    removeSettings: (state) => {
      state.value = initialStateValue;
    },
  },
});

export const { updateSettigns, removeSettings } = settingsSlice.actions;

export default settingsSlice.reducer;
