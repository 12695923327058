import React, { useEffect, useState } from "react";
import { InputGroup } from "react-bootstrap";
import { useSelector } from "react-redux";

function CalendarSettings(props) {
  const user = useSelector((state) => state.user.value);
  const settings = useSelector((state) => state.settings.value);
  const [dropdownValue, setDropdownValue] = useState("");
  const [save, setSave] = useState("disabled");
  const [message, setMessage] = useState("");
  const SaveSettings = props.SaveSettings;

  useEffect(() => {
    // Set up
    if (settings?.component?.lenght > 0) {
      setDropdownValue(
        settings?.component?.length > 0 ? settings.component[0].calendar : ""
      );
      console.log("SETINGS Calendar:", dropdownValue);
    }
  }, [settings]);

  return user.loginStatus ? (
    <div className="col-md-4 col-sm-12 ">
      <div className="card card-custom card-stretch shadow  p-4">
        <h4 className="align-left m-2 mb-4"> Calendar Time Frame </h4>
        <select
          className="form-select  h-40px  "
          data-control="select2"
          data-hide-search="true"
          data-placeholder="Status"
          data-kt-ecommerce-order-filter="status"
          value={dropdownValue}
          onChange={(e) => {
            setDropdownValue(e.target.value);
            setSave("");
          }}
        >
          <option>Select calendar</option>
          <option value="6">6 months in past 6 months in future</option>
          <option value="3">3 months in past 9 months in future</option>
          <option value="12">12 months prespective in future</option>
        </select>
        <div className="m-4 d-flex flex-column">
          <p>
            Currently Sellected{" "}
            <span className="text-success">{dropdownValue} </span>
          </p>

          <button
            className="btn btn-primary my-4 mx-2 mt-0"
            disabled={save}
            onClick={(e) => {
              e.preventDefault();
              setMessage(SaveSettings("calendar", dropdownValue));
            }}
          >
            {save ? save : "Update"}
          </button>
          {message && (
            <div
              dangerouslySetInnerHTML={{
                __html: message ? message : "",
              }}
            />
          )}

          <p>You can set up your Calandar to show projects :</p>
          <ul className="nav flex-column">
            <li>6 months in past 6 in future</li>
            <li>3 months in past 9 in future</li>
            <li>12 months in future</li>
          </ul>
        </div>
      </div>
    </div>
  ) : null;
}

export default CalendarSettings;
