import React, { useState } from "react";
import { useSelector } from "react-redux";
import Home from "../components/Home/home.layouts";
//import SideBar from "../components/Sidebar/sidebar";
//import CustomerStore from "../components/Store/store.customers.projects";
//import TypeAheadCustomers from "../components/Common/CreateComponent/find.customer.typeahead";
//import TypeAheadProjects from "../components/Common/CreateComponent/find.project.typeahead";
import Error from "./error";

const CreatorHome = (props) => { 

	const user = useSelector((state) => state.user.value); 
	//console.log("Tools:", user);
	 
  return user.loginStatus ? ( 
      
          <Home user={user} />
 
   
  ) : (
    <Error error="User has been logged out please login to have access" />
  );
};

export default CreatorHome;
