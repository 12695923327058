import { createSlice } from "@reduxjs/toolkit";

const initialStateValue = {
	token: "",
	lastlogin: "",
	company: "",
	companylogo: "",
	username: "",
	firstname: "",
	lastname: "",
	address: "",
	city: "",
	zip: "",
	phone: "",
	email: "",
	loginStatus: false,
	id: "",
	userhelpword: "",
	tokenkey: "",
};

export const userSlice = createSlice({
  name: "user",
  initialState: { value: initialStateValue },
  reducers: {
    login: (state, action) => {
      state.value = action.payload;
    },
    logout: (state) => {
      state.value = initialStateValue;
    },
  },
});

export const { login, logout } = userSlice.actions;

export default userSlice.reducer;
