/**
 *  @file config.js
 *  @brief Brief
 */

class Config {
  //process.env.NODE_ENV // this is a process to pick from node so we can pass environment variable to application // if we need to  TBD

  constructor() {
    this.contracts = "/json/contracts/";
    this.preparedcontracts = "/json/contracts/defined/";
    this.paypal = { clientId: "test" };
    this.saveAPI = "/api/php-class/index.php";
    this.userCheckApi = "/api/php-class/index.php";
    this.sendEmailApi = "/api/php-class/sendEmail2.php";
    this.footerData = {
      backgroundColor: "#434343",
      notes: "© Stefadesign Ltd. All rights reserved.",
    };
    this.headerData = {
      backgroundColor: "#434343",
      logoClassname: "logo",
      orientation: "vertical",
      navigations: [
        {
          className: "navigation-header text-uppercase font-white",
          type: "collapse",
          position: "top",
          items: [
            {
              name: "Home",
              url: "/home",
              icon: "fa-duotone fa-house text-danger me-2",
            },
            {
              name: "Generators",
            },
            {
              name: "Project Generator",
              url: "/project-creator",
              icon: "fa-duotone fa-plus text-danger me-2 ",
            },
            {
              name: "Terms and Conditions",
              url: "/creator",
              icon: "fa-duotone fa-building-columns text-danger me-2 ",
            },
            {
              name: "Invoice Generator",
              url: "/invoice",
              icon: "fa-duotone fa-money-check-dollar text-danger me-2  ",
            },
            {
              name: "Lookup",
            },
            {
              name: "Project Lookup",
              url: "/project-lookup",
              icon: "fa-duotone fa-magnifying-glass-plus text-danger me-2 ",
            },
            {
              name: "Customer Lookup",
              url: "/customer-lookup",
              icon: "fa-duotone fa-user-magnifying-glass text-danger me-2 ",
            },
            {
              name: "Invoice Lookup",
              url: "/invoice-lookup",
              icon: "fa-duotone fa-magnifying-glass-dollar text-danger me-2 ",
            },

            {
              name: "Estimate Lookup",
              url: "/invoice-lookup",
              icon: "fa-sharp fa-light fa-money-check-dollar-pen text-danger me-2 ",
            },
            {
              name: "Tools",
            },
            {
              name: "Take/Add photo",
              url: "/photos",
              icon: "fa-duotone fa-camera-retro text-danger text-danger me-2  ",
            },
            {
              name: "Calendar Schedule",
              url: "/calendar",
              icon: "fa-duotone fa-calendar text-danger text-danger me-2 ",
            },
            {
              name: "Import Customers",
              url: "/import",
              icon: "fa-duotone fa-camera-retro text-danger text-danger me-2  ",
            },
            {
              name: "Create New",
            },
            {
              name: "Create Labor or BOM",
              url: "/creator",
              icon: "fa-duotone fa-layer-plus text-danger me-2 ",
            },
            {
              name: "New Estimate",
              url: "/invoice",
              icon: "fa-duotone fa-flying-disc text-danger me-2 ",
            },
            {
              name: "User",
            },
            {
              name: "Login/Logout",
              url: "/login",
              icon: "fa-duotone fa-right-to-bracket text-danger me-2 ",
              // sublist:[
              //   {
              //     name: "Take a photo",
              //     url: "/photos",
              //     icon: "fa-duotone fa-camera-retro text-danger",
              //   },
              //   {
              //     name: "Creator",
              //     url: "/creator",
              //     icon: "fa-duotone fa-layer-plus text-danger",
              //   },
              // ]
            },
            {
              name: "Profile",
              url: "/login",
              icon: "fa-duotone fa-user text-danger me-2 ",
            },
          ],
        },
      ],
    };
  }
}
export default new Config();
