import React, { useState, useRef, useEffect } from "react";
import { Typeahead } from "react-bootstrap-typeahead";
import { Form } from "react-bootstrap";
import { updateAdutCustomer, updateAuditProject } from "../../../features/adut";
import { addAdutCustomer } from "../../../features/store-actions";
import { useDispatch, useSelector } from "react-redux";

export default function TypeAheadCustomers({
  props,
  options,
  selected,
  setSelCustomer,
  setisPaneOpen,
}) {
  const [selectedCustomer, setSelectedCustomer] = useState([]);
  const [searchFilter, setSearchFilter] = useState("firstname");
  const [customer, setStateCustomer] = useState(false);
  //const options = options;
  const typeaheadRef = useRef(null);
  const dispatch = useDispatch();
  const addCustomer = (item) => {
    dispatch(addAdutCustomer(item));
  };

  function changeFilter(newFilter) {
    setSearchFilter(newFilter);
    setSelectedCustomer([]);
    document.getElementById("typeahead");
  }

  function changeCustomer(customer) {
    console.log(" selected", customer);
    setSelectedCustomer(customer);
    setSelCustomer(...customer);

    console.log("SELECTED CUSTOMER FROM PARENT", selected);
  }

  useEffect(() => {
    if (selected) changeCustomer([selected]);
    addCustomer({ customer: selected });
  }, [selected]);

  // function setCustomer(customer) {
  // 	console.log(" adding to state ", selectedCustomer);
  // 	setSelectedCustomer(customer);
  // 	setSelCustomer(customer);
  // 	console.log("SELECTED CUSTOMER FROM PARENT", selected);
  // }

  return (
    <div className="mt-4 mb-4 col-sm-12 rounded bg-light border border-1 p-3">
      {" "}
      <Form.Group controlId="formBasicSelect">
        <Form.Label>
          <h4 className="title fs-6 ">Select Customer</h4>
        </Form.Label>
        <Form.Control
          className="mb-2"
          as="select"
          defaultValue={searchFilter.toString()}
          onChange={(e) => {
            changeFilter(e.target.value);
          }}
        >
          <option value="firstname">
            Change Filter (search by name is default)
          </option>
          <option value="lastname">by Last Name</option>
          <option value="firstname">by First Name</option>
          <option value="phone">By Phone Number</option>
          <option value="email">By Email </option>
        </Form.Control>

        <Typeahead
          className="mb-2"
          id="typeahead"
          labelKey={searchFilter}
          onChange={changeCustomer.bind(this)}
          minLength={2}
          options={options}
          ref={typeaheadRef}
          defaultValue={selected?.firstname?.toString()}
          placeholder={
            selected?.firstname
              ? selected?.firstname?.toString()
              : "Type customer info..."
          }
          renderMenuItemChildren={(option) => (
            <div key={option.id} className="flex horizontal block-link ">
              <span
                className={`badge  text-dark ${
                  searchFilter === "firstname"
                    ? "badge-success text-white"
                    : "badge-light"
                }`}
              >
                {option.firstname}
              </span>
              <span
                className={`badge  text-dark ${
                  searchFilter === "lastname"
                    ? "badge-success text-light"
                    : "badge-light"
                }`}
              >
                {option.lastname}
              </span>
              <span
                className={`badge  text-dark ${
                  searchFilter === "phone"
                    ? "badge-success text-light"
                    : "badge-light"
                }`}
              >
                {option.phone}
              </span>
              <span
                className={`badge  text-dark ${
                  searchFilter === "email"
                    ? "badge-success text-light"
                    : "badge-light"
                }`}
              >
                {option.email}
              </span>
            </div>
          )}
        />
      </Form.Group>
      {selectedCustomer[0] ? (
        <>
          {selectedCustomer[0]?.email || selectedCustomer[0]?.phone ? (
            <a
              className="btn btn-primary mt-0 d-flex align-items-center"
              onClick={() => setisPaneOpen(true)}
            >
              <i className="fa-duotone fa-solid fa-street-view fs-2"></i>

              <section className=" mb-2">
                <span className="me-2">Click for more customer info: </span>

                <span className="me-2">
                  {selectedCustomer[0]?.firstname +
                    ", " +
                    selectedCustomer[0]?.lastname}
                </span>
                <span>
                  {selectedCustomer[0]?.address1 +
                    " , " +
                    selectedCustomer[0]?.city1 +
                    " , " +
                    selectedCustomer[0]?.state1}
                </span>
              </section>
            </a>
          ) : null}
        </>
      ) : null}
    </div>
  );
}
