import React, { useState } from "react";
import { useSelector } from "react-redux";
import Photos from "../components/Common/photos/capture.photo";
//import CustomerStore from "../components/Common/CreateComponent/store.customers.projects";
import Store from "../components/Store/store.customers.projects";
import TypeAheadCustomers from "../components/Common/CreateComponent/find.customer.typeahead";
import TypeAheadProjects from "../components/Common/CreateComponent/find.project.typeahead";
import Error from "./error";

const Create = (props) => {
	 
	const customers = useSelector((state) => state.customers.value);
	const projects = useSelector((state) => state.projects.value);

	const user = useSelector((state) => state.user.value);
	const [selected, setSelCustomer] = useState([]);
	const [selectedProject, setSelectedProject] = useState([]);
	console.log("SELECTED CUSTOMER FROM PARENT COMPONENT:", selected);
	console.log("SELECTED PROJECT FROM PARENT COMPONENT:", selectedProject);
	const Convertor = () => {
		var result = [];
		result.push(selected ? selected[0] : selected);
		return result;
	};
	return user.loginStatus ? (
    <div className="card card-flush mt-6">
      <Store />
     
      <div class="card-header mt-6">
        <div class="card-title flex-column">
          <h3 class="title p-0 mb-1">Photos</h3>
          <div class="fs-6 text-gray-400">Take a photo with your mobile device and add to project </div>
        </div>
      </div>
    
        <div className="card-body pt-0">
          <div className="row bg-light">
            <div className="my-2 col-md-6 col-sm-12">
              <TypeAheadCustomers
                options={customers.component}
                setSelCustomer={setSelCustomer}
                selected={selected}
              />
            </div>
            <div className="my-2 col-md-6 col-sm-12">
              <TypeAheadProjects
                options={projects.component}
                selectedProject={selectedProject}
                setSelectedProject={setSelectedProject}
                selected={selected}
              />
            </div>
          </div>

          <Photos selected={selected} selectedProject={selectedProject} />

          <div className=" mt-5 mb-5">
            {selected?.address1 ? (
              <>
                <strong>Customer site: </strong>
                <span className="tex-muted">{selected.address1}</span>
              </>
            ) : null}
            {selectedProject?.projectname ? (
              <>
                <strong>Project: </strong>
                <span className="tex-muted">{selectedProject.projectname}</span>
              </>
            ) : null}
          </div>
        </div>
     
    </div>
  ) : (
    <Error error="User has been logged out please login to have access" />
  );
};

export default Create;
