import React, { useState, useEffect } from "react";      
import { toast } from "react-toastify";
import Config from "../../../config";
import { useSelector } from "react-redux";

 
import axios from "axios";  
const ref = React.createRef();

const GetGalleryDataWithDisplay = (props) => { 
  const [photoGalery, setPhotosGalery] = useState([]);
  const user = useSelector((state) => state.user.value);
  const projectId = props.projectId; 
  const type = props.type;
  const [loginError, setLoginError] = useState("");
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  console.log("GALLERY: projectId ", projectId);
  console.log("GALLERY: userId ", user.id);
  
  useEffect(() => { 
    getAllPhotos(projectId);  
  }, [refresh]);

 
  const getAllPhotos = (projectId) => {
    if (projectId) {
      var bodyFormDataProjects = new FormData();
      bodyFormDataProjects.append("action", "select");
      bodyFormDataProjects.append("owner", user.id);
      bodyFormDataProjects.append("token", user.token.trim());
      bodyFormDataProjects.append("projectid", projectId);
      bodyFormDataProjects.append("table", "photos");
      axios({
        method: "post",
        url: Config.saveAPI,
        data: bodyFormDataProjects,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then((res) => {
          let apiResponse = res;
          let validated = res.data ? true : false;
          if (validated) {
            //set data
            setPhotosGalery([]);
            setPhotosGalery(res.data);
            //console.log("GALLERY", res.data);
            // toast.success("Retreiving All photos fro this project complete  ", {
            //   position: toast.POSITION.BOTTOM_RIGHT,
            //   autoClose: 1500,
            // });
          }
          setLoginError(
            validated
              ? null
              : "Server message: Something went wrong with loading ALL PHOTOS for this project ID"
          );
          //console.log("RECIEVED ALL PHOTOS DATA ", res.data);
        })
        .catch((err) => {
          toast.warn(
            "Hmmm, geting Gallery for this project is having troubles:  " + err,
            {
              position: toast.POSITION.BOTTOM_RIGHT,
              autoClose: 4500,
            }
          );
          console.log(err);
        });
    }
  };
 

  const deleteItem = (id, table) => {
    //action: disconnect
    // Removing($table, $owner ,$action, $id, $token)

    if (
      id &&
      window.confirm(
        "You are about to delete " +
          table +
          " with ID:" +
          id +
          " press OK to continue or cancel to cancel"
      )
    ) {
      //console.log("GET BOM :id", props.selected.id);
      var bodyFormDataProjects = new FormData();
      bodyFormDataProjects.append("action", "disconnect");
      bodyFormDataProjects.append("token", user.token);
      bodyFormDataProjects.append("owner", Number(user.id));
      bodyFormDataProjects.append("id", Number(id));
      bodyFormDataProjects.append("table", table);
      axios({
        method: "post",
        url: Config.saveAPI,
        data: bodyFormDataProjects,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then((res) => {
          if(res?.data?.data==="Expired"){
            toast.warn("Error:  Please re-login your session has expired", {
              position: toast.POSITION.BOTTOM_RIGHT,
              autoClose: 3500,
            });
          }
          else{
            toast.success(res.data.data, {
              position: toast.POSITION.BOTTOM_RIGHT,
              autoClose: 1500,
            });
          }
          //remove spinner
          setLoading(false);
          //refresh data
          //alert("refreshBom:" + refreshBom);
          setRefresh(!refresh);
          //console.log("RECIEVED BOM DATA ", res.data);
        })
        .catch((err) => {
          toast.warn(
            "Hmmm, geting labor for this project is having troubles:  " + err,
            {
              position: toast.POSITION.BOTTOM_RIGHT,
              autoClose: 4500,
            }
          );
          console.log(err);
        });
    } else {
      return;
    }
  };

 
  return (
    <>
      {photoGalery.length > 0 ? (
        <div className=" card stretch bg-light-primary p-3">
       
          <h1 className=" ribbon ribbon-start ribbon-vertical">
            <span className="svg-icon svg-icon-2hx svg-icon-warning me-3">
              <div className="ribbon-label bg-blue">
                <i className="fa-duotone fa-square-plus text-light me-3 fs-3"></i>{" "}
                <span className="fs-3 me-3">Photos</span>{" "}
                <strong className="fs-7">{props.selected?.projectname}</strong>
              </div>
            </span>
          </h1>

          <div className="ribbon ribbon-triangle ribbon-top-end border-light-gray">
            <span className="ribbon-label bg-blue">
              <strong className="fs-7 text-light">{props.selected?.id}</strong>
            </span>
          </div>

          <div className="w-100 row">
            {photoGalery?.length > 0 ? (
              photoGalery?.map((item, index) => {
                return (
                  <div className="col-lg-3  col-sm-12 col-md-6 p-2" key={index}>
                    <div className="card card-custom overlay overflow-hidden p-2">
                      <div className="card-body p-0">
                        <div className="overlay-wrapper d-flex">
                          <img
                            src={item.data}
                            alt=""
                            className="w-100 rounded"
                          />
                        </div>
                        <div className="overlay-layer bg-dark bg-opacity-10 flex-column">
                          <div className="bg-dark text-warning m-1 p-3 rounded-3">
                            Date:{item.created}
                          </div>
                          {type === "edit" ? (
                            <button
                              className="btn btn-danger text-white center mb-0"
                              onClick={() => deleteItem(item.id, "photos")}
                            >
                              Delete
                            </button>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <div className="alert alert-warning d-flex align-items-center p-5 mb-10">
                <span className="svg-icon svg-icon-2hx svg-icon-primary me-3">
                  <i className="fa-duotone fa-face-holding-back-tears fs-1"></i>
                </span>

                <div className="d-flex flex-column">
                  <h5 className="mb-1">Not an issue but no photos</h5>
                  <span>
                    It looks like <strong></strong> does not have any gallery
                    created yet. To create a new gallery go to Photos section
                    and start by sleecting project missing photos.
                  </span>
                </div>
              </div>
            )}
          </div>
        </div>
      ) : (
        <div className="alert alert-warning d-flex align-items-center p-5 mb-10">
          <span className="svg-icon svg-icon-2hx svg-icon-primary me-3">
            <i className="fa-duotone fa-face-holding-back-tears fs-1"></i>
          </span>

          <div className="d-flex flex-column">
            <h5 className="mb-1">Not an issue but no photos</h5>
            <span>
              It looks like <strong></strong> does not have any gallery created
              yet. To create a new gallery go to Photos section and start by
              sleecting project missing photos.
            </span>
          </div>
        </div>
      )}
    </>
  );
}
 export default GetGalleryDataWithDisplay;