import { useState, useEffect, useRef } from "react";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/material_blue.css";
import DataTable from "react-data-table-component";
import IvoiceView from "../View/invoice";
import Modal from "react-modal";
import config from "../../../config";
import { useDispatch } from "react-redux";
import { updateInvoiceStore } from "../../../features/invoices";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
/**
 * react-data-table-component package to display data in table format with paginationa
 * flatpickr and react-flatpickr for date picker
 */

const TableInvoiceView = (props, selectProject) => {
  const dispatch = useDispatch();
  const [filteredData, setFilteredData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const datepickrRef = useRef(null);
  const [startdate, setStartDate] = useState(new Date(0));
  const [enddate, setEndDate] = useState(new Date("04/20/2030"));
  const dateField = props?.settings?.fields?.find((f) => f.type === "date");
  const dropdownField = props?.settings?.filter?.dropdownField;
  const [activeModal, setActiveModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [edit, setEdit] = useState(false);
  const [selectedC, setSelectedC] = useState([]);
  const [dropdownValue, setDropdownValue] = useState("");

  useEffect(() => {
    setFilteredData(props?.data ? [...props?.data] : []);
  }, [props?.data]);

  useEffect(() => {
    setFilteredData(props?.data ? [...props?.data] : []);
    if (searchTerm !== "") {
      setFilteredData([]);
      props?.settings.fields.forEach((e) => {
        switch (e.type) {
          case "string":
            setFilteredData((f) => [
              ...f,
              ...props?.data?.filter((d) =>
                d[e.name].toLowerCase().includes(searchTerm.toLowerCase())
              ),
            ]);
            break;
          case "title":
            setFilteredData((f) => [
              ...f,
              ...props?.data?.filter((d) =>
                d[e.name].toLowerCase().includes(searchTerm.toLowerCase())
              ),
            ]);
            break;
          case "number":
            setFilteredData((f) => [
              ...f,
              ...props?.data?.filter((d) =>
                d[e.name].toLowerCase().includes(searchTerm.toLowerCase())
              ),
            ]);
            break;
          case "version":
            setFilteredData((f) => [
              ...f,
              ...props?.data?.filter((d) =>
                d[e.name].toString().includes(searchTerm)
              ),
            ]);
            break;
          case "array":
            break;
          case "hidden":
            break;
          default:
            setFilteredData((f) => [
              ...f,
              ...props?.data?.filter((d) => d[e.name].includes(searchTerm)),
            ]);
        }
      });
    }

    if (dropdownValue !== "") {
      setFilteredData((f) =>
        f?.filter((d) => d[dropdownField] === dropdownValue)
      );
    }
    if (
      startdate?.getTime() != new Date(0).getTime() &&
      enddate?.getTime() != new Date(0).getTime()
    ) {
      setFilteredData((f) =>
        f?.filter(
          (d) =>
            new Date(d[dateField.name]).getTime() >= startdate.getTime() &&
            new Date(d[dateField.name]).getTime() <= enddate.getTime()
        )
      );
    }
    //find unique filteredData
    setFilteredData((f) =>
      f?.filter((value, index) => {
        const _value = JSON.stringify(value);
        return (
          index ===
          f.findIndex((obj) => {
            return JSON.stringify(obj) === _value;
          })
        );
      })
    );
  }, [searchTerm, dropdownValue, startdate?.getTime(), enddate?.getTime()]);

  const customStyles = {
    rows: {
      style: {
        minHeight: "50px", // override the row height
      },
    },
    headCells: {
      style: {
        paddingLeft: "8px", // override the cell padding for head cells
        paddingRight: "8px",
      },
    },
    cells: {
      style: {
        paddingLeft: "8px", // override the cell padding for props?.data cells
        paddingRight: "8px",
      },
    },
  };

  const col = props?.settings?.fields?.map((f) => {
    let sortable = true;
    switch (f.type) {
      case "array":
        sortable = false;
        break;
      default:
        sortable = true;
    }
    return {
      name: <div className="text-gray-800 fw-bold">{f.headerName}</div>,
      selector: (row) => row[f.name],
      cell: (row) => {
        switch (f.type) {
          case "html":
            return (
              <div
                className="text-gray-600 fw-bold"
                dangerouslySetInnerHTML={{
                  __html: row[f.name] ? row[f.name] : "",
                }}
              ></div>
            );
          case "price":
            return <div className="text-gray-600 fw-bold ">${row[f.name]}</div>;
          case "version":
            return (
              <div className="text-gray-600 fw-bold ">v-{row[f.name]}</div>
            );
          case "title":
            return (
              <div className={`"text-gray-600 w-400px ${row[f.width]}"`}>
                {row[f.name]}
              </div>
            );
          case "link":
            return (
              <a
                href
                className="text-blue-600 fw-bold link d-flex flex-column w-50 align-items-center"
                onClick={(e) => {
                  setEdit(true);
                  handleOpenModal(row[f.name], row);
                }}
              >
                <i className="fa-duotone fa-shield-exclamation fs-5 text-danger"></i>{" "}
                Delete
              </a>
            );
          case "delete":
            return (
              <a
                href
                className="text-blue-600 fw-bold link d-flex flex-column w-50 align-items-center"
                onClick={(e) => {
                  deleteItem(row[f.name]);
                  //handleOpenModal(row[f.name], row);
                }}
              >
                <i className="fa-duotone fa-shield-exclamation fs-5 text-danger"></i>{" "}
                Delete
              </a>
            );
          case "hidden":
            return (
              <>
                <a
                  href
                  className="text-blue-600 fw-bold link d-flex flex-column w-50 align-items-center"
                  onClick={(e) => {
                    setEdit(true);
                    handleOpenModal(row[f.name], row);
                  }}
                >
                  <i className="fa-duotone fa-print-magnifying-glass fs-5 text-danger"></i>{" "}
                  view
                </a>
              </>
            );
          case "date":
            return <div className="text-gray-600  ">{row[f.name]}</div>;
          case "persentage":
            return <div className="text-gray-600  "> {row[f.name]} %</div>;
          default:
            return <div className="text-gray-600  ">{row[f.name]}</div>;
        }
      },
      sortable,
      sortFunction: (a, b) => {
        switch (f.type) {
          case "number":
            return a[f.name] - b[f.name];
          case "date":
            return new Date(a[f.name]) - new Date(b[f.name]);
          case "string":
            return a[f.name].localeCompare(b[f.name]);
          case "title":
            return a[f.name].localeCompare(b[f.name]);
        }
      },
    };
  });
  const handleOpenModal = (val, item) => {
    // console.log("INVOICE  TABLE VIEW LOOKUP val:", val);
    // console.log("INVOICE TABLE VIEW LOOKUP item:", item);
    // console.log("INVOICE TABLE JSON:",JSON.parse(item.json));
    setSelectedC(item);
    //console.log("ITEM IN VEW:",item);
    //setActiveModal(val.split(" ").join(""));
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setEdit(false);
    setActiveModal("");
  };

  const deleteItem = (id) => {
    //action: disconnect
    // Removing($table, $owner ,$action, $id, $token)

    if (
      id &&
      window.confirm(
        "You are about to delete Invoice with ID:" +
          id +
          " press OK to continue or cancel to cancel"
      )
    ) {
      //console.log("GET BOM :id", props.selected.id);
      var bodyFormDataProjects = new FormData();
      bodyFormDataProjects.append("action", "disconnect");
      bodyFormDataProjects.append("token", user.token);
      bodyFormDataProjects.append("owner", Number(user.id));
      bodyFormDataProjects.append("id", Number(id));
      bodyFormDataProjects.append("table", "invoices");
      axios({
        method: "post",
        url: config.saveAPI,
        data: bodyFormDataProjects,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then((res) => {
          if (res?.data?.data === "Expired") {
            toast.warn("Error:  Please re-login your session has expired", {
              position: toast.POSITION.BOTTOM_RIGHT,
              autoClose: 3500,
            });
          } else {
            toast.success(res.data.data, {
              position: toast.POSITION.BOTTOM_RIGHT,
              autoClose: 1000,
            });
          }
          //remove spinner
          //refresh data
          //setRefreshBom(!refreshBom);
          //console.log("RECIEVED BOM DATA ", res.data);
          //refreshInvoices
          StoreInvoices();
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          }
          toast.warn(
            "Hmmm, geting labor for this project is having troubles:  " +
              error?.response?.status,
            {
              position: toast.POSITION.BOTTOM_RIGHT,
              autoClose: 4500,
            }
          );
          console.log(error);
        });
    } else {
      return;
    }
  };
  const StoreInvoices = () => {
    //invoices update
    var bodyFormDataInvoice = new FormData();
    bodyFormDataInvoice.append("action", "select");
    bodyFormDataInvoice.append("token", user.token);
    bodyFormDataInvoice.append("owner", Number(user.id));
    bodyFormDataInvoice.append("table", "invoices");

    axios({
      method: "post",
      url: config.saveAPI,
      data: bodyFormDataInvoice,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((res) => {
        toast.success("IVNOICE's have been updated  ", {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 1500,
        });
        //set store
        dispatch(
          updateInvoiceStore({
            component: res.data,
          })
        );
      })
      .catch((err) => {
        toast.warn("Hmmm, projects store is having troubles:  " + err, {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 4500,
        });
        console.log(err);
      });
  };

  const user = props.user;
  //console.clear();
  //console.log("User in Table Invoice view", user );
  return (
    <div className="card card-flush mt-6">
      <div className="card-header mt-6">
        <div className="card-title flex-column">
          <h3 className="title p-0 mb-1">{props.title}</h3>
          <div className="fs-6 text-gray-400">{props.subtitle}</div>
        </div>
      </div>
      <div className="card-header">
        <div className="d-flex align-items-center position-relative my-1">
          <span className="svg-icon svg-icon-1 position-absolute ms-4">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                opacity="0.5"
                x="17.0365"
                y="15.1223"
                width="8.15546"
                height="2"
                rx="1"
                transform="rotate(45 17.0365 15.1223)"
                fill="currentColor"
              />
              <path
                d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z"
                fill="currentColor"
              />
            </svg>
          </span>

          <input
            type="text"
            data-kt-ecommerce-order-filter="search"
            className="form-control form-control-solid w-250px h-40px ps-14"
            placeholder="Search Order"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
        <div className="input-group w-250px d-flex align-items-center position-relative my-1">
          <Flatpickr
            ref={datepickrRef}
            data-input
            className="d-flex"
            onChange={([date1, date2]) => {
              if (date1) {
                setStartDate(date1);
              }
              if (date2) {
                setEndDate(date2);
              }
            }}
            options={{
              mode: "range",
              altInputClass: "hide",
              enableTime: true,
              dateFormat: "M d Y H:i",
              wrap: true,
            }}
          >
            {/* 		maxDate: new Date(),
							minDate: new Date("01-01-2018"), */}
            <input
              type="text"
              className="form-control form-control-solid h-40px rounded rounded-end-0"
              placeholder="Pick date range"
              data-input
            />
            <button data-toggle className="d-none" />
          </Flatpickr>
          <button
            className="btn btn-icon btn-light h-40px"
            onClick={() => {
              setStartDate(new Date(0));
              setEndDate(new Date(0));
              datepickrRef.current.flatpickr.clear();
            }}
          >
            {" "}
            <span className="svg-icon svg-icon-2">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  opacity="0.5"
                  x="7.05025"
                  y="15.5356"
                  width="12"
                  height="2"
                  rx="1"
                  transform="rotate(-45 7.05025 15.5356)"
                  fill="currentColor"
                />
                <rect
                  x="8.46447"
                  y="7.05029"
                  width="12"
                  height="2"
                  rx="1"
                  transform="rotate(45 8.46447 7.05029)"
                  fill="currentColor"
                />
              </svg>
            </span>{" "}
          </button>
        </div>
        <div className="w-100 mw-150px d-flex align-items-center position-relative my-1">
          <select
            className="form-select form-select-solid h-40px"
            data-control="select2"
            data-hide-search="true"
            data-placeholder="Status"
            data-kt-ecommerce-order-filter="status"
            value={dropdownValue}
            onChange={(e) => setDropdownValue(e.target.value)}
          >
            {props?.settings?.filter?.dropdownValues?.map((f, i) => (
              <option value={f.value} key={i}>
                {f.name}
              </option>
            ))}
          </select>
        </div>
      </div>

      <div className="card-body pt-0">
        <DataTable
          columns={col}
          data={filteredData}
          customStyles={customStyles}
          pagination
        />

        <Modal
          isOpen={showModal}
          contentLabel="Editable"
          shouldCloseOnOverlayClick={true}
          appElement={document.getElementById("app")}
        >
          <div className="content  d-flex flex-column rounded">
            <header className="bg-light w-100 p-2 ">
              <h4 className="w-100">
                <button
                  className="close btn btn-primary mr-5"
                  id="close"
                  onClick={handleCloseModal}
                >
                  CLOSE
                </button>{" "}
                <button
                  className="close btn btn-primary mr-5"
                  id="close"
                  onClick={handleCloseModal}
                >
                  SEND AGAIN - missing fn(x)
                </button>{" "}
              </h4>
            </header>
            <main>
              <IvoiceView
                selected={selectedC}
                handleCloseModal={handleCloseModal}
                user={user}
              />
            </main>
          </div>
        </Modal>
      </div>
    </div>
  );
};
export default TableInvoiceView;
