import { useCallback, useState, useRef, useEffect } from "react";
import Dropzone from "./Dropzone";
import Papa from "papaparse";//https://www.papaparse.com/docs#config
import { toast } from "react-toastify";
//import DataTable from "./TableSearchView";
import DataTable from "../../../Lookup/TableCustomerView";
import axios from "axios";
import LoadingSpinner from "../../../../../utils/LoadingSpinner";
import { CSVLink } from "react-csv"; 
import Config from "../../../../../config";
 import { useSelector } from "react-redux";

const ImportCustomers = (props) => {
	const user = useSelector((state) => state.user.value);
	const projects = useSelector((state) => state.projects.value);

	const [files, setFiles] = useState([]);
	const [rowData, setRowData] = useState([]);
	const [error, setError] = useState("");
	const [isLoading, setIsLoading] = useState(false);
	const [uploadInProgress, setUploadInProgress] = useState(false);
	const [header, setHeader] = useState([]);
	const [hierarchy, setHierarchy] = useState([]);
	const [refreshComponent, setRefreshComponent] = useState(false);
 
const headerConfig = [
	"firstname",
	"lastname",
	"email",
	"phone",
	"phone2",
	"address1",
	"city1",
	"state1",
	"zip1",
	"company",
	"address2",
	"city2",
	"state2",
	"zip2",
	"phone3",
  ];	 
	useEffect(() => {
		setError("");
		setFiles([]);
		setRowData([]);
     	setHeader(headerConfig);
	}, [refreshComponent]);
	
  useEffect(() => {
		setHeader(headerConfig);
		if (rowData) {
			saveData();
		}
	  //progress();
	    
	}, [rowData]);
	const saveData = () => { 
		console.log("SAVEDATA:",rowData);
	}
	const progress = (t, s) => { 
		const bar = document.getElementById("bar");
		const percentageTag = document.getElementById("percentage");
		const total = t?parseInt(t):0;
		const solved = s?parseInt(s):0;
		const ruleOfThree = (num1, num2) => {
			const proportion = (num2 * 100) / num1;
			return Math.round(proportion * 10) / 10;
		};
		const updateBarLength = () => {
			const percentage = ruleOfThree(total, solved);
			bar.style.width = percentage + "%";
		};
		const updateText = () => {
			percentageTag.textContent = ruleOfThree(total, solved) + "%";
		};

		percentageTag.textContent = ruleOfThree(total, solved) + "%";
		updateBarLength();
		if(total && solved) updateText();
	}

	const settings = {
		fields: [
			{ name: "id", headerName: "Details", type: "link" },
			{
				name: "firstname",
				headerName: "First Name",
				type: "title",
				width: "min-w-150px",
			},
			{ name: "lastname", headerName: "Last Name", type: "string" },
			{ name: "email", headerName: "E-mail", type: "string" },
			{ name: "phone", headerName: "Mobile", type: "string" },
			{ name: "phone2", headerName: "Phone", type: "string" },
			{ name: "address1", headerName: "Address1", type: "string" },
			{ name: "city1", headerName: "City1", type: "string" },
			{ name: "state1", headerName: "State1", type: "string" },
			{ name: "zip1", headerName: "Zip1", type: "number" },
			{ name: "company", headerName: "Company", type: "string" },
			{ name: "address2", headerName: "Address2", type: "string" },
			{ name: "city2", headerName: "City2", type: "string" },
			{ name: "state2", headerName: "State2", type: "string" },
			{ name: "zip2", headerName: "Zip2", type: "number" },
			{ name: "phone3", headerName: "Phone3", type: "string" },
		],
		filter: {
			datePickerField: "startDate",
			dropdownField: "status",
			dropdownValues: [
				{ name: "All", value: "" },
				{ name: "Estimate", value: "estimate" },
				{ name: "Proposal", value: "proposal" },
				{ name: "Invalid", value: "invalid" },
				{ name: "Closed", value: "closed" },
				{ name: "Re-work", value: "rework" },
				{ name: "Invoice", value: "invoice" },
			],
		},
	};
	const sendCSVData = () => {
		//const api = props?.api.find((a) => a.name === "sendCodes");
		setUploadInProgress(true);
		console.log("DATA:::::>>>", rowData.length);
		const total = rowData.length;
		rowData.map((item, index) => {
			setIsLoading(true);
			console.log("uploading:" + index, item.firstname);
			//item.append("owner", user.id);
			let body = Object.assign({ owner: user.id }, item);
			 
			axios
				.post(
					Config?.saveAPI,
					{
						table: "customers",
						owner: user.id,
						data: JSON.stringify(body),
						action: "insert",
					},
					{
						headers: {
							"Content-Type": "multipart/form-data",
						},
					}
				)
				.then((res) => {
					setIsLoading(false);
					setFiles([]);

					progress(rowData.length, index + 1);
					if (total === index + 1) {
						toast.success(
							"Upload Successfull " +
								(index + 1) +
								" customers added to database",
							{
								position: toast.POSITION.TOP_RIGHT,
							}
						);
					}
				})
				.catch((e) => {
					setIsLoading(false);
				});
		});
		
	};

	const convertToJSONHierarchy = (data, headers) => {
		let obj = [];
		data.map((d) => {
			let temp = obj;
			for (let i = 0; i < headers.length; i++) {
				if (d[headers[i]] !== "") {
					const x = temp?.find((t) => t.name === d[headers[i]]);
					if (x) {
						temp = x?.child;
					} else {
						const newObj = getChildObject(d[headers[i]], i);
						temp.push(newObj);
						temp = newObj.child;
					}
				}
			}
		});
		return obj;
	};

	const getChildObject = (obj, i) => {
		let temp = {};
		temp.name = obj;
		temp.driver = i + 1;
		temp.child = [];
		return temp;
	};

	const generateHierarchy = (codes, header) => {
		if (codes.length > 0) {
			setHierarchy(convertToJSONHierarchy(codes, header));
			// toast.success("File Successfully Generated.", {
			//   position: toast.POSITION.TOP_RIGHT,
			// });
		}
	};

	const onDrop = useCallback(
		(acceptedFiles) => {
			setError("");
			acceptedFiles.forEach((file) => {
				const reader = new FileReader();
				setFiles([...acceptedFiles]);
				reader.onabort = () => console.log("file reading was aborted");
				reader.onerror = () => console.log("file reading has failed");
				reader.onload = async ({ target }) => {
					const csv = Papa.parse(target.result, {
						header: true,
						delimiter: ",",
						skipEmptyLines: true,
					});
          const headerCSV = Papa.parse(target.result, {
            header: false,
            preview:1,
						delimiter: ",",
						skipEmptyLines: true,
          });
          console.log("CSV HEADER:", headerCSV?.data.flat());
          
          console.log("CSV:", csv?.data);
          setHeader(headerCSV?.data[0].flat());
          console.log("CSV:", csv);
					const parsedData = csv?.data;
					let columns = [];
          console.log("HEADER",JSON.stringify(header))
					if (parsedData && parsedData?.length > 0) {
						setRowData(parsedData);
						columns = Object.keys(parsedData[0]);
					} else {
						setError("Corrupt File Format 1");
					}
					if (
						columns.length < 1 || 
						parsedData
							.map((d) => Object.keys(d)?.length <= header.length)
							.includes(false)
					) {
						setError("Corrupt File Format 2");
					}
				};
				reader.readAsText(file);
			});
		},
		[header]
	);

	const setErrorInChild = (error) => {
		setError(error);
	};
	const csvBtn = useRef();
	return (
		<div className="card card-flush mt-6">
 
	  <div class="card-header mt-6 ">
        <div class="card-title flex-column">
          <h3 class="title p-0 mb-1">Import Customers Bulk Operator</h3>
          <div class="fs-6 text-gray-400">Download template or select CSV file or download one, update and upload customers in database</div>
        </div>
      </div>
      {isLoading && <LoadingSpinner />}
      {uploadInProgress ? (
        <div className="card p-2">
          <h1 className="title">Uploading in Progress</h1>
          <div className="status-info  ">
            <div className="bar-wrap">
              <div id="bar" className="bar"></div>
            </div>
            <span id="percentage"></span>
          </div>
        </div>
      ) : null}

      <div className="bg-light primary p-3 shadow-sm d-flex flex-wrap justify-content-between align-items-center border-bottom py-6">
        <div className=" d-flex flex-wrap">
          <div>
            <i
              className="fa-light fa-circle-info mx-2"
              data-toggle="tooltip"
              data-placement="bottom"
              data-container="body"
              title="Click to download the sample template and update under provided header as suitable. "
            ></i>
            <CSVLink
              // ref={ref}
              className="text-gray-500 text-hover-dark"
              target="_self"
              filename="Template.csv"
              data={[header]}
            >
              Download Template{" "}
              <i className="fa-regular fa-arrow-down-to-line mx-1"></i>
            </CSVLink>
          </div>
          <div className="ms-5">
            <i
              className="fa-light fa-circle-info mx-2"
              data-toggle="tooltip"
              data-placement="bottom"
              data-container="body"
              title="Upload the downloaded template file that you have filled with Disposition codes. "
            ></i>
            <a
              className="text-gray-500 text-hover-dark"
              onClick={() => setError("")}
              data-bs-toggle="modal"
              data-bs-target="#modal_upload"
            >
              Upload <i className="fa-regular fa-arrow-up-to-line mx-1"></i>
            </a>
            <div
              className="modal fade"
              id="modal_upload"
              tabIndex="-1"
              aria-hidden="true"
            >
              <div className="modal-dialog modal-dialog-centered mw-650px">
                <div className="modal-content">
                  <form className="form" action="none">
                    <div className="modal-header">
                      <h2 className="fw-bold">Upload file</h2>
                      <div
                        className="btn btn-icon btn-sm btn-active-icon-primary"
                        data-bs-dismiss="modal"
                        onClick={() => setFiles([])}
                      >
                        <span className="svg-icon svg-icon-1">
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <rect
                              opacity="0.5"
                              x="6"
                              y="17.3137"
                              width="16"
                              height="2"
                              rx="1"
                              transform="rotate(-45 6 17.3137)"
                              fill="currentColor"
                            />
                            <rect
                              x="7.41422"
                              y="6"
                              width="16"
                              height="2"
                              rx="1"
                              transform="rotate(45 7.41422 6)"
                              fill="currentColor"
                            />
                          </svg>
                        </span>
                      </div>
                    </div>
                    <div className="modal-body pt-10 pb-15 px-lg-17">
                      <Dropzone
                        onDrop={onDrop}
                        uploadFile={files}
                        setError={setErrorInChild}
                        accept={{
                          "text/csv": [".csv"],
                        }}
                      />
                      {error !== "" && (
                        <span className="text-danger">Error: {error}</span>
                      )}
                      <div className="text-center">
                        <button
                          type="button"
                          data-bs-dismiss="modal"
                          className="btn btn-primary"
                          disabled={files.length === 0 || error !== ""}
                          onClick={() => {
                            if (error !== "") {
                              setFiles([]);
                              setRowData([]);
                            } else {
                              generateHierarchy(rowData, header);
                            }
                          }}
                        >
                          Done
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex flex-wrap align-items-center">
          {error === "" && files?.length > 0 ? (
            <>
              {files[0]?.name ? (
                <div className="text-danger m-2">
                  Data importing from :
                  <span className="badge bg-light-primary text-dark fs-6 ms-2">
                    {files[0]?.name}
                  </span>
                  <CSVLink
                    className="text-gray-500 text-hover-dark"
                    target="_self"
                    filename="customerList.csv"
                    data={rowData}
                  >
                    download Modified data 
                  </CSVLink>
                </div>
              ) : null}

              <button
                type="button"
                onClick={() => {
                  setRefreshComponent((r) => !r);
                }}
                className="btn btn-secondary btn-sm m-2"
              >
                Cancel
              </button>
              <button
                type="button"
                onClick={sendCSVData}
                className="btn btn-primary btn-sm m-2"
              >
                Import {rowData.length} items
              </button>
            </>
          ) : (
            <CSVLink
              className="text-gray-500 text-hover-dark"
              target="_self"
              filename="customerList.csv"
              data={rowData}
            >
              customers.csv
            </CSVLink>
          )}
        </div>
      </div>

      <DataTable
        data={rowData}
        settings={settings}
        projects={projects}
        title="Import Customer from CSV file into DB"
        subtitle="View data imported for customer from CSV file, use search to locate partiular customer"
      />
      {/* {hierarchy && (
				<div className="my-9 ms-5">
					{files?.length > 0 ? (
						<div className="text-danger my-2 fs-4">
							New Codes (save to upload)
						</div>
					) : (
						<div className="text-success my-2 fs-4">Current Codes </div>
					)}
					<Hierarchy data={hierarchy} />
				</div>
			)} */}
    </div>
  );
};

export default ImportCustomers;
