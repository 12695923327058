import { createSlice } from "@reduxjs/toolkit";

const initialStateValue = {
	component: [],
};
//creating customers
export const projectSlice = createSlice({
	name: "projects",
	initialState: { value: initialStateValue },
	reducers: {
		updateProjectStore: (state, action) => {
			state.value = action.payload;
		},
		unloadedProjectStore: (state) => {
			state.value = initialStateValue;
		},
	},
});

export const { updateProjectStore, unloadedProjectStore } = projectSlice.actions;

export default projectSlice.reducer;
