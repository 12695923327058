import React, { useEffect, useState } from "react";
import Labor from "./labor";
import Bom from "./bom";
import CustomerTableView from "./customer.table";
import ProjectTableView from "./project.table";
import InvoiceHeader from "./header.invoice";
import Terms from "./terms.table";
import TotalSignature from "./total.signature";
import { GetBOMdata } from "../Get/get.bom.data";

const ViewInvoice = ({ props, selected, handleCloseModal, user }) => {
	const asset = "Invoices";
	let thismonth = "";
	//console.log("INVOICE VIEW SELECTED JSON ", JSON.parse(selected.json));
	console.log("INVOICE VIEW: selected", selected);
	//console.log("INVOICE: VIEW: fileJson", JSON.parse(selected.json));
;
	const [totalNoTax, setTotalNoTax] = useState(0);
	const [totalTax, setTotalTax] = useState(0);
	const [total, setTotal] = useState(0);
	const [totalL, setTotalL] = useState(0);
	const [totalB, setTotalB] = useState(0);
	//console.log("INVOICE: VIEW: fileJson", JSON.parse(selected));
	var fileJson = selected?.json ? JSON.parse(selected?.json) : "";
	const labor = fileJson.labor;
	const bom = selected?.json ? fileJson.bom : GetBOMdata(selected.id);
	const customer = fileJson.customer;
	const project = fileJson.project;
	const terms = fileJson.terms;
	//const user = props.user;
 
	const calculator = () => {
		let total = Number(totalL) + Number(totalB);
		setTotalNoTax(total);
		let tax =
			(Number(totalL) + Number(totalB)) * Number(project.tax / 100).toFixed(2); 
		setTotalTax(tax);
		return (
			total+tax
		);
	};

		useEffect(() => {
      setTotal(calculator());
      console.log("INVOICE VIEW : bom",bom);
		}, [totalB, totalL]);
	
	return (
    <div className="row w-100">
      {selected && user ? (
        <div className="col-sm-12 col-lg-12 position-relative">
          <InvoiceHeader selectedCustomer={customer} user={user} />
          {/* EXCLUDE CUSTOMER INFO */}
          {/* {customer && <CustomerTableView data={customer} />} */}
          <h1 className="center">{project?.projectname}</h1>
          {labor.length > 0 && (
            <Labor data={labor} total={totalL} setTotal={setTotalL} />
          )}
          {bom.length > 0 && (
            <Bom data={bom} total={totalB} setTotal={setTotalB} />
          )}
          {project && <ProjectTableView data={project} />}
          <TotalSignature
            total={total}
            tax={project.tax}
            totalNoTax={totalNoTax}
            totalTax={totalTax}
            customer={customer.firstname + " " + customer.lastname}
            owner={user.company}
          />
          {terms && (
            <Terms
              data={terms}
              user={user.company}
              customer={customer.firstname + " " + customer.lastname}
            />
          )}
        </div>
      ) : (
        <h1>no data passed</h1>
      )}
    </div>
  );
};

export default ViewInvoice;
