import React, { useState, useEffect } from "react";
import { Alert, Button, Spinner, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { login, logout } from "../features/user";
import { updateSettigns, removeSettings } from "../features/settings";
import { updateMenu, unloadedMenu } from "../features/menu";
import {
  updateCustomerStore,
  unloadedCustomerStore,
} from "../features/customers";

import { unloadedAdutStore } from "../features/adut";
import { unloadedInvoiceStore } from "../features/invoices";
import { unloadedProjectStore } from "../features/projects";
import { unloadedTermsStore } from "../features/terms";
import { toast } from "react-toastify";

import Config from "../config";
import axios from "axios";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Profile from "../components/profile/profile";

function Login() {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const user = useSelector((state) => state.user.value);
  const settings = useSelector((state) => state.settings.value);

  const [userPass, setUserPass] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [loginError, setLoginError] = useState("");
  const [loading, setLoading] = useState(false);
  //const [component, setComponentCat] = useState(false);

  //switching teme from settings on this template ;
  const addBodyClass = (className) => document.body.classList.add(className);
  const removeBodyClass = (className) =>
    document.body.classList.remove(className);

  useEffect(() => {
    // Set up
    if (settings?.component?.lenght > 0) {
      settings?.component[0]?.theme instanceof Array
        ? settings?.component[0]?.theme.map(addBodyClass)
        : addBodyClass(settings?.component[0]?.theme);

      // Clean up
      return () => {
        settings?.component[0]?.theme instanceof Array
          ? settings?.component[0]?.theme.map(removeBodyClass)
          : removeBodyClass(settings?.component[0]?.theme);
      };
    }
  }, [user?.id]);

  const reset = () => {
    setUserPass("");
    setUserEmail("");
    setLoginError("");
    //unload all store data
    dispatch(logout());
    dispatch(unloadedMenu());
    dispatch(unloadedCustomerStore());
    dispatch(unloadedAdutStore());
    dispatch(unloadedInvoiceStore());
    dispatch(unloadedProjectStore());
    dispatch(unloadedTermsStore());
    dispatch(removeSettings());
  };

  const gotoURL = (url) => {
    navigate(url);
  };

  const validateUser = async () => {
    let body = new FormData();
    let date = new Date();
    date = date.toString().replace(/\s/g, "");
    body.append("email", userEmail);
    body.append("pass", userPass);
    body.append("login", true);
    body.append("key", btoa(date));
    //console.log("LOGIN  STRUCTURED:", body);

    if (Config.userCheckApi) {
      //setLoading(true);
      let response = await axios(
        {
          method: "post",
          url: Config.userCheckApi,
          data: body,
          config: { headers: { "Content-Type": "multipart/form-data" } },
        },
        [setLoading(true)]
      )
        .then((response) => {
          //setuserLogin(response);
          let validated = response?.data?.user?.email ? true : false;
          //let Vid = response?.data?.user?.id ? Number(response.data.user.id) : 0;
          //console.log("USER VALIDATED response", response);
          //console.log("USER VALIDATED validated", validated);

          if (validated) {
            //set response into Redux

            //console.log("USER OBJECT IN LOGIN : ", response?.data?.user);
            dispatch(
              login({
                loginStatus: validated,
                token: response.data.user.token,
                lastlogin: response.data.user.lastlogin,
                company: response.data.user.company,
                companylogo: response.data.user.companylogo,
                username: response.data.user.username,
                tokenkey: response.data.user.tokenkey,
                firstname: response.data.user.firstname,
                lastname: response.data.user.lastname,
                address: response.data.user.address,
                city: response.data.user.city,
                zip: response.data.user.zip,
                phone: response.data.user.phone,
                email: response.data.user.email,
                id: response.data.user.id,
                userhelpword: response.data.user.userhelpword,
              })
            );
            storeSettings(response.data.user.id);
            // toast.success("You are logged in  " + user?.username, {
            // 	position: toast.POSITION.BOTTOM_RIGHT,
            // 	autoClose: 1500,
            // });
            if (
              user.loginStatus &&
              location.pathname &&
              location.pathname !== "/login"
            ) {
              navigate(location.pathname);
            } else navigate("/home");
          } else {
            toast.warn(
              "Unfortunatelly, no match found. You are NOT logged in  " +
                userEmail,
              {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 4500,
              }
            );
            setLoginError(
              "Credentials you previously entered did not match any of our records."
            );
          }

          setLoading(false);
          console.log("LOGIN: User validation:", validated);
        })
        .catch((err) => {
          setLoading(false);
          setLoginError(
            "Hmmm, Login Services is having service issue:  " + err
          );
          console.log(err);
        });
    }
  };

  const storeSettings = async (id) => {
    //setLoading(true);
    console.log("ADDING SETTINGS from login");
    let body = new FormData();
    body.append("owner", id);
    body.append("action", "select");
    body.append("table", "settings");
    let res = await axios({
      method: "post",
      url: Config.userCheckApi,
      data: body,
      config: { headers: { "Content-Type": "multipart/form-data" } },
    })
      .then((res) => {
        dispatch(
          updateSettigns({
            component: res.data,
          })
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Form className="mt-3 mb-5  justify-content-end ribbon ribbon-end">
      {user.loginStatus ? (
        <div className="card card-flush">
          <div className="card-header mt-6">
            <div className="card-title flex-column">
              <h3 className="title p-0 mb-1">Profile and Settings </h3>
              <div className="fs-6 text-gray-400">
                See and update your personal information and organize tools
                settings{" "}
              </div>
            </div>
          </div>
          {user?.username ? (
            <div className="alert bg-light d-flex align-items-center  align-left p-5  m-3">
              <div className="ribbon-label bg-white">
                <span className="svg-icon svg-icon-2hx svg-icon-primary me-3">
                  <i className="fa-duotone fa-person-digging fs-1 text-danger"></i>
                </span>
              </div>

              <div className="d-flex flex-column">
                <h5 className="mb-1">
                  Welcome <strong>{user.username}</strong>
                </h5>
                <div>
                  <p>
                    You are logged in as <strong>{user.firstname}</strong> with
                    email address <strong>{user.email}</strong>. If this is not
                    your user name, please logout immidiatelly. It might happen
                    that machine you are using has multiple users and by
                    accident previous user did <strong>NOT</strong> log out. Do{" "}
                    <strong>NOT</strong> share same computer without logging out
                    clicking on button below.
                  </p>
                  <Link
                    className=" mt-5 btn-danger btn"
                    to="/home"
                    onClick={() => {
                      toast.success("You are logged Out  ", {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        autoClose: 1500,
                      });
                      reset();
                    }}
                  >
                    <span className="me-2">Logout</span>
                    <i className="fa-solid fa-right-from-bracket"></i>
                  </Link>
                </div>
              </div>
            </div>
          ) : null}

          {user.loginStatus ? (
            <div className="settings ">
              <h3 className="title m-0 pb-1">Settings</h3>
              <Profile />
            </div>
          ) : null}
        </div>
      ) : (
        <div className="blocker">
          <div className="blocker-inner">
            <p>Let's login to start our work</p>
            <div className="form-group">
              <input
                className="form-control mb-3"
                name="userPass"
                type="password"
                placeholder="Password"
                onChange={(event) => {
                  setUserPass(event.target.value);
                }}
              />
            </div>

            <div className="form-group mb-3">
              <input
                placeholder="Email"
                className="form-control"
                name="userEmail"
                type="text"
                onChange={(event) => {
                  setUserEmail(event.target.value);
                }}
              />
            </div>

            <Link
              className="login btn-primary btn d-flex align-items-center"
              to="/profile"
              onClick={() => {
                validateUser();
              }}
            >
              <span className="me-2">Login </span>{" "}
              {loading ? (
                <Spinner animation="border" variant="white" />
              ) : (
                <i className="fa-thin fa-right-to-bracket"></i>
              )}
            </Link>

            {loginError ? (
              <>
                <Alert variant="danger" className="mt-3">
                  {loginError}
                </Alert>
              </>
            ) : null}
          </div>
        </div>
      )}
    </Form>
  );
}

export default Login;
