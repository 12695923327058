import React, { useState, useRef } from "react";
import { Typeahead } from "react-bootstrap-typeahead";
import { Form } from "react-bootstrap";

export default function TypeAheadCustomers({ props, options, selected, setSelCustomer }) {
	const [selectedCustomer, setSelectedCustomer] = useState([]);
	const [searchFilter, setSearchFilter] = useState("firstname");
	const [customer, setStateCustomer] = useState(false);
	//const options = options;
	const typeaheadRef = useRef(null);

	function changeFilter(newFilter) {
		setSearchFilter(newFilter);
		setSelectedCustomer([]);
		document.getElementById("typeahead");
	}

	function changeCustomer(customer) {
		console.log(" selected", customer);
		setSelectedCustomer(customer);
		setSelCustomer(...customer);
		console.log("SELECTED CUSTOMER FROM PARENT", selected);
	}

	// function setCustomer(customer) {
	// 	console.log(" adding to state ", selectedCustomer);
	// 	setSelectedCustomer(customer);
	// 	setSelCustomer(customer);
	// 	console.log("SELECTED CUSTOMER FROM PARENT", selected);
	// }

	return (
		<div className="p-4 rounded bg-light my-3">
			<Form.Group controlId="formBasicSelect">
				<Form.Label>Start by selecting Customer</Form.Label>
				<Form.Control
					className="mb-2"
					as="select"
					defaultValue={searchFilter.toString()}
					onChange={(e) => {
						changeFilter(e.target.value);
					}}
				>
					<option value="firstname">
						Change Filter (search by name is default)
					</option>
					<option value="lastname">by Last Name</option>
					<option value="firstname">by First Name</option>
					<option value="phone">By Phone Number</option>
					<option value="email">By Email </option>
				</Form.Control>
			</Form.Group>

			<Form.Group>
				<Typeahead
					className="mb-2"
					id="typeahead"
					labelKey={searchFilter}
					onChange={changeCustomer.bind(this)}
					minLength={2}
					options={options}
					ref={typeaheadRef}
					placeholder="Type customer info..."
					renderMenuItemChildren={(option) => (
						<div key={option.id} className="flex horizontal block-link ">
							<span
								className={`badge  text-dark ${
									searchFilter === "firstname" ? "badge-success text-white" : "badge-light"
								}`}
							>
								{option.firstname}
							</span>
							<span
								className={`badge  text-dark ${
									searchFilter === "lastname" ? "badge-success text-light" : "badge-light"
								}`}
							>
								{option.lastname}
							</span>
							<span
								className={`badge  text-dark ${
									searchFilter === "phone" ? "badge-success text-light" : "badge-light"
								}`}
							>
								{option.phone}
							</span>
							<span
								className={`badge  text-dark ${
									searchFilter === "email" ? "badge-success text-light" : "badge-light"
								}`}
							>
								{option.email}
							</span>
						</div>
					)}
				/>
			</Form.Group>

			{selectedCustomer.length > 0 ? (
				<>
					<div className="block bg-light-warning mb-2">
						<span>Current Customer has been selected: </span>
						<span>
							{selectedCustomer[0]?.firstname +
								", " +
								selectedCustomer[0]?.lastname}
						</span>
					</div>
					<div className="block bg-light-primary">
						<span>Address: </span>
						<span>
							{selectedCustomer[0]?.address1 +
								" , " +
								selectedCustomer[0]?.city1 +
								" , " +
								selectedCustomer[0]?.state1}
						</span>
					</div>
				</>
			) : null}
		</div>
	);
};
