import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import "bootstrap/dist/css/bootstrap.css";
import App from "./App";
import { configureStore } from "@reduxjs/toolkit";
import { Provider } from "react-redux";
import { ContextProvider } from "./components/Common/services/context/store";
import userReducer from "./features/user";
import settingsReducer from "./features/settings";
import customerReducer from "./features/customers";
import projectReducer from "./features/projects";
import menuReducer from "./features/menu";
import termsReducer from "./features/terms";
import adutReducer from "./features/adut";
import invoices from "./features/invoices";
import { ToastContainer } from "react-toastify";

//adding redux store
const store = configureStore({
  reducer: {
    user: userReducer,
    settings: settingsReducer,
    customers: customerReducer,
    projects: projectReducer,
    menu: menuReducer,
    terms: termsReducer,
    adut: adutReducer,
    invoices: invoices,
  },
});

//const root = ReactDOM.createRoot(document.getElementById("root"));
ReactDOM.render(
  <Provider store={store}>
    <ContextProvider>
      <App />
    </ContextProvider>

    <ToastContainer />
  </Provider>,
  document.getElementById("app")
);
