import React, { useState, useEffect } from "react";      
import { toast } from "react-toastify";
import Config from "../../../config";
import { useSelector } from "react-redux"; 
import axios from "axios";  
const ref = React.createRef();

const GetBOMDataWithDisplay = (props) => {
  const [labor, setLabor] = useState([]);
  const [bom, setBOM] = useState([]);
  const type = props.type;
  const projectID = props.selected.id;
  //const user = props.user;
  const user = useSelector((state) => state.user.value); 

  const [photoGalery, setPhotosGalery] = useState([]);
  const [loginError, setLoginError] = useState("");
  const [loading, setLoading] = useState(false);

  let today = new Date().toISOString().slice(0, 11).replace("T", " ");
  // console.log("SELECTED CUSTOMER", selectedCustomer);

  useEffect(() => {
    //setTotalPrice([]);
    //getLabor(props.selected.id);
    getBOM(props.selected.id);
    //getAllPhotos(props.selected.id);
    // console.log("GET BOM:bom", bom);
    //console.log("GET BOM:labor", labor);
  }, [props.selected,props.refresh]);

  const getBOM = () => {
    //load BOM
    if (props.selected.id) {
      //console.log("GET BOM :id", props.selected.id);
      var bodyFormDataProjects = new FormData();
      bodyFormDataProjects.append("action", "select");
      bodyFormDataProjects.append("owner", Number(props.selected.owner));
      bodyFormDataProjects.append("token", user.token.trim());
      bodyFormDataProjects.append("data", Number(props.selected.id));
      bodyFormDataProjects.append("table", "bom");
      axios({
        method: "post",
        url: Config.saveAPI,
        data: bodyFormDataProjects,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then((res) => {
          let apiResponse = res;
          let validated = res.data ? true : false;
          if (validated) {
            setBOM(res.data);
            // toast.success("Retreiving BOM data complete  ", {
            //   position: toast.POSITION.BOTTOM_RIGHT,
            //   autoClose: 1500,
            // });
          }
          setLoginError(
            validated
              ? null
              : "Server message: Something went wrong with loading BOM  Data"
          );
          //remove spinner
          setLoading(false);
          //console.log("RECIEVED BOM DATA ", res.data);
        })
        .catch((err) => {
          toast.warn(
            "Hmmm, geting labor for this project is having troubles:  " + err,
            {
              position: toast.POSITION.BOTTOM_RIGHT,
              autoClose: 4500,
            }
          );
          console.log(err);
        });
    }
  };

  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  }); // {formatter.format(item.price)}

  return (
    <div className="align-left mb-4">
      <h3 className="m-0 p-0 pb-4">Bill of Material</h3>
      {bom.length > 0 ? (
        <div className="card card stretch p-2">
            <table className="table table-row-dashed table-row-gray-300 gy-2 mb-0">
              <thead>
                <tr className="fw-bolder fs-6 text-gray-800 ">
                  <td className=" fw-bold text-muted w-320px">Description</td>
                  <td className="text-dark ">
                    <p className="center mb-0">Price</p>
                  </td>
                  <td className="text-dark  mb-0">
                    <p className="center  mb-0">Quantity</p>
                  </td>
                  {type === "edit" ? (
                    <td>
                      <p className="center  mb-0">Remove</p>
                    </td>
                  ) : null}
                </tr>
              </thead>
              <tbody>
                {bom.length > 0
                  ? bom.map((item, index) => {
                      return (
                        <tr
                          key={index}
                          className="fw-bolder fs-6 text-gray-800 "
                        >
                          <td
                            className=" fw-bold text-muted w-320px mb-0"
                            dangerouslySetInnerHTML={{
                              __html: item.description ? item.description : "",
                            }}
                          ></td>
                          <td className="text-dark center">
                            <p className="mb-0">
                              {formatter.format(item.price)}
                            </p>{" "}
                          </td>
                          <td>
                            <p className="text-dark center mb-0">
                              {item.quantity}
                            </p>
                          </td>
                          {/* <td>
                            <p className="text-danger center mb-0">
                              {formatter.format(
                                Number(item.quantity) * Number(item.price)
                              )}{" "}
                               
                            </p>
                          </td> */}
                          {type === "edit" ? (
                            <td>
                              <p className="text-danger center mb-0">
                                <button
                                  className="btn btn-white p-0 rounded-circle"
                                  onClick={() => props.deleteItem(item.id)}
                                >
                                  <i className="fa-solid text-danger fa-trash fs-5"></i>
                                </button>
                              </p>
                            </td>
                          ) : null}
                        </tr>
                      );
                    })
                  : null}
              </tbody>
            </table>
         
        </div>
      ) : (
        <p className="m-2">
          There are not entered data for this project. To enter new BOM (Bill of
          Material) use form above.
        </p>
      )}
    </div>
  );
};
 export default GetBOMDataWithDisplay;