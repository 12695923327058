import React, { useRef } from "react";

import axios from "axios";
import config from "../../../config";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { Button, Form } from "react-bootstrap";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
//https://www.npmjs.com/package/react-sliding-pane#example
import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";
//import ProjectsTable from "../TableSearchView/TableSearchView";

const CreateComponent = ({ props, selected, selectedProject }) => {
	const user = useSelector((state) => state.user.value);
	const projects = useSelector((state) => state.projects.value);
	const [componentList, setComponentList] = useState([]);
	const [message, setMessage] = useState("");
	const [isPaneOpen, setisPaneOpen] = useState("");
	const [isPaneOpenLeft, setisPaneOpenLeft] = useState("");
	const [selectedAsset, setSelectedAsset] = useState("");
	const [requiredPass, setRequiredPass] = useState(false);
	const [componentName, setComponentName] = useState("");
	const [componentJSONFormat, setComponentJSONFormat] = useState({});
	const [formFieldsBasedOnJSON, setFormFieldBasedOnJSON] = useState(<></>);
	const [stateForForm, setStateForForm] = useState({});
	const [showButton, setShowButton] = useState(false);
	const navigate = useNavigate();
	const selectFormRef = useRef();
	const gotoURL = (url) => {
		navigate(url);
	};

	 const settings = {
			fields: [
				{ name: "id", headerName: "ID", type: "link" },
				{
					name: "projectname",
					headerName: "Project Name",
					type: "title",
					width: "min-w-250px",
				},
				{ name: "status", headerName: "Status", type: "string" },
				{ name: "paymentterms", headerName: "Payment Terms", type: "string" },
				{ name: "customername", headerName: "Customer", type: "string" },
				{ name: "tax", headerName: "Tax", type: "persentage" },
				{ name: "type", headerName: "Type", type: "string" },
				{ name: "datecreated", headerName: "Date Created", type: "date" },
				{ name: "startingdate", headerName: "Date Starting", type: "date" },
			],
			filter: {
				datePickerField: "startDate",
				dropdownField: "status",
				dropdownValues: [
					{ name: "All", value: "" },
					{ name: "Estimate", value: "estimate" },
					{ name: "Proposal", value: "proposal" },
					{ name: "Invalid", value: "invalid" },
					{ name: "Closed", value: "closed" },
					{ name: "Re-work", value: "rework" },
					{ name: "Invoice", value: "invoice" },
				],
			},
	 };
	
	useEffect(() => {
		//Gets all json files from contracts/components to display in ComponentList
		axios
			.get(config.preparedcontracts)
			.then((res) => {
				setComponentList(res.data.map((elem) => elem.split(".")[0]));
			})
			.catch((e) => {}); 
	}, []);

	useEffect(() => {
		console.log("selectedAsset",selectedAsset);
		if (selected?.id) {
			restart(); 
			
		} 
	}, [selected?.id]);

	useEffect(() => {
		if (!user.loginStatus) {
			gotoURL("/login"); 
		} else { 
			renderForm();
		}
	}, [stateForForm]);

	const createCompleteObjectState = (value) => {
		const state = {};
		Object.entries(value).forEach(([key, val]) => {
			if (val.type == "array") {
				state[key] = val.childObject
					? [{ ...createCompleteObjectState(val.childObject) }]
					: [];
			} else if (val.type == "object") {
				state[key] = { ...createCompleteObjectState(val.childObject) };
			} else {
				state[key] = "";
			}
		});
		return state;
	};
	const createObjectStateForForm = (value) => {
		const state = {};
		Object.entries(value).forEach(([key, val]) => {
			if (val.type == "array") {
				if (val.required == "true")
					state[key] = val.childObject
						? [{ ...createObjectStateForForm(val.childObject) }]
						: [];
				else state[key] = [];
			} else if (val.type == "object") {
				state[key] = { ...createObjectStateForForm(val.childObject) };
			} else {
				state[key] = "";
			}
		});
		return state;
	};

	const createStateforForm = (res) => {
		const state = { ...createObjectStateForForm(res) };
		setStateForForm(state);
	};

	//recursive function that returns final state after pushing new object at a location
	const pushObjInState = (keys, state, obj) => {
		const key = keys.shift();
		if (keys.length == 0) {
			state[key].push(obj);
		} else state[key] = pushObjInState(keys, state[key], obj);
		return state;
	};

	//This function is called on + button click
	const addItemToArray = (e, keyHierarchy) => {
		const obj = JSON.parse(e.currentTarget.dataset.object);
		setStateForForm((state) => {
			return { ...pushObjInState(keyHierarchy.split(","), state, obj) };
		});
	};

	const removeObjFromState = (keys, state, idx) => {
		const key = keys.shift();
		if (keys.length == 0 && state[key].length > idx && idx > -1) {
			state[key].splice(idx, 1);
		} else state[key] = removeObjFromState(keys, state[key], idx);
		return state;
	};
	const removeItemFromArray = (e, keyHierarchy, idx) => {
		setStateForForm((state) => {
			return { ...removeObjFromState(keyHierarchy.split(","), state, idx) };
		});
	};

	//finds the object from json provides using the keyHierarchy
	const findObjectFromJSON = (keyHierarchy, json) => {
		var data = { ...json };
		keyHierarchy.split(",").forEach((key) => {
			data = data[key];
		});
		return data[0];
	};

	//Finds the object to be pushed by creating an object from componentJSON
	const findObject = (keyHierarchy) => {
		var data = { ...componentJSONFormat };
		const key = keyHierarchy.split(",")[0];
		const json = createCompleteObjectState(data[key]?.childObject);
		var newHierarchy = keyHierarchy.substring(
			keyHierarchy.indexOf(",") + 1
				? keyHierarchy.indexOf(",") + 1
				: keyHierarchy.length
		);
		if (newHierarchy.startsWith("0,")) newHierarchy = newHierarchy.substring(2);
		if (newHierarchy == "") return json;
		return findObjectFromJSON(newHierarchy, json);
	};
	const onEditorValueChange = (event, editor, keyHierarchy) => {
		const data = editor.getData();
		setStateForForm((state) => {
			return { ...updateState(keyHierarchy.split(","), state, data) };
		});
	};
	//renders an HTML Form Field
	const renderField = (key, state, keyHierarchy, componentObject) => {
		if (componentObject?.type === "select")
			return (
				<Form.Group key={keyHierarchy} className="my-2 col-md-4 col-sm-12">
					<Form.Label>{componentObject?.description}</Form.Label>
					<Form.Select
						name={key}
						defaultValue={state}
						required={componentObject?.required == "true"}
						onChange={(e) => onChangeHandler(e, keyHierarchy)}
						
					>
						{componentObject?.options.map((opt, i) => (
							<option key={i} value={opt.value}>
								{opt.name}
							</option>
						))}
					</Form.Select>
				</Form.Group>
			);
		else if (componentObject?.type === "textarea") {
			return (
				<Form.Group key={keyHierarchy} className="my-2 col-md-12 col-sm-12">
					<Form.Label>{componentObject?.description}</Form.Label>
					<CKEditor
						editor={ClassicEditor}
						data={state}
						required={componentObject?.required == "true"}
						onChange={(event, editor) =>
							onEditorValueChange(event, editor, keyHierarchy)
						}
					/>
				</Form.Group>
			);
		}

		return (
			<Form.Group key={keyHierarchy} className="my-2 col-md-4 col-sm-12">
				<Form.Label>{componentObject?.description}</Form.Label>
				<Form.Control
					type={componentObject?.type}
					defaultValue={state}
					name={key}
					required={componentObject?.required == "true"}
					onChange={(e) => onChangeHandler(e, keyHierarchy)} 
				/>
			</Form.Group>
		);
	};
	const renderObject = (state, keyHierarchy, componentObject) => {
		var form = [];
		// if (componentObject?.description) {
		//   form.push(<div>{componentObject?.description}</div>);
		// }
		Object.entries(state).forEach(([key, value]) => {
			if (typeof value == "string") {
				form.push(
					renderField(
						key,
						state[key],
						keyHierarchy + "," + key,
						componentObject?.childObject[key]
					)
				);
			} else if (Array.isArray(value)) {
				form.push(
					renderArray(
						key,
						state[key],
						keyHierarchy + "," + key,
						componentObject?.childObject[key]
					)
				);
			} else if (typeof value == "object") {
				form = [
					...form,
					...renderObject(
						state[key],
						keyHierarchy + "," + key,
						componentObject?.childObject[key]
					),
				];
			}
		});
		return form;
	};

	const renderArray = (key, state, keyHierarchy, componentObject) => {
		var form = [];
		const obj = findObject(keyHierarchy.replace(/,[0-9]*,/g, ",0,"));
		if (componentObject?.childObject)
			return (
				<div key={keyHierarchy} className="">
					<div className="title">
						<label>{componentObject?.description} (Array)</label>
						<a
							className="button"
							title="Add Array Item"
							data-object={JSON.stringify(obj)}
							onClick={(e) => addItemToArray(e, keyHierarchy)}
						>
							<i className="fa-solid fa-square-plus"></i>
						</a>
					</div>
					{state.length > 0 &&
						state.map((st, idx) => {
							return (
								<div key={idx} className="array-wrapper container">
									<div className="title">
										{componentObject?.description} #{idx + 1}
										<a
											className="button"
											title="Remove Array Item"
											onClick={(e) => removeItemFromArray(e, keyHierarchy, idx)}
										>
											<i className="fa-solid fa-square-minus"></i>
										</a>
									</div>
									<div className="row">
										{renderObject(
											st,
											keyHierarchy + "," + idx,
											componentObject
										)}
									</div>
								</div>
							);
						})}
				</div>
			);
	};

	const renderForm = () => {
		var form = [];
		Object.entries(stateForForm).forEach(([key, value]) => {
			if (typeof value == "string") {
				form.push({ ...renderField(key, stateForForm[key], key, "text") });
			} else if (typeof value == "number") {
				form.push({ ...renderField(key, stateForForm[key], key, "number") });
			} else if (Array.isArray(value)) {
				form.push(
					renderArray(key, stateForForm[key], key, componentJSONFormat[key])
				);
			} else if (typeof value == "object") {
				form = [
					...form,
					...renderObject(stateForForm[key], key, componentJSONFormat[key]),
				];
			}
		});
		setFormFieldBasedOnJSON(form);
	};
	const required = (assetName) => {
		//checking if  dependent selection is done
		if (
			assetName === "Projects" ||
			assetName === "Terms" ||
			assetName === "Customers"
		) {
			if (assetName === "Projects" && !selected?.id) {
				
				setShowButton(false);
				setRequiredPass(false);
				setFormFieldBasedOnJSON(
					<>
						<p>
							To add new Project you need to select customer first. Please select customer to add new Project..
						</p>
					</>
				);

				return false;
			} else { 
				setRequiredPass(true);
				
				setComponentName(assetName);
				setShowButton(true);
				return true;
			}
			
		} else {
			if (
				(assetName === "BOM" || assetName === "Labor") &&
				(selected.id && selectedProject.id)
			) {
				
				setComponentName(assetName);
				setRequiredPass(true);
				setShowButton(true);
				return true;
			} else {
				setSelectedAsset("");
				setShowButton(false);
				setRequiredPass(false);
				setFormFieldBasedOnJSON(
					<>
						<p>
							To add Bill of Material (BOM) or Labor (Labor) you need to select
							customer and project first...
						</p>
					</>
				);

				return false;
			}
		}
	};
	//OnChange handler function retreives contract for the component selected
	const componentSelected = (e) => {
		console.log("COMPONENT NAME:", e.target.value);
		if (e.target.value == "") {
			setFormFieldBasedOnJSON(<></>);
			return;
		}
		setSelectedAsset(e.target.value);
		//if required conditional met load otherwise update message
		required(e.target.value) && setRequiredPass
			? axios
					.get(config.preparedcontracts + e.target.value + ".component.json")
					.then((res) => {
						setComponentJSONFormat({ ...res.data });
						createStateforForm(res.data);
						setMessage("");
					})
					.catch((e) => {})
			: e.target.value === "Projects"
			? setMessage(
					"To add new <strong>" +
						e.target.value +
						"</strong>, you will have to select CUSTOMER to continue. "
			  )
			: setMessage(
					"To add new <strong>" +
						e.target.value +
						"</strong>, you will have to select CUSTOMER and PROJECT in order to continue. "
				);
		
	};
	const onChangeHandler = (event, keyHierarchy) => {
		const name = event.target.name;
		const value = event.target.value;
		const keys = keyHierarchy.split(",");
		setStateForForm((state) => {
			return { ...updateState(keys, state, value) };
		});
	};

	const updateState = (keys, state, value) => {
		const key = keys.shift();
		if (!key) {
			return value;
		}
		state[key] = updateState(keys, state[key], value);
		return state;
	};

	const flatten = (data) => {
		var result = {};
		function recurse(cur, prop) {
			if (Object(cur) !== cur) {
				result[prop] = cur;
			} else if (Array.isArray(cur)) {
				for (var i = 0, l = cur.length; i < l; i++)
					recurse(cur[i], prop + "[" + i + "]");
				if (l == 0) result[prop] = [];
			} else {
				var isEmpty = true;
				for (var p in cur) {
					isEmpty = false;
					recurse(cur[p], prop ? prop + "." + p : p);
				}
				if (isEmpty && prop) result[prop] = {};
			}
		}
		recurse(data, "");
		return result;
	};
	const onFormSubmit = (e) => {
		e.preventDefault();
		const formdata = Object.fromEntries(new FormData(e.target)?.entries());
		const componentProps = { ...stateForForm };
		const self = this;
		let owner = Number(user.id);
		let customer = selected.id ? Number(selected.id) : 0;
		let projectid = Number(selectedProject.id);
		componentProps[Object.keys(componentProps)[0]].owner = owner;
		if (componentName.toLowerCase() != "customers") {
			componentProps[Object.keys(componentProps)[0]].customer = customer;
		}
		if (componentName.toLowerCase() === "projects") {
			componentProps[Object.keys(componentProps)[0]].customername =
				selected.firstname + " " + selected.lastname;
		} else {
			if (componentName.toLowerCase() === "terms") {
				componentProps[Object.keys(componentProps)[0]].datecreated = new Date()
					.toISOString()
					.slice(0, 19)
					.replace("T", " ");
			} else {
				//if is not project or terms or customers table add projectid
				if (componentName.toLowerCase() != "customers") {
					componentProps[Object.keys(componentProps)[0]].projectid = projectid;
				}
			}
		}

		const finalObj = {
			data: componentProps[Object.keys(componentProps)[0]],
		};

		console.log("Creator saving data:", finalObj);
		const postData = new FormData();
		for (let key in finalObj) {
			postData.append(key,  JSON.stringify(finalObj[key]));
		}
		postData.append("owner", owner);
		postData.append("action", "insert");
		postData.append("table", componentName.toLowerCase());

		axios({
			method: "post",
			url: config.saveAPI,
			data: postData,
			config: { headers: { "Content-Type": "multipart/form-data" } },
		})
			.then(function (response) {
				toast.success("Form has been submitted " + response.data, {
					position: toast.POSITION.BOTTOM_RIGHT,
					autoClose: 5500,
				});
				//document.getElementById("close").click();
				console.log("submitted form  ", response.data);
				console.log(response);
				restart();
				//gotoURL("/creator");
			})
			.catch(function (response) {
				//handle error
				console.log("ERROR FORM SERVER", response);
				toast.warn(" Submission failed.", {
					position: toast.POSITION.BOTTOM_RIGHT,
					autoClose: 1500,
				});
			});
	};

	const restart = () => {

		setMessage("");
		setSelectedAsset("");
		setComponentName("");
		setComponentJSONFormat({});
		createStateforForm({}); 
		setFormFieldBasedOnJSON(
			<>
				<p>Select Component from menu above...</p>
			</>
		);
		setShowButton(false);
		return;
	};
	let thismonth = "";
	return (
		<div className="row">
			<SlidingPane
				className="some-custom-class"
				overlayClassName="some-custom-overlay-class"
				isOpen={isPaneOpen}
				title="Upon selection selected customer and project will appear in here"
				subtitle="If you dont see anything it means you did not select anything.At any point of selection you can verify selected customer and projects."
				onRequestClose={() => {
					// triggered on "<" on left top click or on outside click
					setisPaneOpen(false);
				}}
			>
				{/* Display project and selected customer  */}
				{selectedProject && selected ? (
					<main>
						<div className="container">
							{selected ? (
								<div className="row g-6 g-xl-9">
									<div className="col-lg-6">
										<h3>
											{selected.firstname} {selected.lastname}
										</h3>
										{Object.entries(selected).map(function ([key, val]) {
											if (key != "id" && key != "owner") {
												if (val != "") {
													return (
														<p className="block" key={key}>
															<span>{key} </span> <em> {val} </em>
														</p>
													);
												} else {
													return;
												}
											}
										})}
									</div>
									<div className="col-lg-6">
										<h3>
											{selectedProject ? selectedProject.projectname : null}{" "}
										</h3>

										<div className="accordion accordion-icon-toggle " id="acc">
											{selectedProject ? (
												<>
													{Object.entries(selectedProject).map(function ([
														key,
														val,
													]) {
														if (
															key != "owner" &&
															key != "customer" &&
															key != "id" &&
															key != "laborID" &&
															key != "bomID" &&
															key != "photoID"
														) {
															if (key == "notes") {
																return (
																	<div className="block d-flex p-3" key={key}>
																		<div className="text-danger fw-bold text-uppercase ">
																			{key}{" "}
																		</div>
																		<div
																			className="text-dark fw-semibold ps-2 "
																			dangerouslySetInnerHTML={{
																				__html: val,
																			}}
																		/>
																	</div>
																);
															} else if (val != "") {
																return (
																	<div className="block d-flex   p-3" key={key}>
																		<div className="text-muted ">{key} </div>
																		<div
																			className="text-dark fw-semibold ps-2 "
																			dangerouslySetInnerHTML={{
																				__html: val,
																			}}
																		/>
																	</div>
																);
															} else {
																return;
															}
														}
													})}
												</>
											) : (
												<div className="alert alert-warning d-flex align-items-center p-5 mb-10">
													<span className="svg-icon svg-icon-2hx svg-icon-primary me-3">
														<i className="fa-duotone fa-face-holding-back-tears fs-1"></i>
													</span>

													<div className="d-flex flex-column">
														<h5 className="mb-1">Not an issue but no data</h5>
														<span>
															It looks like{" "}
															<strong>
																{selected.firstname} {selected.lastname}{" "}
															</strong>{" "}
															does not have any project created yet. To create a
															new project go to Project Creator from main manu
															and create new project.
														</span>
													</div>
												</div>
											)}
										</div>
										{selectedProject.length > 0 ? (
											<div className="alert alert-warning d-flex align-items-center p-5 mt-5">
												<span className="svg-icon svg-icon-2hx svg-icon-primary me-3">
													<i className="fa-duotone fa-calendar fs-1"></i>
												</span>

												<div className="d-flex flex-column">
													<h5 className="mb-1">
														{selectedProject.length} {" projects found"}
													</h5>
													<span>
														<strong>
															{selected.firstname} {selected.lastname}{" "}
														</strong>
														{" has "} {selectedProject.length}{" "}
														{"available projects"}. Click on name of the project
														to see more details. Any project located in this
														month will have red badge as mark of urgency and
														attention.
													</span>
												</div>
											</div>
										) : null}
									</div>
								</div>
							) : (
								<p>Nothing selected </p>
							)}
						</div>
					</main>
				) : null}
			</SlidingPane>

			<div className="col-sm-12 col-lg-12 position-relative">
				<div className="alert alert-secondary align-items-center p-5 mb-10">
					<div className="d-flex align-items-center">
						<span className="svg-icon svg-icon-2hx svg-icon-primary me-3">
							<i className="fa-duotone fa-person-digging fs-1"></i>
						</span>
						<h5 className="mb-1">
							<span>Select create new from list below <strong>{user.username}</strong></span>
							
						</h5>
						
									
					</div>

					<div className="d-flex flex-column">
						<Form onSubmit={onFormSubmit} onReset={restart}>
							<div
								className={
									user?.id && selected?.id && requiredPass
										? "mt-4 mb-4 d-flex "
										: "mt-4 mb-4 d-flex border-1 border-dashed card-rounded border-danger justify-content-center align-items-center"
								}
							>
								<Form.Group className="p-2">
									 
									<Form.Select
										className="text-capitalize my-4"
										ref={selectFormRef}
										value={selectedAsset}
										multiple={false}
										name="componentName"
										onChange={(e) => componentSelected(e)}
									>
										<option value={0}>Select Option</option>
										{componentList?.map((opt, i) => (
											<option key={i}>{opt}</option>
										))}
									</Form.Select>
									<a className="btn btn-primary my-4 mx-2 mt-0" onClick={() => restart()}>
								Reset Selected option {" "}
								<i className="fa-solid fa-rotate-exclamation fs-1 text-light"></i>
							</a>
									
							<a
								className="btn btn-primary my-4 mx-2 mt-0"
								onClick={() => setisPaneOpen(true)}
							>
								<span className="me-2">View customer and project info</span>
								<i className="fa-duotone fa-binoculars fs-1 text-light"></i>
							</a>
								 
									<Form.Control
										type="text"
										name="projectWid"
										defaultValue={
											selectedProject?.id
												? JSON.stringify(Number(selectedProject.id))
												: ""
										}
										placeholder="Select Customer first from above search field"
										className={
											selectedProject?.id
												? "bg-light-success"
												: "bg-light-danger"
										}
										hidden
									/>
								 
									<Form.Control
										type="text"
										name="customerID"
										defaultValue={user.id ? Number(user.id) : ""}
										placeholder="View Name"
										className={
											user?.id ? "bg-light-success" : "bg-light-danger"
										}
										hidden
									/>
							 
									<Form.Control
										type="text"
										name="customerID"
										defaultValue={
											selected?.id ? JSON.stringify(Number(selected.id)) : ""
										}
										placeholder="Select Customer first from above search field"
										className={
											selected?.id ? "bg-light-success" : "bg-light-danger"
										}
										hidden
									/> 
								</Form.Group>
							 

							
							</div>

							<div className="row">
								{message ? (
									<div
										className="alert alert-danger border-1 border-dashed card-rounded p-5 p-lg-10 mb-1 "
										dangerouslySetInnerHTML={{
											__html: message,
										}}
									/>
								) : null}
								<hr className="mt-4 mb-4" />
								{componentName && showButton ? <h1>{componentName}</h1> : null}
								{componentJSONFormat?.description ? (
									<p> {componentJSONFormat.description}</p>
								) : null}

								{formFieldsBasedOnJSON}

								{showButton ? (
									<Button
										variant="primary"
										type="submit"
										className="my-3 col-3 offset-4"
									>
										Submit
									</Button>
								) : null}
							</div>
						</Form>
					</div>
				</div>
			</div>
			{!showButton ? (
				<div className="">
					<div className="">
						<p className="border-1 border-dashed card-rounded p-5 p-lg-10 mb-1 ">
							<span>
								First thing with Creator is to know what you are doing, so
								select project and customer that belongs to you and we can
								continue with selection of creating new Project, SOW, Labor,
								Expenses, Material and Terms and Conditions{" "}
							</span>
						</p>
						<p className="border-1 border-dashed card-rounded p-5 p-lg-10 mb-1 ">
							<span>
								Once selection is made, you can proceed with building your
								project and generate invoice, Estimate and do whatever you wish
								to do with it. We hope you made money so make it count ;)
							</span>
						</p>
						<p className="border-1 border-dashed card-rounded p-5 p-lg-10 mb-1 ">
							<span>
								Please note that colors represent success or missing selection
								(look at Owner field its green which means you are already
								logged in). THe whole section below appears RED by default.
								Status will change color to dark green once you select your
								customer and project to start with.
							</span>
						</p>
					</div>
				</div>
			) : null}
		</div>
	);
};

export default CreateComponent;
