import React, { useEffect, useState } from "react";

const ViewTerms = ({ props, data, customer , user }) => {
	let thismonth = "";
	const terms = data;

	return (
		<div className="row">
			<div className="d-flex  center fs-4  text-muted w-100 p-5 pb-0 p-2 mb-8">
				<span className="text-dark ">
					By signing acknowledgement above, confirming agreement on price and
					project execution, both party ({customer } &  { user} ) agree on payment and work execution of
					project defined in this document.
				</span>
			</div>
			<div className="col-sm-12 col-lg-12 position-relative">
				<h3 className="mb-1">Terms and Conditions</h3>
				<div className="alert alert-warning d-flex align-items-center p-5 mb-10">
					<span className="svg-icon svg-icon-2hx svg-icon-primary me-3">
						...
					</span>

					<div className="d-flex flex-column">
						<div
							dangerouslySetInnerHTML={{
								__html: terms.terms ? terms.terms : "No terms selected",
							}}
						></div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ViewTerms;
