import React, { useEffect, useState } from "react";

const ViewLabor = ({ props, data, total, setTotal }) => {
  let thismonth = "";
  const bom = data;

  const calculator = () => {
    let total = 0;
    return (
      bom.forEach((item) => {
        total = total + Number(item.price);
      }),
      [total]
    );
  };
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",

    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  }); // {formatter.format(item.price)}
	
  useEffect(() => {
    setTotal(calculator());
  }, [props]);

  return (
    <div className="row">
      <div className="col-sm-12 col-lg-12 position-relative">
        <h6 className="m-2">Labor itemized</h6>
        <div className="card  d-flex  mb-10 ">
          <div className="w-100">
            <table className="table table-dashed table-rounded  gy-2 gs-2 w-100 mb-0">
              <thead>
                <tr className="fw-bolder fs-6 text-gray-800 ">
                  <td className=" fw-bold text-muted w-320px">Description</td>
                  <td className="text-dark ">
                    <p className="center  mb-0">Price</p>
                  </td>
                  <td className="text-dark ">
                    <p className="center  mb-0">Quantity</p>
                  </td>
                  <td className="text-dark ">
                    <p className="center  mb-0">$$$</p>
                  </td>
                </tr>
              </thead>
              <tbody>
                {bom.length > 0
                  ? bom.map((item, index) => {
                      calculator(Number(item.quantity) * Number(item.price));
                      return (
                        <tr
                          key={index}
                          className="fw-bolder fs-6 text-gray-800 "
                        >
                          <td
                            className=" fw-bold text-muted w-320px"
                            dangerouslySetInnerHTML={{
                              __html: item.description ? item.description : "",
                            }}
                          ></td>
                          <td className="text-dark center">
                            <p className="mb-0">
                              {formatter.format(item.price)}
                            </p>{" "}
                          </td>
                          <td>
                            <p className="text-dark center mb-0">1</p>
                          </td>
                          <td>
                            <p className="text-danger center mb-0">
                              {/* {Number(item.price)}{" "} */}
                              {formatter.format(item.price)}
                            </p>
                          </td>
                        </tr>
                      );
                    })
                  : null}
              </tbody>
            </table>

            <p className="text-muted border-bottom m-0"></p>
            <div className="d-flex justify-content-end fs-6 fw-bold text-muted w-100 p-2 bg-light-primary ">
              <span className="text-muted">Total labor price: </span>
              <span className="text-danger ps-6 me-10">
                {/* {" "}
                ${total} */}
                {formatter.format(total)}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewLabor;
 
