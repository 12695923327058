import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

function InvoiceSettings(props) {
  const user = useSelector((state) => state.user.value);
  const settings = useSelector((state) => state.settings.value);
  const SaveSettings = props.SaveSettings;
  const [dropdownValue, setDropdownValue] = useState([]);

  const [save, setSave] = useState("disabled");
  const [message, setMessage] = useState("disabled");

  const onChange = (val, name) => {};

  useEffect(() => {
    // Set up
    if (settings?.component?.lenght > 0) {
      setDropdownValue(
        settings?.component?.length > 0
          ? settings.component[0].invoicetemplate
          : ""
      );
      // console.log("SETINGS invoicetemplate:", dropdownValue);
    }
  }, [settings]);

  return user.loginStatus ? (
    <div className="col-md-4 col-sm-12 ">
      <div className="card card-custom card-stretch shadow   p-4">
        <h4 className="align-left  m-2 mb-4">Invoice View</h4>
        <div className="mb-10">
          <div className="align-left form-check form-check-custom form-check-solid d-flex align-items-center mb-2">
            <input
              className="form-check-input"
              type="checkbox"
              value="false"
              id="invoiceDisplayProjectInfo"
            />
            <label className="form-check-label" htmlFor="displayProjectInfo">
              Display Project Info
            </label>
          </div>

          <div className="align-left form-check form-check-custom form-check-solid d-flex align-items-center mb-2">
            <input
              className="form-check-input"
              type="checkbox"
              value="true"
              id="invoiceDisplayPhotos"
            />
            <label className="form-check-label" htmlFor="displayPhotos">
              Display Photos
            </label>
          </div>

          <div className="align-left form-check form-check-custom form-check-solid d-flex align-items-center mb-2">
            <input
              className="form-check-input"
              type="checkbox"
              value="true"
              id="invoiceDisplayTerms"
            />
            <label className="form-check-label" htmlFor="displayTerms">
              Display Terms and ligal rights
            </label>
          </div>

          <div className="align-left form-check form-check-custom form-check-solid d-flex align-items-center mb-2">
            <input
              className="form-check-input"
              type="checkbox"
              value="true"
              id="invoiceDisplayLogo"
            />
            <label className="form-check-label" htmlFor="displayLogo">
              Display Logo
            </label>
          </div>
        </div>
        <div className="m-4 d-flex flex-column">
          <p>
            Currently Sellected{" "}
            <span className="text-success">{dropdownValue}</span>
          </p>

          <button
            className="btn btn-primary my-4 mx-2 mt-0"
            disabled={save}
            onClick={(e) => {
              e.preventDefault();
              setMessage(SaveSettings("invoice", dropdownValue));
            }}
          >
            {save ? save : "Update"}
          </button>
          {message && (
            <div
              dangerouslySetInnerHTML={{
                __html: message ? message : "",
              }}
            />
          )}
        </div>
      </div>
    </div>
  ) : null;
}

export default InvoiceSettings;
