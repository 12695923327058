import { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { updateInvoiceStore } from "../../../features/invoices";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/material_blue.css";
import DataTable from "react-data-table-component";
import EditProject from "../Edit/edit.project";
import EditBom from "../Edit/edit.bom";
import EditLabor from "../Edit/edit.labor";
import GetGallery from "../Get/get.gallery";
import IvoiceCreator from "../View/invoice.modal";
//import { GetCustomer } from "../Get/get.customer";
import config from "../../../config";
import axios from "axios";
import Modal from "react-modal";
import { toast } from "react-toastify";

/**
 * react-data-table-component package to display data in table format with paginationa
 * flatpickr and react-flatpickr for date picker
 */
Modal.setAppElement("#app");
const TableProjectView = (props, selectProject) => {
  //const user = useSelector((state) => state.user.value);
  const dispatch = useDispatch();
  const adut = useSelector((state) => state.adut.value);
  const [filteredData, setFilteredData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const datepickrRef = useRef(null);
  const [startdate, setStartDate] = useState(new Date(0));
  const [enddate, setEndDate] = useState(new Date("04/20/2030"));
  const dateField = props?.settings?.fields?.find((f) => f.type === "date");
  const dropdownField = props?.settings?.filter?.dropdownField;
  const [activeModal, setActiveModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showInvoiceModal, setShowInvoiceModal] = useState(false);
  const [edit, setEdit] = useState(false);
  const [selectedC, setSelectedC] = useState([]);
  const [dropdownValue, setDropdownValue] = useState("");
  const [selectedInvoice, setSelectedInvoice] = useState([]);
  const user = props.user;

  const [customer, setCustomer] = useState([]);
  const [loginError, setLoginError] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setFilteredData(props?.data ? [...props?.data] : []);
  }, [props?.data]);
  //
  useEffect(() => {
    GetCustomer(selectedC.customer, user.id);
  }, [selectedC]);

  // const customerObject = GetCustomer(selectedC.customer);
  // console.log("TABLE PROJECT VIEW  CUSTOMER OBJECT :",customerObject);

  useEffect(() => {
    setFilteredData(props?.data ? [...props?.data] : []);
    if (searchTerm !== "") {
      setFilteredData([]);
      props?.settings.fields.forEach((e) => {
        switch (e.type) {
          case "string":
            setFilteredData((f) => [
              ...f,
              ...props?.data?.filter((d) =>
                d[e.name].toLowerCase().includes(searchTerm.toLowerCase())
              ),
            ]);
            break;
          case "status":
            setFilteredData((f) => [
              ...f,
              ...props?.data?.filter((d) =>
                d[e.name].toLowerCase().includes(searchTerm.toLowerCase())
              ),
            ]);
            break;
          case "title":
            setFilteredData((f) => [
              ...f,
              ...props?.data?.filter((d) =>
                d[e.name].toLowerCase().includes(searchTerm.toLowerCase())
              ),
            ]);
            break;
          case "number":
            setFilteredData((f) => [
              ...f,
              ...props?.data?.filter((d) =>
                d[e.name].toString().includes(searchTerm)
              ),
            ]);
            break;
          case "array":
            break;
          default:
            setFilteredData((f) => [
              ...f,
              ...props?.data?.filter((d) => d[e.name].includes(searchTerm)),
            ]);
        }
      });
    }

    if (dropdownValue !== "") {
      setFilteredData((f) =>
        f?.filter((d) => d[dropdownField] === dropdownValue)
      );
    }
    if (
      startdate?.getTime() != new Date(0).getTime() &&
      enddate?.getTime() != new Date(0).getTime()
    ) {
      setFilteredData((f) =>
        f?.filter(
          (d) =>
            new Date(d[dateField.name]).getTime() >= startdate.getTime() &&
            new Date(d[dateField.name]).getTime() <= enddate.getTime()
        )
      );
    }
    //find unique filteredData
    setFilteredData((f) =>
      f?.filter((value, index) => {
        const _value = JSON.stringify(value);
        return (
          index ===
          f.findIndex((obj) => {
            return JSON.stringify(obj) === _value;
          })
        );
      })
    );
  }, [searchTerm, dropdownValue, startdate?.getTime(), enddate?.getTime()]);

  const customStyles = {
    rows: {
      style: {
        minHeight: "72px", // override the row height
        minWidth: "300px",
      },
    },
    headCells: {
      style: {
        paddingLeft: "8px", // override the cell padding for head cells
        paddingRight: "8px",
      },
    },
    cells: {
      style: {
        paddingLeft: "8px", // override the cell padding for props?.data cells
        paddingRight: "8px",
      },
    },
  };

  const GetCustomer = (id, owner) => {
    console.log("GetCustomer():", id, owner);
    if (id) {
      var bodyFormDataProjects = new FormData();
      bodyFormDataProjects.append("action", "singlerecord");
      bodyFormDataProjects.append("owner", Number(owner));
      bodyFormDataProjects.append("data", Number(id));
      bodyFormDataProjects.append("table", "customers");
      axios({
        method: "post",
        url: config.saveAPI,
        data: bodyFormDataProjects,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then((res) => {
          let validated = res.data ? true : false;
          if (validated) {
            setCustomer(res.data);
            //console.log("GetCustomerData data function returned", res.data);
          }
        })
        .catch((err) => {
          console.log(err);
          return;
        });
    }
  };
  const col = props?.settings?.fields?.map((f) => {
    let sortable = true;
    switch (f.type) {
      case "array":
        sortable = false;
        break;
      default:
        sortable = true;
    }
    return {
      name: <div className="text-gray-800 fw-bold">{f.headerName}</div>,
      selector: (row) => row[f.name],
      cell: (row) => {
        switch (f.type) {
          case "html":
            return (
              <div
                className="text-gray-600 fw-bold"
                dangerouslySetInnerHTML={{
                  __html: row[f.name] ? row[f.name] : "",
                }}
              ></div>
            );
          case "price":
            return <div className="text-gray-600 fw-bold ">${row[f.name]}</div>;
          case "title":
            return (
              <div className={`text-gray-600  align-left`}>{row[f.name]}</div>
            );
          case "link":
            return (
              <>
                <a
                  className="text-blue-600 fw-bold link d-flex flex-column w-24 me-2 align-items-center"
                  onClick={(e) => {
                    setEdit(true);
                    handleOpenModal(row[f.name], row);
                  }}
                >
                  <i className="fa-duotone fa-pencil fs-5  text-danger"></i>{" "}
                  edit
                </a>
                <a
                  className="text-blue-600 fw-bold link d-flex flex-column w-24 align-items-center"
                  onClick={(e) => {
                    setEdit(false);
                    handleOpenInvoiceModal(row[f.name], row);
                  }}
                >
                  <i className="fa-duotone fa-print-magnifying-glass fs-5 text-danger"></i>{" "}
                  Invoice
                </a>
              </>
            );
          case "date":
            return <div className="text-gray-600  ">{row[f.name]}</div>;
          case "status":
            return row[f.name] === "approved" ? (
              <div className="text-gray-600  text-light bg-success p-2 min-w-100px">
                {row[f.name]}
              </div>
            ) : row[f.name] === "canceled" || row[f.name] === "inactive" ? (
              <div className="text-gray-600  text-light bg-danger p-2 min-w-100px">
                {row[f.name]}
              </div>
            ) : row[f.name] === "complete" ? (
              <div className="text-gray-600  text-light bg-blue p-2 min-w-100px">
                {row[f.name]}
              </div>
            ) : (
              <div className="text-gray-600  text-dark bg-warning p-2 min-w-100px">
                {row[f.name]}
              </div>
            );
          case "persentage":
            return <div className="text-gray-600  "> {row[f.name]} %</div>;
          default:
            return <div className="text-gray-600  ">{row[f.name]}</div>;
        }
      },
      sortable,
      sortFunction: (a, b) => {
        switch (f.type) {
          case "number":
            return a[f.name] - b[f.name];
          case "date":
            return new Date(a[f.name]) - new Date(b[f.name]);
          case "string":
            return a[f.name].localeCompare(b[f.name]);
          case "status":
            return a[f.name].localeCompare(b[f.name]);
          case "title":
            return a[f.name].localeCompare(b[f.name]);
        }
      },
    };
  });

  const handleOpenInvoiceModal = (val, item) => {
    console.log("PROJECT TABLE VIEW LOOKUP invoice modal val:", val);
    console.log("CUSTOMER TABLE VIEW LOOKUP invoice modal item:", item);
    if (item) {
      setSelectedC(item);
      setCustomer(item.customer);
    }
    setShowInvoiceModal(true);
  };

  const handleOpenModal = (val, item, type) => {
    //console.log("PROJECT TABLE VIEW LOOKUP val:", val);
    //console.log("CUSTOMER TABLE VIEW LOOKUP item:", item);
    setSelectedC(item);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setEdit(false);
    setShowInvoiceModal(false);
    setActiveModal("");
  };

  /**Modal functions tools  */
  const sendEmail = () => {
    // alert("config.sendEmailApi:" + config.sendEmailApi);
    //alert("SEND EMAIL() to : "+customer[0].email)
    var bodyFormDataProjects = new FormData();
    bodyFormDataProjects.append("to", customer[0].email);
    bodyFormDataProjects.append(
      "subject",
      user.firstname + " is sending invoice"
    );
    bodyFormDataProjects.append("from", user.email);
    bodyFormDataProjects.append("token", user.token);
    bodyFormDataProjects.append("project", selectedC.projectname);
    console.log("SEND EMAIL() : " + JSON.stringify(bodyFormDataProjects));
    axios({
      method: "post",
      url: config.sendEmailApi,
      data: bodyFormDataProjects,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((res) => {
        let validated = res ? true : false;
        if (validated) {
          console.log("SendEmail in TableProjectView  returned", res);
        }
      })
      .catch((err) => {
        console.log(err);
        return;
      });
  };

  const printElement = (e) => {
    e = document.getElementById("invoice");
    let cloned = e.cloneNode(true);
    document.body.appendChild(cloned);
    cloned.classList.add("printable");
    window.print();
    document.body.removeChild(cloned);
    //const myTimeout = setTimeout(document.body.removeChild(cloned), 1000);
  };
  const StoreInvoices = () => {
    //invoices update
    var bodyFormDataInvoice = new FormData();
    bodyFormDataInvoice.append("action", "select");
    bodyFormDataInvoice.append("token", user.token);
    bodyFormDataInvoice.append("owner", Number(user.id));
    bodyFormDataInvoice.append("table", "invoices");

    axios({
      method: "post",
      url: config.saveAPI,
      data: bodyFormDataInvoice,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((res) => {
        toast.success("IVNOICE's have been updated  ", {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 1500,
        });
        //set store
        dispatch(
          updateInvoiceStore({
            component: res.data,
          })
        );
      })
      .catch((err) => {
        toast.warn("Hmmm, projects store is having troubles:  " + err, {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 4500,
        });
        console.log(err);
      });
  };
  const SaveInvoice = () => {
    //

    setLoading(true);
    let datecreated = new Date().toISOString().slice(0, 11).replace("T", " ");
    let name =
      adut.customer.lastname +
      "-" +
      adut.customer.firstname +
      "-" +
      adut.project.id +
      "-" +
      new Date().toLocaleString("en-US", {
        month: "short",
        year: "numeric",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
      });

    const data = {
      name: name,
      datecreated: datecreated,
      owner: Number(user.id),
      customer: Number(adut.customer.id),
      json: JSON.stringify(adut),
      projectid: Number(adut.project.id),
    };

    var bodyFormDataProjects = new FormData();
    bodyFormDataProjects.append("action", "insert");
    bodyFormDataProjects.append("owner", Number(user.id));
    bodyFormDataProjects.append("token", user.token);
    bodyFormDataProjects.append("table", "invoices");
    bodyFormDataProjects.append("data", JSON.stringify(data));

    axios({
      method: "post",
      url: config.saveAPI,
      data: bodyFormDataProjects,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((res) => {
        let validated = res.data ? true : false;
        setLoginError(
          validated
            ? null
            : "Server message: Something went wrong with Saving INVOICE in invoice.modal line 371 "
        );
        toast.success("INVOICE  has been saved " + res.data, {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 1500,
        });
        //remove spinner
        setLoading(false);
        //StoreInvoices
        StoreInvoices();
      })
      .catch((err) => {
        toast.warn("Hmmm, SAVE INVOICE is having troubles:  " + err, {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 4500,
        });
        console.log(err);
      });
  };

  Modal.setAppElement("#app");

  return (
    <div className="card card-flush mt-6">
      <div className="card-header mt-6">
        <div className="card-title flex-column">
          <h3 className="title p-0 mb-1">Project Look Up</h3>
          <div className="fs-6 text-gray-400">Search for your customers </div>
        </div>
      </div>
      <div className="card-header">
        <div className="d-flex align-items-center position-relative my-1">
          <span className="svg-icon svg-icon-1 position-absolute ms-4">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                opacity="0.5"
                x="17.0365"
                y="15.1223"
                width="8.15546"
                height="2"
                rx="1"
                transform="rotate(45 17.0365 15.1223)"
                fill="currentColor"
              />
              <path
                d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z"
                fill="currentColor"
              />
            </svg>
          </span>

          <input
            type="text"
            data-kt-ecommerce-order-filter="search"
            className="form-control form-control-solid w-250px h-40px ps-14"
            placeholder="Search Order"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
        <div className="input-group w-250px d-flex align-items-center position-relative my-1">
          <Flatpickr
            ref={datepickrRef}
            data-input
            className="d-flex"
            onChange={([date1, date2]) => {
              if (date1) {
                setStartDate(date1);
              }
              if (date2) {
                setEndDate(date2);
              }
            }}
            options={{
              mode: "range",
              altInputClass: "hide",
              enableTime: true,
              dateFormat: "M d Y H:i",
              wrap: true,
            }}
          >
            {/* 		maxDate: new Date(),
							minDate: new Date("01-01-2018"), */}
            <input
              type="text"
              className="form-control form-control-solid h-40px rounded rounded-end-0"
              placeholder="Pick date range"
              data-input
            />
            <button data-toggle className="d-none" />
          </Flatpickr>
          <button
            className="btn btn-icon btn-light h-40px"
            onClick={() => {
              setStartDate(new Date(0));
              setEndDate(new Date(0));
              datepickrRef.current.flatpickr.clear();
            }}
          >
            {" "}
            <span className="svg-icon svg-icon-2">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  opacity="0.5"
                  x="7.05025"
                  y="15.5356"
                  width="12"
                  height="2"
                  rx="1"
                  transform="rotate(-45 7.05025 15.5356)"
                  fill="currentColor"
                />
                <rect
                  x="8.46447"
                  y="7.05029"
                  width="12"
                  height="2"
                  rx="1"
                  transform="rotate(45 8.46447 7.05029)"
                  fill="currentColor"
                />
              </svg>
            </span>{" "}
          </button>
        </div>
        <div className="w-100 mw-150px d-flex align-items-center position-relative my-1">
          <select
            className="form-select form-select-solid h-40px"
            data-control="select2"
            data-hide-search="true"
            data-placeholder="Status"
            data-kt-ecommerce-order-filter="status"
            value={dropdownValue}
            onChange={(e) => setDropdownValue(e.target.value)}
          >
            {props?.settings?.filter?.dropdownValues?.map((f, i) => (
              <option value={f.value} key={i}>
                {f.name}
              </option>
            ))}
          </select>
        </div>
      </div>

      <div className="card-body pt-0">
        <DataTable
          columns={col}
          data={filteredData}
          customStyles={customStyles}
          pagination
        />

        <Modal
          isOpen={showInvoiceModal}
          contentLabel="ProjectLookupInvoiceModal"
          shouldCloseOnOverlayClick={true}
          appElement={document.getElementById("app")}
        >
          <div className="content  d-flex flex-column rounded">
            <header className=" bg-light w-100 p-2  ">
              <h4 className="w-100">
                <button
                  className="close btn btn-primary mr-5"
                  id="close"
                  onClick={handleCloseModal}
                >
                  CLOSE
                </button>{" "}
                <button
                  className="close btn btn-primary mr-5"
                  id="close"
                  onClick={handleCloseModal}
                >
                  SEND AGAIN - missing fn(x)
                </button>{" "}
                <button
                  className="close btn btn-secondary mr-5"
                  id="close"
                  onClick={() => {
                    printElement();
                  }}
                >
                  PRINT
                </button>{" "}
                <button
                  className="close btn btn-secondary mr-5"
                  id="close"
                  onClick={handleCloseModal}
                >
                  SEND EMAIL
                </button>{" "}
                <button
                  className="close btn btn-secondary mr-5"
                  id="close"
                  onClick={() => {
                    SaveInvoice();
                  }}
                >
                  SAVE INVOICE in DB
                </button>
              </h4>
            </header>
            <main id="invoice">
              {selectedC.id ? (
                <IvoiceCreator selectedId={selectedC.id} />
              ) : null}
            </main>
          </div>
        </Modal>

        <Modal
          isOpen={showModal}
          contentLabel="ProjectLookupModal"
          shouldCloseOnOverlayClick={true}
          appElement={document.getElementById("app")}
        >
          <div className="content  d-flex flex-column  rounded">
            <header className="bg-light  w-100 p-2 ">
              <h4 className="w-100">
                <button
                  className="close btn btn-primary mr-5"
                  id="close"
                  onClick={handleCloseModal}
                >
                  CLOSE
                </button>{" "}
              </h4>
            </header>
            <main>
              <EditProject
                selected={selectedC}
                handleCloseModal={handleCloseModal}
              />

              <div className="row">
                <div className="col-sm-12 col-lg-6 mb-4 ">
                  <div className="card stretch bg-light">
                    <EditBom selected={selectedC} />
                  </div>
                </div>
                <div className=" col-sm-12 col-lg-6 mb-4 ">
                  <div className="card stretch bg-light">
                    <EditLabor selected={selectedC} />
                  </div>
                </div>
                <div className="col-sm-12 col-lg-12 mb-4 ">
                  {selectedC?.id ? (
                    <GetGallery
                      projectId={selectedC?.id}
                      selected={selectedC}
                      userId={user?.id}
                      type="edit"
                    />
                  ) : null}
                </div>
              </div>
            </main>
          </div>
        </Modal>
      </div>
    </div>
  );
};
export default TableProjectView;
