import React, { useEffect, useState } from "react";

const ViewProject = ({ props, data }) => {
  const project = data;

  return (
    <div className="row">
      <div className="col-sm-12 col-lg-12 position-relative">
        <h3>Project Details</h3>
        <div className="d-flex mb-10 w-100">
          <div className="w-100">
            <table className="table table-dashed table-rounded  gy-2 gs-2 w-100">
              <tbody>
                {project
                  ? Object.entries(project).map(([key, value]) =>
                      key != "json" &&
                      key != "photoID" &&
                      key != "bomID" &&
                      key != "owner" &&
                      key != "customer" &&
                      key != "laborID" &&
                      key != "photoID" ? (
                        <tr key={key}>
                          <td className="">
                            <span className="text-muted">{key}</span>
                            <span
                              className="text-dark ps-3"
                              dangerouslySetInnerHTML={{
                                __html: value,
                              }}
                            />
                          </td>
                        </tr>
                      ) : null
                    )
                  : null}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewProject;
