import React, {useState, useEffect } from "react";  
import DataTable from "./TableInvoicesView";
import { useSelector } from "react-redux";
import Error from "../../../templates/error";

const InvoiceLookup = (props) => {  
    const invoices = useSelector((state) => state.invoices.value);
    const projects = useSelector((state) => state.projects.value);
	const customers = useSelector((state) => state.customers.value);
	const user = useSelector((state) => state.user.value);
 
    // useEffect(() => {
    //     console.log("New value", customers);
    //         return () => {
    //             console.log("Prev value", customers);
    //         };
    // }, [customers]);
    
    const settings = {
      fields: [
        { name: "json", headerName: "Details", type: "hidden" },
        {
          name: "name",
          headerName: "File Name",
          type: "title",
          width: "400px",
        },
        { name: "projectid", headerName: "Project ID", type: "number" },
        { name: "datecreated", headerName: "Date Created", type: "date" },
        { name: "id", headerName: "Action", type: "delete" },
        { name: "id", headerName: "Version", type: "version" },
        { name: "customer", headerName: "Customer ID", type: "number" },
      ],
      filter: {
        datePickerField: "datecreated",
        dropdownField: "name",
        dropdownValues: [{ name: "All", value: "" }],
      },
    };
    return user.loginStatus ? (  
      <DataTable
      data={invoices.component}
      settings={settings}
      projects={projects}
      customers={customers}
      user={user}
      title="Invoice Look Up"
      subtitle="Search for your Invoices, see the history , resend invoice, see details."
    />
      ) : (
      <Error error="User has been logged out please login to have access" />
      );
     
}
export default InvoiceLookup;