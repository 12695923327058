import React, { useEffect, useState } from "react";
import Config from "../../config";
//import CalendarView from "../Common/CalendarView/calendarView";
import MonthlyFeed from "../Common/CalendarView/calendar.current.view";
import NavigationHorizontal from "../Header/navigation-horizontal";
import Store from "../Store/store.customers.projects";
import { useSelector } from "react-redux";

// import ProjectLookup from "../Common/Lookup/projects.lookup";
// import CustomerLookup from "../Common/Lookup/customer.lookup";
// import InvoiceLookup from "../Common/Lookup/invoice.lookup";
// import ImportCustomer from "../../components/Common/services/import/importCustomers/import.customers";
// import Invoice from "../../templates/creator.invoice.template";

const HomeLayouts = (props) => {
  const user = useSelector((state) => state.user.value);
  const settings = useSelector((state) => state.settings.value);
  const [current, setCurrent] = useState([]);
  const [homeImage, setImage] = useState("");
  const toAbsoluteUrl = (pathname) => {
    return "/images/" + pathname;
  };

  useEffect(() => {
    isThisMOnth();
    setImage(settings?.component ? settings?.component[0].homebackground : "");
  }, [settings]);

  const enumerateMonths = (from) => {
    const current = new Date(from);
    current.setUTCDate(1);
    current.setUTCHours(0, 0, 0, 0);

    let month = [];
    month.year = current.getUTCFullYear();
    month.month = `${current.getUTCMonth() + 1}`.padStart(2, "0");
    setCurrent(month);
  };

  const isThisMOnth = () => {
    const date = new Date();
    const setDate = date.toLocaleString("en-US", {
      year: "numeric",
      month: "2-digit",
    });
    enumerateMonths(date);
    console.log("Home Layout :: setDate", setDate);
  };
  return (
    <div className="d-flex flex-column flex-root fadein  w-100 ">
      <Store />
      <div className="vh-100 d-flex bg-white">
        <div
          className="w-100 d-flex align-items-center justify-content-end"
          style={{
            backgroundImage: `url(${toAbsoluteUrl(`${homeImage}`)}`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundColor: "#fff",
          }}
        >
          <h2 className="logo">Welcome to Creator Business Center</h2>
          <div
            className="w-75 d-flex flex-column align-items-center justify-content-center"
            style={{
              backgroundImage: `url(${toAbsoluteUrl("/logo/login-bckg.svg")}`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              height: "100%",
              display: "flex",
              position: "relative",
            }}
          >
            <p className=" text-dark">
              Welcome {user?.username}, here are your incoming projects ...
            </p>

            {current && <MonthlyFeed month={current} />}

            {Config.headerData && (
              <NavigationHorizontal data={Config.headerData.navigations} />
            )}
            {/* <CalendarView mode="home" /> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeLayouts;
