import { createSlice } from "@reduxjs/toolkit";

const initialStateValue = {
  component: [],
  menu: [],
  slectedCustomer:[]
};
//creating customers
export const customersSlice = createSlice({
  name: "customers",
  initialState: { value: initialStateValue },
  reducers: {
    updateCustomerStore: (state, action) => {
      state.value = action.payload;
    },
    unloadedCustomerStore: (state) => {
      state.value = initialStateValue;
    },
  },
});

export const { updateCustomerStore, unloadedCustomerStore } = customersSlice.actions;

export default customersSlice.reducer;
