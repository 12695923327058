const LoadingSpinner = (props) => {
  return (
    <div className="parentDisable" width="100%">
      <div className="spinner-border" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>
  );
};
export default LoadingSpinner;
