import React, { useRef, useState } from "react";
//import Navbar from "react-bootstrap/Navbar";
import { Link } from "react-router-dom";
//import Navigation from "./navigation-horizontal";
import SlidingPane from "react-sliding-pane";
import NavigationVertical from "./navigation-vertical";
import "react-sliding-pane/dist/react-sliding-pane.css";
import { useSelector } from "react-redux";
/**
 *
 * @Container props
 * logo: Website Logo image
 * data.items[]: array of {url, name} -> for navigation links
 */
const Header = (props) => {
  document.documentElement.style.setProperty(
    "--header-background-color",
    props?.data?.backgroundColor
  );
  const user = useSelector((state) => state.user.value);
  const [isPaneOpen, setisPaneOpen] = useState(false);
  const [isPaneOpenLeft, setisPaneOpenLeft] = useState(false);
  const headerData = props?.data;

  const closeThePanel = ()=>{
	setisPaneOpenLeft(false)
  }
  return (
		<header className="header">
			 
				<div className="d-flex align-items-start">

					<a className="link fs-7 d-inline m-2 me-3"
					onClick={() => setisPaneOpenLeft(true)}
					>
						<i className="fa-duotone fa-bars fs-1 text-secondary"></i>
					</a>

					<Link to="/home">
						<h2 className="logo" data-text="StefaDesign">
							StefaDesign
						</h2>
					</Link>
						
				</div>
			 
			<SlidingPane
					className="some-custom-class"
					overlayClassName="some-custom-overlay-class"
					isOpen={isPaneOpenLeft}
					title={user.company}
					subtitle={user.username}
					width="320px"
					from="left"
					onRequestClose={() => {
						// triggered on "<" on left top click or on outside click
						setisPaneOpenLeft(false);
					}}
					>
					
					{props?.data && props.data.navigations && (
						<NavigationVertical data={props.data.navigations} closeThePanel={closeThePanel} />
					)}
					
					</SlidingPane>
		</header>
	);
};

export default Header;
