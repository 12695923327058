import React, { useEffect } from "react";

const ViewHeader = ( props) => {
	let thismonth = "";
	const user = props?.user;
	const selectedCustomer = props?.selectedCustomer;
	console.log("invoice HEADER props:", props);
	return (
		<div className="row row-cols-2   mb-6 mb-xl-9">
		   {/* LOGO IMAGE  */}
			<div className="col-12 center ">
				{user?.companylogo ? (
					<img
						src={user?.companylogo}
						width="200px"
						className="printable m-4 center "
					/>
				) : null}
			</div>
		  {/* OWNER DATA  */}
			<div className="col">
				<div className="card pt-2 h-md-100 mb-6 mb-md-0">
					<div className=" px-6 align-items-start m-2">
						<span className="d-flex flex-column align-items-start ms-2">
							<span className="fs-6 fw-bolder">{user?.company}</span>

							<span className="fs-7">
								{user?.address}, {user?.zip}
								{", "}
								{user?.city}
							</span>
							<span className="fs-8 ">
								Owner: {user?.firstname}
								{user?.lastname}
							</span>
						</span>
					</div>
				</div>
			</div>
			{/* CUSTOMER DATA  */}
			<div className="col">
				<div className="card pt-2 h-md-100 mb-6 mb-md-0">
					<div className=" px-6 align-items-start m-2">
						<span className="d-flex flex-column align-items-start ms-2">
							<span className="fs-6 fw-bolder">
								<span className="fs-6 fw-bolder">
									{selectedCustomer?.firstname}
									{selectedCustomer?.lastname}
								</span>{" "}
							</span>

							<span className="fs-7">
								{selectedCustomer?.address1} {", "}
								{selectedCustomer?.zip1}
								{", "}
								{selectedCustomer?.city1}
							</span>

							<span className="fs-7">Phone: {selectedCustomer?.phone}</span>
						</span>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ViewHeader;
