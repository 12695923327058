 
import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import axios from "axios";
import config from "../../../config";
import UploadPhoto from "./uploadFile";
import { Spinner } from "react-bootstrap";


function Photo({ props, selected, selectedProject }) {
  const imageRef = useRef();
  const user = useSelector((state) => state.user.value);
  // const menu = useSelector((state) => state.menu.value);
  const [capteringFinished, setCapteringFinished] = useState(false);
  const [spinner, setSpinner] = useState(false);
  const [photoTaken, setPhotoTaken] = useState("");
  const [showTakeAPicture, setShowTakeAPicture] = useState(false);
  const [showUploadImage, setShowUploadImage] = useState(false);
  const [showGallery, setShowGallery] = useState(true);
  const [photoGalery, setPhotosGalery] = useState([]);
  const [loginError, setLoginError] = useState("");
  const [fileUploadOngoing, setfileUploadOngoing] = useState(false);
  const [refresh, setRefresh] = useState(false);
  //
  // The width and height of the captured photo. We will set the
  // width to the value defined here, but the height will be
  // calculated based on the aspect ratio of the input stream.

  var width = "540"; // We will scale the photo width to this
  var height = "auto"; // This will be computed based on the input stream
  var classShowStreaming = capteringFinished ? "hide" : "show";
  var classShowImage = capteringFinished ? "show" : "hide";
  var classShowSpinner = spinner ? "show" : "hide";
  // |streaming| indicates whether or not we're currently streaming
  // video from the camera. Obviously, we start at false.


  // The various HTML elements we need to configure or control. These
  // will be set by the startup() function.
	var streaming = false;
	var video = null;
	var canvas = null;
	var photo = null;
	var startbutton = null;
	var data;
	video = document.getElementById("video");
	canvas = document.getElementById("canvas");
	photo = document.getElementById("photo");
	
  useEffect(() => {
    if (selectedProject?.id && selected?.id) {
      getAllPhotos();
    }
}, [refresh]);
  useEffect(() => {  
    getAllPhotos();
	  setCapteringFinished(false);
  }, [ props]);

  const reTakePic = () => {
    setCapteringFinished(false);
    //reset picture taking
  };

  const savePhoto = () => {
    setSpinner(true);
    SaveCapturedPhoto();
    if (selectedProject?.id && selected?.id) {
      getAllPhotos();
    }
  };

  const SaveCapturedPhoto = () => {
    setSpinner(true);
    //console.log("SaveCapturedPhoto saving data:", photoTaken);
    let owner = Number(user.id);
    let customer = selected.id ? Number(selected.id) : 0;
    let projectid = Number(selectedProject.id);
    let datecreated = new Date().toISOString().slice(0, 19).replace("T", " ");
    const d = new Date();
    let time = d.getTime();
    const postData = new FormData();
    postData.append("owner", owner);
    postData.append("customer", customer);
    postData.append("projectid", projectid);
    postData.append("action", "insert");
    postData.append("photo", photoTaken);
    postData.append("created", datecreated);
    postData.append("name", time + "_" + customer + "-" + projectid);
    postData.append("table", "photos");
    if (selectedProject?.id && selected?.id) {
      axios({
        method: "post",
        url: config.saveAPI,
        data: postData,
        config: { headers: { "Content-Type": "multipart/form-data" } },
      })
        .then(function (response) {
          toast.success("Photo has been submitted " + response.data, {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 5500,
          });
          //document.getElementById("close").click();
          console.log("submitted form  ", response.data);
          console.log(response);

          setTimeout(() => {
            setSpinner(false);
            reTakePic();
          }, 3000);
        })
        .catch(function (response) {
          //handle error
          console.log("ERROR FORM SERVER", response);
          toast.warn(" Photo Submission failed.", {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 1500,
          });
          setSpinner(false);
        });
    } else {
      toast.error("Select CUSTOMER and PROJECT FIRST!.", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 5500,
      });
      setSpinner(false);
    }
  };

  const startup = () => {
    console.log("STARTING CAPTURE SETTINGS");
    video = document.getElementById("video");
    canvas = document.getElementById("canvas");
    photo = document.getElementById("photo");
    startbutton = document.getElementById("startbutton");
    startbutton.addEventListener(
      "click",
      function (ev) {
        clearphoto();
        takepicture();
        ev.preventDefault();
      },
      false
    );

    navigator.mediaDevices
      .getUserMedia({ video: true, audio: false })
      .then(function (stream) {
        video.srcObject = stream;
        video.play();
      })
      .catch(function (err) {
        console.log("An error occurred: " + err);
      });

    video.addEventListener(
      "canplay",
      function (ev) {
        if (!streaming) {
          height = video.videoHeight / (video.videoWidth / width);

          // Firefox currently has a bug where the height can't be read from
          // the video, so we will make assumptions if this happens.

          if (isNaN(height)) {
            height = width / (4 / 3);
          }
          video.setAttribute("width", width);
          video.setAttribute("height", height);
          canvas.setAttribute("width", width);
          canvas.setAttribute("height", height);
          streaming = true;
        }
      },
      false
    );
  };

  // Fill the photo with an indication that none has been
  // captured.

  const clearphoto = () => {
    setCapteringFinished(false);
    var context = canvas.getContext("2d");
    context.fillStyle = "#000";
    context.fillRect(0, 0, canvas.width, canvas.height);
    var data = canvas.toDataURL("image/png");
    photo.setAttribute("src", data);
  };

  // Capture a photo by fetching the current contents of the video
  // and drawing it into a canvas, then converting that to a PNG
  // format data URL. By drawing it on an offscreen canvas and then
  // drawing that to the screen, we can change its size and/or apply
  // other changes before drawing it.

  const takepicture = () => {
    var context = canvas.getContext("2d");
    if (width && height) {
      canvas.width = width;
      canvas.height = height;
      context.drawImage(video, 0, 0, width, height);
      setCapteringFinished(true);
      data = canvas.toDataURL("image/png");
      setPhotoTaken(data);
      photo.setAttribute("src", data);
    } else {
      clearphoto();
    }
  };
  //get all images for this project
  const getAllPhotos = () => {
    //load All
    if (selected?.id) {
      setSpinner(true);
      var bodyFormDataProjects = new FormData();
      bodyFormDataProjects.append("action", "select");
      bodyFormDataProjects.append("owner", Number(selected.owner));
      bodyFormDataProjects.append("projectid", Number(selectedProject.id));
      bodyFormDataProjects.append("table", "photos");
      axios({
        method: "post",
        url: config.saveAPI,
        data: bodyFormDataProjects,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then((res) => {
          let apiResponse = res;
          let validated = res.data ? true : false;
          if (validated) {
            //set data
            setPhotosGalery([]);
            setPhotosGalery(res.data);
            toast.success("Retreiving All photos fro this project complete  ", {
              position: toast.POSITION.BOTTOM_RIGHT,
              autoClose: 1500,
            });
          }
          setLoginError(
            validated
              ? null
              : "Server message: Something went wrong with loading ALL PHOTOS for this project ID"
          );
          //remove spinner
          setSpinner(false);
          console.log("RECIEVED ALL PHOTOS DATA ", res.data);
        })
        .catch((err) => {
          toast.warn(
            "Hmmm, looks like server is having issues :  " + err,
            {
              position: toast.POSITION.BOTTOM_RIGHT,
              autoClose: 4500,
            }
          );
          console.log(err);
        });
    }
  };

  const deleteItem = (id, table) => {
    //action: disconnect
    // Removing($table, $owner ,$action, $id, $token)

    if (
      id &&
      window.confirm(
        "You are about to delete " +
          table +
          " with ID:" +
          id +
          " press OK to continue or cancel to cancel"
      )
    ) {
      //console.log("GET BOM :id", props.selected.id);
      var bodyFormDataProjects = new FormData();
      bodyFormDataProjects.append("action", "disconnect");
      bodyFormDataProjects.append("token", user.token);
      bodyFormDataProjects.append("owner", Number(user.id));
      bodyFormDataProjects.append("id", Number(id));
      bodyFormDataProjects.append("table", table);
      axios({
        method: "post",
        url: config.saveAPI,
        data: bodyFormDataProjects,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then((res) => {
          if(res?.data?.data==="Expired"){
            toast.warn("Error:  Please re-login your session has expired", {
              position: toast.POSITION.BOTTOM_RIGHT,
              autoClose: 3500,
            });
          }
          else{
            toast.success(res.data.data, {
              position: toast.POSITION.BOTTOM_RIGHT,
              autoClose: 1500,
            });
          }
          //remove spinner
          //setLoading(false);
          //refresh data
          //REMOVE IMAGE FROM DOM;
          //imageRef.current.removeChild(imageRef.current.children[0])
          setRefresh(!refresh);
          //console.log("RECIEVED BOM DATA ", res.data);
        })
        .catch((err) => {
          toast.warn(
            "Hmmm, I am having troubles with server cannot delete:  " + err,
            {
              position: toast.POSITION.BOTTOM_RIGHT,
              autoClose: 4500,
            }
          );
          console.log(err);
        });
    } else {
      return;
    }
  };

 
  // Set up our event listener to run the startup process
  // once loading is complete.
  //window.addEventListener("load", startup, false);

	return selectedProject.id && selected.id ? (
    <div className="add-photo row">
      <div className="buttons flex horizontal col-12 mt-4 mb-4 "></div>

      {/* className={(index = 0 ? "ms-1 active" : "ms-1")} */}

      <div className="card card-custom ">
        <div className="card-header card-header-stretch ribbon ribbon-end ribbon-clip">
          {showTakeAPicture || showUploadImage || showGallery ? (
            <div className="ribbon-label">
              {showTakeAPicture ? "Take a Picture" : ""}
              {showUploadImage ? "Upload image" : ""}
              {showGallery ? "Galery" : ""}
              <span className="ribbon-inner bg-success"></span>
              {spinner ? (
                <Spinner animation="border" className="text-white m-5" />
              ) : null}
            </div>
          ) : null}

          <div className="card-toolbar">
            <ul className="nav nav-tabs nav-line-tabs nav-stretch fs-6 border-0">
            <li className="nav-item  ">
                <a
                  data-bs-toggle="tab"
                  href="#home_modules_3"
                  className={showGallery ? "nav-link active" : "nav-link active"}
                  onClick={() => {
                    setShowGallery(true);
                    setShowUploadImage(false);
                    setShowTakeAPicture(false);
                    if (selectedProject?.id && selected?.id) {
                      //console.log("GETTING ALL PHOTOS");
                      getAllPhotos();
                    }
                  }}
                >
                 Gallery 
                </a>
              </li>
              <li className="nav-item  ">
                <a
                  data-bs-toggle="tab"
                  href="#home_modules_1"
                  className={
                    showTakeAPicture ? " nav-link active" : " nav-link "
                  }
                  onClick={() => {
                    setShowGallery(false);
                    setShowUploadImage(false);
                    setShowTakeAPicture(true);
                  }}
                >
                  Take a Picture
                </a>
              </li>
           
              <li className="nav-item  ">
                <a
                  data-bs-toggle="tab"
                  href="#home_modules_2"
                  className={showUploadImage ? " nav-link active" : " nav-link"}
                  onClick={() => {
                    setShowGallery(false);
                    setShowUploadImage(true);
                    setShowTakeAPicture(false);
                  }}
                >
                  Upload 
                </a>
              </li>
             
            </ul>
          </div>
        </div>

        <div className="card-body">
          <div className="tab-content" id="myTabContent">
            <div
              className="tab-pane fade"
              id="home_modules_1"
              role="tabpanel"
            >
              {showTakeAPicture ? (
                <div className="row">
                  <div
                    className={`camera ${classShowStreaming}  align-items-center  col-sm-12 col-md-6 col-lg-6 d-flex `}
                  >
                    <video
                      id="video"
                      width="100%"
                      height="auto"
                      className="bg-dark"
                    >
                      Video stream not available.
                    </video>

                    <ul className="nav nav-tabs nav-line-tabs nav-stretch fs-6 border-0 ">
                      <li className="nav-item">
                        <button
                          id="startbutton"
                          className="btn btn-danger m-2 position-relative"
                          onClick={() => startup()}
                        >
                          Take
                          <div className=" rounded-circle  border-4 border-body h-60px w-60px">
                            <i className="fa-duotone fa-camera fs-1 text-white mt-2 ms-1"></i>
                          </div>
                        </button>
                      </li>
                    </ul>
                    <span className="m-2 text-muted ms-0">
                      Before you take a picture select project and customer from
                      dropdowns above.
                    </span>
                  </div>

                  <div
                    className={`camera ${classShowImage}  align-items-center col-sm-12 col-md-6 col-lg-6 d-flex `}
                  >
                    <div className="canvas">
                      <canvas id="canvas"> </canvas>
                      <p className="btn btn-primary  mt-2">Canvas Preview</p>
                    </div>
                    <img
						id="photo"
						alt="The screen capture will appear in this box."
						width="100%"
						src="https://www.treehugger.com/thmb/GPjiQZfyXMsbdakVm-8t2Ww2HxQ=/889x667/smart/filters:no_upscale()/__opt__aboutcom__coeus__resources__content_migration__mnn__images__2018__06__nature-phpoto-day-squirrel-6b50719b524c4ecbb3152a32baa38367.jpg"
                    />
                    <ul className="nav nav-tabs nav-line-tabs nav-stretch fs-6 border-0">
                      <li className="nav-item">
                        <button
                          id="clearButton"
                          className="btn btn-secondary m-2"
                          onClick={() => clearphoto()}
                        >
                          Clear{" "}
                          <div className="bg-gray rounded-circle  border-4 border-body h-60px w-60px">
                            <i className="fa-duotone fa-broom-wide fs-1 text-white mt-2 ms-1"></i>
                          </div>
                        </button>
                      </li>
                      <li className="nav-item">
                        <button
                          id="reTakeButton"
                          className="btn btn-danger m-2"
                          onClick={() => reTakePic()}
                        >
                          Re-take{" "}
                          <div className="bg-gray rounded-circle   border-4 border-body h-60px w-60px">
                            <i className="fa-duotone fa-rotate-right fs-1 text-white mt-2 ms-1"></i>
                          </div>
                        </button>
                      </li>
                      <li className="nav-item">
                        <button
                          id="savebutton"
                          className="btn btn-success orange m-2"
                          onClick={() => savePhoto()}
                        >
                          Save Photo{" "}
                          <div className="bg-gray rounded-circle   border-4 border-body h-60px w-60px">
                            <i className="fa-duotone fa-floppy-disk-circle-arrow-right fs-1 text-white mt-2 ms-1"></i>
                          </div>
                          <span
                            id="loading"
                            className={`spinner btn btn-primary orange ${classShowSpinner}`}
                          ></span>
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>
              ) : null}
            </div>
            <div className="tab-pane fade" id="home_modules_2" role="tabpanel">
              {showUploadImage ? (
                <div className="row">
                  <UploadPhoto
                    owner={Number(user.id)}
                    customer={selected.id ? Number(selected.id) : 0}
                    projectid={Number(selectedProject.id)}
                    created={new Date()
                      .toISOString()
                      .slice(0, 19)
                      .replace("T", " ")}
                    name={
                      new Date().getTime() +
                      "_" +
                      selected.id +
                      "-" +
                      Number(selectedProject.id)
                    }
                  />
                </div>
              ) : null}
            </div>
            <div className="tab-pane fade show active" id="home_modules_3" role="tabpanel">
              <div className="container">
                <div className="d-flex flex-wrap">
                  {showGallery && photoGalery? (
                    <>
                      {photoGalery.length > 0
                        ? photoGalery.map((item, index) => {
                            return (
                              <div className="col-lg-4" key={index} ref={imageRef}>
                                <div className="card card-custom overlay overflow-hidden">
                                  <div className="card-body p-0">
                                    <div className="overlay-wrapper">
                                      <img
                                        src={item.data}
                                        alt=""
                                        className="w-100 rounded"
                                      />
                                    </div>
                                    <div className="overlay-layer bg-dark bg-opacity-10 flex-column">
                                        <div className="bg-dark text-warning m-1 p-3 rounded-3">
                                          Date:{item.created}
                                        </div>
                                       
                                          <button
                                            className="btn btn-danger text-white center mb-0"
                                            onClick={() => deleteItem(item.id, "photos")}
                                          >
                                            Delete
                                          </button>
                                        
                                      </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })
                        : null}
                    </>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : null;
}

export default Photo;
 