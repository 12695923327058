import { createSlice } from "@reduxjs/toolkit";

const initialStateValue = {
  components: [],
};
//creating MENU
export const menuSlice = createSlice({
  name: "menu",
  initialState: { value: initialStateValue },
  reducers: {
    updateMenu: (state, action) => {
      state.value = action.payload;
    },
    unloadedMenu: (state) => {
      state.value = initialStateValue;
    },
  },
});

export const { updateMenu, unloadedMenu } = menuSlice.actions;

export default menuSlice.reducer;
