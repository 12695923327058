import React, { useEffect, useState } from "react";

const ViewTotalSignature = ({
	props,
	data,
	customer,
	owner,
	total,
	tax,
	totalNoTax,
	totalTax,
}) => {
  let thismonth = "";
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",

    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  }); // {formatter.format(item.price)}
  return (
    <div className="row">
      <div className="col-sm-12 col-lg-12 position-relative">
        <h3>Total project cost </h3>
        <div className="d-flex justify-content-end  fs-6  text-muted w-100 p-5 pb-0 pt-0">
          <span className="text-muted ">
            Total labor & material (no tax included): 
            {formatter.format(totalNoTax)}, witout the tax. Tax for project is{" "}
            {tax}% which comes of amount of {formatter.format(totalTax)}
            to add on top of bill of mateial and labor cost. Finally, total cost
            calculated is {formatter.format(totalNoTax)} + 
            {formatter.format(totalTax)} which wraps up all expenses for project
            with the price of{" "}
            <i className="text-danger ps-2"> {formatter.format(total)}</i>
          </span>
        </div>

        <hr />
        <div className="d-flex justify-content-end  fs-4 fw-bold text-muted w-100 p-5 ">
          <span className="text-muted">Project cost due (including tax): </span>
          <span className="text-danger ps-2"> {formatter.format(total)}</span>
        </div>
        <div className="row ">
          <div className="col mt-6">
            <hr className="m-4" />
            <span className="text-muted center">Singature {customer}</span>
          </div>
          <div className="col mt-6">
            <hr className="m-4" />
            <span className="text-muted center">Singature {owner}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewTotalSignature;
