import React, { useEffect, useState } from "react";
import ItemDates from "./item.dates";
import ItemProjects from "./item.projects";
import { useSelector, useDispatch } from "react-redux";
import { updateProjectStore } from "../../../features/projects";
import axios from "axios";
import config from "../../../config";

function CalendarView(props) {
  const [months, setmonths] = useState([]);
  const settings = useSelector((state) => state.settings.value);
  const user = useSelector((state) => state.user.value);
  const dispatch = useDispatch();

  useEffect(() => {
    setMonths();
    StoreProject();
  }, [props]);

  const addMonths = (date, months) => {
    date.setMonth(date.getMonth() + months);
    return date;
  };

  const setMonths = () => {
    const mnthNumber = settings?.component?.calendar
      ? settings?.component[0]?.calendar
      : 6;
    const monthsGroup = {
      data: enumerateMonths(
        addMonths(new Date(), -mnthNumber),
        addMonths(new Date(), +mnthNumber)
      ),
    };
    setmonths({ ...monthsGroup });
  };

  const enumerateMonths = (from, to) => {
    const current = new Date(from);
    current.setUTCDate(1);
    current.setUTCHours(0, 0, 0, 0);
    const toDate = new Date(to);
    const months = [];

    while (current.getTime() <= toDate.getTime()) {
      let month = [];
      month.year = current.getUTCFullYear();
      month.month = `${current.getUTCMonth() + 1}`.padStart(2, "0");
      const date = { ...month };
      months.push(date);
      current.setUTCMonth(current.getUTCMonth() + 1);
    }
    return months;
  };
  const StoreProject = () => {
    //projects update
    var bodyFormDataProjects = new FormData();
    bodyFormDataProjects.append("action", "select");
    bodyFormDataProjects.append("token", user.token);
    bodyFormDataProjects.append("owner", Number(user.id));
    bodyFormDataProjects.append("table", "projects");

    axios({
      method: "post",
      url: config.saveAPI,
      data: bodyFormDataProjects,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((res) => {
        console.log("STORE IN CALENDAR RELOADED PROJECTS");
        //set store
        dispatch(
          updateProjectStore({
            component: res.data,
          })
        );
      })
      .catch((err) => {
        toast.warn("Hmmm, projects store is having troubles:  " + err, {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 4500,
        });
        console.log(err);
      });
  };

  return (
    <div className="card card-stretch  h-lg-100">
      {props.mode === "home" ? (
        <div className="card-header mt-6 ">
          <div className="card-title flex-column">
            <div className="fs-6 text-gray-400">
              Here is how your incoming projects for this month look like.{" "}
            </div>
          </div>
        </div>
      ) : (
        <div className="card-header mt-6 ">
          <div className="card-title flex-column">
            <h3 className="title p-0 mb-1">
              Road Map in past and future 6 months
            </h3>
            <div className="fs-6 text-gray-400">Your Incoming projects </div>
          </div>
        </div>
      )}

      <div className="card-body bg-light primary p-3 shadow-sm d-flex flex-wrap justify-content-between align-items-center border-bottom py-6">
        <ul
          className="nav nav-pills d-flex flex-nowrap hover-scroll-x py-2"
          role="tablist"
        >
          {months?.data
            ? months?.data.map((item, i) => {
                return <ItemDates month={item} key={i} index={i} />;
              })
            : null}
        </ul>

        <div className="tab-content w-100 c">
          {months?.data ? (
            <ItemProjects months={months?.data} />
          ) : (
            <p>Hmmm... looks like nothing is going on this month</p>
          )}
        </div>
      </div>
    </div>
  );
}

export default CalendarView;
