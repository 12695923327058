import React from "react";

const ItemDates = (props) => {
  const month = props.month;
  const date = new Date(month.year + "-" + month.month + "-01");
  const textDate = date.toLocaleString("en-US", { month: "short" }); // {month:'long'}

  const isThisMOnth = () => {
    const date = new Date(props.month.year + "-" + props.month.month + "-01");
    const setDate = date.toLocaleString("en-US", {
      year: "numeric",
      month: "2-digit",
    });
    var currentDate = new Date();

    if (date.getMonth() == currentDate.getMonth()) {
      // console.log(
      // 	"MATCH IN MOTNHS " + date.getMonth(),
      // 	currentDate.getMonth()
      // );
      return true;
    } else {
      // console.log(
      // 	"NO MATCH IN MOTNHS " + date.getMonth(),
      // 	currentDate.getMonth()
      // );
    }
  };
  return (
    <li className="nav-item me-1" role="presentation">
      <a
        className={`nav-link btn d-flex flex-column flex-center rounded-pill min-w-45px me-2 py-4 px-3 ${
          isThisMOnth() ? "  btn-primary-red active " : "  btn-red"
        }`}
        data-bs-toggle="tab"
        href={`#item${month.year}-${month.month}`}
        aria-selected={isThisMOnth() ? true : false}
        role="tab"
      >
        <span className="opacity-50 fs-7 fw-semibold">{month.year}</span>
        {/* <span className="fs-6 fw-bold uppercase">{new Date((month.year + "-" + month.month + "-01").toString()).toLocaleString("en-US", { month: "short" })}</span> */}
        <span className="fs-6 fw-bold uppercase">
          {new Date(
            (month.year + "-" + month.month + "-10").toString()
          ).toLocaleString("en-US", { month: "short" })}
        </span>
      </a>
    </li>
  );
};
export default ItemDates;
