import React, { useEffect, useState } from "react";
//import Navigation from "../Header/navigation-horizontal";
/**
 *
 * @Components props.data
 * name: component name (not user for rendering)
 * notes : copyright info,
 * img: company logo,
 * items[]: footerlinks contains array of {url, name}
 */

const Footer = (props) => {
  const { data, site } = props;

    document.documentElement.style.setProperty(
      "--footer-background-color",
      props?.data?.backgroundColor
    );

    return (
			<footer className="footer row p-3">
				<div className="col-lg-4 col-sm-12 col-md-3 py-3 font-0_7"></div>
				<div className="col-lg-4 col-sm-12 col-md-5 py-3 center">
					<h2 className="logo" data-text="StefaDesign">
						StefaDesign
					</h2>
					<p>{data && data.notes}</p>
				</div>
				<div className="col-lg-4 col-sm-12 col-md-4 m-auto font-0_7 "></div>
			</footer>
		);
};
export default Footer;
