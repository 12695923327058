import React, { useState } from "react";
import { useSelector } from "react-redux";
import Tools from "../components/tools/tools.layouts";
//import CustomerStore from "../components/Common/CreateComponent/store.customers.projects";
//import Store from "../components/Common/CreateComponent/store.customers.projects";
//import TypeAheadCustomers from "../components/Common/CreateComponent/find.customer.typeahead";
//import TypeAheadProjects from "../components/Common/CreateComponent/find.project.typeahead";
import Error from "./error";

const CreatorTools = (props) => { 

	const user = useSelector((state) => state.user.value); 
	//console.log("Tools:", user);
	 
	return user.loginStatus ? (
		<div className=" align-left">
			 <Tools/>
		</div>
	) : (
		<Error error="User has been logged out please login to have access" />
	);
};

export default CreatorTools;
