import React, { useRef, useState } from "react";
import { BrowserRouter } from "react-router-dom";
import "./App.scss";
import Footer from "./components/Footer/Footer";
import Header from "./components/Header/Header";
import Config from "./config";
import Routers from "./Routers"; 

import "react-toastify/dist/ReactToastify.css"; 

function App() {
  // if (siteconfig) {
  //   document.documentElement.style.setProperty(
  //     "--primary-color-dark",
  //     siteconfig.colorPrimaryBckg
  //   );
  //   
  //   document.title = siteconfig.siteTitle;
  // }
 

  return (
    <div
      id="main"
      className={`${
        JSON.parse(window.localStorage.getItem("staticProjectName"))
          ? JSON.parse(window.localStorage.getItem("staticProjectName"))
          : "default"
      }`}
    >
      <BrowserRouter>
        {Config.headerData && <Header data={Config.headerData} logo={Config.headerData.logo} />}
        <Routers />
        {Config.footerData && <Footer data={Config.footerData} />}
      </BrowserRouter>
 
    </div>
  );
}

export default App;
