import { useEffect } from "react";
import { useDropzone } from "react-dropzone";

function Dropzone({ onDrop, accept, uploadFile, setError }) {
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragReject,
    acceptedFiles,
    fileRejections,
  } = useDropzone({
    accept,
    onDrop,
    multiple: false,
  });

  // useEffect(() => {
  //   console.log("removeAll...");
  //   acceptedFiles.length = 0;
  //   acceptedFiles.splice(0, acceptedFiles.length);
  //   console.log(acceptedFiles);
  // }, []);
  // console.log("isDragActive:", isDragActive);
  // console.log("isDragReject:", isDragReject);

  useEffect(() => {
    if (fileRejections?.length > 0) {
      fileRejections?.forEach(({ file, errors }) =>
        errors?.forEach((e) => setError(e.message))
      );
    }
  }, [JSON.stringify(fileRejections)]);

  const files = uploadFile.map((file) => (
    <li key={file.path} className="d-flex justify-content-between">
      <div>{file.path}</div>
    </li>
  ));

  return (
    <div>
      <div {...getRootProps({ className: "dropzone" })}>
        <input className="input-zone" {...getInputProps()} />
        <div className="text-center">
          {isDragActive ? (
            <div className="dropzone-content">
              Release to drop the file here
            </div>
          ) : (
            <div
              className="dropzone-content d-flex flex-column justify-content-center h-80px border-secondary"
              style={{ borderStyle: "dashed" }}
            >
              <i className="fs fs-1 fa-sharp fa-solid fa-download"></i>

              <div className="mt-3">
                Drag & drop or <a className="text-primary">browse</a> csv file
                to upload
              </div>
            </div>
          )}
        </div>
      </div>

      <aside>
        <ul>{files}</ul>
      </aside>
    </div>
  );
}

export default Dropzone;
