import React, { useState } from "react";
import { useSelector } from "react-redux";
import Creator from "./creator";
//import CustomerStore from "../components/Store/store.customers.projects";
import Store from "../../Store/store.customers.projects";
import Error from "../../../templates/error";

const Create = (props) => {
  const customers = useSelector((state) => state.customers.value);
  const projects = useSelector((state) => state.projects.value);

  const user = useSelector((state) => state.user.value);

  // console.log("SELECTED CUSTOMER FROM PARENT COMPONENT:", selected);

  return user.loginStatus ? (
    <div className="card  align-left">
      <Store />
      <div className="card-header mt-6">
        <div className="card-title flex-column">
          <h3 className="title p-0 mb-1">Project Creator</h3>
          <div className="fs-6 text-gray-400">
            Everything starts from project! Select Customer and create new
            project.
          </div>
        </div>
      </div>
      <div className="card-body pt-0">
        <Creator options={customers.component} />
      </div>
    </div>
  ) : (
    <Error error="User has been logged out please login to have access" />
  );
};

export default Create;
