import React, { useState, useEffect } from "react";
import config from "../../../config";
import { toast } from "react-toastify";
import axios from "axios";
/**
 * react-data-table-component package to display data in table format with paginationa
 * flatpickr and react-flatpickr for date picker
 */

const ProjectGallery = (props) => {
  //get all images for this project

  const [spinner, setSpinner] = useState(false);
  const [photoGalery, setPhotosGalery] = useState([]);
  const [loginError, setLoginError] = useState("");
  const owner = props.owner;
  const projectId = props.projectId;
  const projectname = props.projectname;

  const getAllPhotos = (owner, projectId, projectname) => {
    //load All
    if (projectId) {
      setSpinner(true);
      var bodyFormDataProjects = new FormData();
      bodyFormDataProjects.append("action", "select");
      bodyFormDataProjects.append("owner", Number(owner));
      bodyFormDataProjects.append("projectid", Number(projectId));
      bodyFormDataProjects.append("table", "photos");
      axios({
        method: "post",
        url: config.saveAPI,
        data: bodyFormDataProjects,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then((res) => {
          let validated = res.data ? true : false;
          if (validated) {
            setPhotosGalery((prevState) => [
              ...prevState,
              { data: res.data, project: projectId, name: projectname },
            ]);
          }
          setLoginError(
            validated
              ? null
              : "Server message: Something went wrong with loading ALL PHOTOS for this project ID"
          );
          setSpinner(false);
          console.log(" PHOTOS", photoGalery);
        })
        .catch((err) => {
          toast.warn("Hmmm, looks like server is having issues :  " + err, {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 4500,
          });
          console.log(err);
        });
    }
  };

  useEffect(() => {
    getAllPhotos(props.owner, props.projectId, props.projectname);
    console.log("GALLERY", photoGalery);
  }, [props.projectId]);

  const data = props.data;

  return (
    <div className="gallery">
      {photoGalery?.length > 0 ? (
        photoGalery?.map((item, index) => {
          return (
            <div key={index} className="projects m-0  mt-4 mb-4">
              {photoGalery?.length > 0 ? (
                photoGalery?.map((item, index) => {
                  return (
                    <div className="row" key={index}>
                      {item?.data?.length > 0 ? (
                        <p className="text-danger fw-bold text-uppercase me-4 mb-1">
                          Photos of {item?.name}:<small>{item?.project}</small>
                        </p>
                      ) : null}
                      {item?.data ? (
                        item?.data?.map((photo, index) => {
                          return (
                            <div
                              className="col-lg-2 col-sm-6 col-md-3 mb-4"
                              key={index}
                            >
                              <div className="card card-custom overlay overflow-hidden p-2">
                                <div className="card-body p-0">
                                  <div className="overlay-wrapper zoom d-flex">
                                    <img
                                      src={photo.data}
                                      alt=""
                                      className="w-100 rounded "
                                    />
                                  </div>
                                  <div className="overlay-layer bg-dark bg-opacity-10 flex-column">
                                    <div className="bg-dark text-warning m-1">
                                      Date:{photo.created}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })
                      ) : (
                        <p>There are no photos taken for this project </p>
                      )}
                    </div>
                  );
                })
              ) : (
                <h3>NOTHING HERE</h3>
              )}
            </div>
          );
        })
      ) : (
        <div className="alert alert-warning d-flex align-items-center p-5 mb-10">
          <span className="svg-icon svg-icon-2hx svg-icon-primary me-3">
            <i className="fa-duotone fa-face-holding-back-tears fs-1"></i>
          </span>

          <div className="d-flex flex-column">
            <h5 className="mb-1">Not an issue but no photos</h5>
            <span>
              It looks like <strong></strong> does not have any gallery created
              yet. To create a new gallery go to Photos section and start by
              sleecting project missing photos.
            </span>
          </div>
        </div>
      )}
    </div>
  );
};
export default ProjectGallery;
